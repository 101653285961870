import React, { SyntheticEvent, useRef, useState } from 'react';
import { ClassNameInterface, DropdownInterface } from '../../interfaces';
import { useOutsideClick } from '../../hooks';

export const Dropdown: React.FC<ClassNameInterface & DropdownInterface> = ({
  alignRight,
  children,
  className,
  dropdownToggleClass,
  dropdownTogglerIcon,
  disabled,
  name,
  onClick,
  title,
  withoutChildren,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useOutsideClick(ref, () => setOpen(false));

  return (
    <div
      ref={ref}
      className={`dropdown ${className || ''}`}
      onClick={(event: SyntheticEvent): void => {
        event.preventDefault();
        !disabled && setOpen(!open);
        onClick && onClick();
      }}
    >
      <span
        className={`d-flex ${dropdownToggleClass}`}
        id={name}
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {title}
        <span className="ml-auto my-auto h4">
          <i className={`fas fa-${dropdownTogglerIcon || 'angle-down'}`} />
        </span>
      </span>
      {!withoutChildren && (
        <div
          className={`dropdown-menu ${
            alignRight ? 'dropdown-menu-right' : ''
          } mt-0 rounded-0 border-lg w-100${(open && ' show') || ''}`}
          aria-labelledby={name}
        >
          {children}
        </div>
      )}
    </div>
  );
};
