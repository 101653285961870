import { ModelActions, ModelInstance, ModelInterface } from './Common';

export interface CompanySubTypeInterface extends ModelInterface {
  idCompanySubType: string;
  companySubTypeCode: string;
  companySubTypeName: string;
}

export class CompanySubTypeInstance extends ModelInstance {
  constructor(companySubType: CompanySubTypeInterface) {
    super(companySubType);
    this.id = companySubType.idCompanySubType;
    this.code = companySubType.companySubTypeCode;
    this.name = companySubType.companySubTypeName;
  }
}

export class Company extends ModelActions {
  getMany = (
    companySubTypes: CompanySubTypeInterface[]
  ): CompanySubTypeInstance[] =>
    companySubTypes.map(
      (companySubType) => new CompanySubTypeInstance(companySubType)
    );
  getOne = (companySubType: CompanySubTypeInterface): CompanySubTypeInstance =>
    new CompanySubTypeInstance(companySubType);
}
