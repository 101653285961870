import {
  CompanyInstance,
  CompanyInterface,
  ContainerDemandStatusInstance,
  ContainerDemandStatusInterface,
  ContainerInstance,
  ContainerInterface,
} from '.';
import { ModelActions, ModelInstance, ModelInterface } from './Common';
import { MaterialInstance, MaterialInterface } from './Material';

export interface ContainerDemandInterface extends ModelInterface {
  idContainerDemand: string;
  idDemand: number;
  company: CompanyInterface;
  container: ContainerInterface;
  status: ContainerDemandStatusInterface;
  internalOperator: string;
  externalOperator: string;
  bookingDate: string;
  materials: MaterialInterface[];
}

export class ContainerDemandInstance extends ModelInstance {
  container?: ContainerInstance = undefined;
  company?: CompanyInstance = undefined;
  status?: ContainerDemandStatusInstance = undefined;
  internalOperator?: string = undefined;
  idDemand?: number = undefined;
  externalOperator?: string = undefined;
  materials?: any[] = [];
  bookingDate?;

  constructor(demand: ContainerDemandInterface) {
    super(demand);
    this.id = demand.idContainerDemand;
    this.idDemand = demand.idDemand;
    this.company = demand.company && new CompanyInstance(demand.company);
    this.container =
      demand.container && new ContainerInstance(demand.container);
    this.status =
      demand.status && new ContainerDemandStatusInstance(demand.status);
    this.internalOperator = demand.internalOperator;
    this.externalOperator = demand.externalOperator;
    this.bookingDate = new Date(demand.bookingDate);
    this.materials = demand.materials.map(
      (material) => new MaterialInstance(material)
    );
  }
}

export class ContainerDemand extends ModelActions {
  getMany = (
    containers: ContainerDemandInterface[]
  ): ContainerDemandInstance[] =>
    containers.map((container) => new ContainerDemandInstance(container));
  getOne = (container: ContainerDemandInterface): ContainerDemandInstance =>
    new ContainerDemandInstance(container);
}
