import React, { useContext } from 'react';
import { ComponentWithTitle } from '../../components/Common';
import { SzTypographie } from 'react-theme-components';
import { useNotLogged } from '../../hooks';
import { LanguageContext } from '../../contexts';
import { LayoutBase } from '../../components/Layout';

interface CommonResetPasswordInterface {
  title: string;
}

export const CommonResetPassword: React.FC<CommonResetPasswordInterface> = ({
  children,
  title,
}) => {
  useNotLogged();
  const { translate } = useContext(LanguageContext);
  return (
    <LayoutBase withFooter>
      <div className="h-100 d-flex row mx-0 flex-grow-1">
        <div className="m-auto">
          <ComponentWithTitle title={`pages.${title}`} icon="book">
            <div className="card p-4 d-block col-12">
              <SzTypographie variant="text" className="fs-2 col-12 mb-0">
                {translate(`pages.${title}.description`)}
              </SzTypographie>
              {children}
            </div>
          </ComponentWithTitle>
        </div>
      </div>
    </LayoutBase>
  );
};
