import React, { createContext, Dispatch, useReducer } from 'react';
import decode from 'jwt-decode';
import { Token, Username } from '../helpers';

export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const LOGOUT = 'LOGOUT';

interface UserInterface {
  dispatch: Dispatch<UserActionInterface>;
  isLogged: boolean;
  user: string | null;
  username: string | null;
}

const defaultValue: UserInterface = {
  dispatch: () => {
    return;
  },
  isLogged: !!new Token().get(),
  user: new Token().get(),
  username: new Username().get(),
};

export interface UserActionInterface {
  payload?: string;
  type: string;
}

function reducer(
  state: UserInterface,
  { payload, type }: UserActionInterface
): UserInterface {
  switch (type) {
    case UPDATE_CLIENT: {
      const { username } = decode(payload || '') as UserInterface;
      new Username().set(username || '');
      return {
        ...state,
        isLogged: true,
        user: payload || '',
        username,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        ...defaultValue,
        isLogged: false,
      };
    }
    default:
      return state;
  }
}

export const UserContext = createContext(defaultValue);

export const UserProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultValue);

  return (
    <UserContext.Provider value={{ ...state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};
