import React, { useContext, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { AppBar, FiltersBar } from './AppBar';
import { LanguageContext, UserContext } from '../../contexts';
import './index.scss';

interface LayoutBaseInterface {
  withFooter?: boolean;
}

export const LayoutBase: React.FC<LayoutBaseInterface> = ({
  children,
  withFooter,
}) => {
  const { translate } = useContext(LanguageContext);

  return (
    <>
      {children}
      {withFooter ? (
        <div className="py-4 container">
          <div className="col-12">
            <Nav className="justify-content-center">
              <Nav.Item className="footer-nav-item">
                <Nav.Link
                  href={`resources/${translate(
                    'layout.footer.nav.general-usage-terms.filename'
                  )}`}
                  target="_blank"
                >
                  {translate('layout.footer.nav.general-usage-terms.label')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="footer-nav-item">
                <Nav.Link
                  href={`resources/${translate(
                    'layout.footer.nav.cookies-policy.filename'
                  )}`}
                  target="_blank"
                >
                  {translate('layout.footer.nav.cookies-policy.label')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="footer-nav-item">
                <Nav.Link
                  href={`resources/${translate(
                    'layout.footer.nav.legal-terms.filename'
                  )}`}
                  target="_blank"
                >
                  {translate('layout.footer.nav.legal-terms.label')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="footer-nav-item">
                <Nav.Link
                  href={`resources/${translate(
                    'layout.footer.nav.privacy-policy.filename'
                  )}`}
                  target="_blank"
                >
                  {translate('layout.footer.nav.privacy-policy.label')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="footer-nav-item">
                <Nav.Link href={`/consent`}>
                  {translate('pages.consent.title')}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
      ) : null}
    </>
  );
};

interface LayoutInterface {
  noContainer?: boolean;
  withDisclaimer?: boolean;
  withFooter?: boolean;
}

export const Layout: React.FC<LayoutInterface> = ({
  children,
  noContainer,
  withDisclaimer,
  withFooter,
}) => {
  useEffect(() => {
    typeof window !== 'undefined' &&
      [
        '/',
        '/analysis',
        '/catalogs',
        '/account',
        '/movement',
        '/contact',
        '/consent',
        '/demandsExternal',
        '/demandsInternal',
      ].includes(document.location.pathname);
  }, [window]);

  const { translate } = useContext(LanguageContext);
  const { isLogged } = useContext(UserContext);

  if (!isLogged) {
    return null;
  }

  return (
    <LayoutBase withFooter={withFooter}>
      <div className="sticky-top">
        <AppBar />
        <FiltersBar />
      </div>
      {noContainer ? (
        children
      ) : (
        <div className="container">
          <div className="row m-0 py-5">{children}</div>
        </div>
      )}
      {withDisclaimer ? (
        <div className="py-4 container">
          <div className="col-12">
            <span className="text-muted">{translate('layout.disclaimer')}</span>
          </div>
        </div>
      ) : null}
    </LayoutBase>
  );
};

export * from './ReturnToTop';
