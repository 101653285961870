import React, { useContext, useEffect, useState } from 'react';
import { CustomBar } from '../Abstract/CutsomBar';
import { Loader } from '../../Loader';
import { formatTitleToK, formatTitleToKg } from './Filters';
import { BaseStoreContext, LanguageContext } from '../../../contexts';
import { KPI as KPIProvider } from '../../../interfaces/providers';
import { Currency, getDate } from '../../../helpers';
import { OptionInterface } from '../../Filters';
import { Tooltip } from 'react-bootstrap';
import { Tile } from '../../Tile';
import './kpi.scss';
import { useWindowSize } from '../../../hooks';
import { TypeSelector, TypeSelectorContext } from '../../TypeSelector';

interface KPIStateInterface {
  [key: string]: number;
}

export const KPI: React.FC = () => {
  const { itemFiltersSelected } = useContext(BaseStoreContext);
  const { selectedLanguage, translate } = useContext(LanguageContext);
  const date = new Date();
  date.setDate(0);
  const { width } = useWindowSize();
  const [values, setValues] = useState<KPIStateInterface | null>(null);
  const { selected } = useContext(TypeSelectorContext);
  const currency = new Currency().getDecoded().label;

  const contract = (
    itemFiltersSelected.contract.length
      ? itemFiltersSelected.contract[0]
      : itemFiltersSelected.contract
  ) as OptionInterface;
  const isServices = contract && contract.value === true;

  useEffect(() => {
    const filters: {
      endDeliveryDate?: string;
      startDeliveryDate?: string;
    } = {};
    const filterDate = new Date();
    filterDate.setDate(0);
    filters.endDeliveryDate = getDate(filterDate);
    filterDate.setDate(1);
    filters.startDeliveryDate = getDate(filterDate);
    new KPIProvider({
      filters: {
        idOrganizations: itemFiltersSelected.organization,
        currencyCode: new Currency().getDecoded()?.value,
        idCompanies: itemFiltersSelected.plant,
        isService: isServices,
        role: selected,
        ...filters,
      },
    })
      .get()
      .then(setValues);
  }, [itemFiltersSelected, selected]);

  if (!values) {
    return <Loader text="charts.kpi" />;
  }

  return (
    <div className="row m-0 h-100 mh-50-vh bg-white">
      <TypeSelector />
      <CustomBar
        placement={width < 762 ? 'right' : 'left'}
        trigger={width < 762 ? 'click' : 'hover'}
        value={values.revenue}
        title={
          <div className="text-secondary">
            {formatTitleToK(values.revenueTotal, currency)}
          </div>
        }
        tooltip={
          <Tooltip id={'tooltip-filters'}>
            <div className="text-left">
              <div>
                <span className="d-block text-center">{`${translate(
                  'charts.kpi.services'
                )}`}</span>
                <span className="d-flex service legend fs-4">
                  {formatTitleToK(
                    values.revenueTotal - values.revenue,
                    currency
                  )}
                </span>
              </div>
              <div className="pt-2">
                <span className="d-block text-center">{`${translate(
                  'charts.kpi.valorisations'
                )}`}</span>
                <span className="d-flex valorisation legend fs-4">
                  {formatTitleToK(values.revenue, currency)}
                </span>
              </div>
            </div>
          </Tooltip>
        }
        total={values.revenueTotal}
        type="secondary-light"
        subtype="secondary"
        label="kpi.revenue"
      />
      {!isServices && (
        <CustomBar
          value={values.volume}
          total={values.volume}
          title={
            <div className="text-primary">{formatTitleToKg(values.volume)}</div>
          }
          label="kpi.volume"
          subtype="blue-clear-light"
        />
      )}
      <Tile className="row m-0 w-100 p-3">
        <div className="col-6 fs-2">
          <span className="service legend d-flex py-1 my-auto">
            {translate('charts.kpi.services')}
          </span>
          <span className="valorisation legend d-flex py-1 my-auto">
            {translate('charts.kpi.valorisations')}
          </span>
        </div>
        <div className="col-6 d-flex">
          <span className="mt-auto ml-auto text-uppercase">
            {date.toLocaleDateString(selectedLanguage.value?.toString(), {
              month: 'long',
              year: 'numeric',
            })}
          </span>
        </div>
      </Tile>
    </div>
  );
};
