import { ModelActions, ModelInstance, ModelInterface } from './Common';

export interface CompanyTypeInterface extends ModelInterface {
  idCompanyType: string;
  companyTypeCode: string;
  companyTypeName: string;
}

export class CompanyTypeInstance extends ModelInstance {
  constructor(companyType: CompanyTypeInterface) {
    super(companyType);
    this.id = companyType.idCompanyType;
    this.code = companyType.companyTypeCode;
    this.name = companyType.companyTypeName;
  }
}

export class Company extends ModelActions {
  getMany = (companyTypes: CompanyTypeInterface[]): CompanyTypeInstance[] =>
    companyTypes.map((companyType) => new CompanyTypeInstance(companyType));
  getOne = (companyType: CompanyTypeInterface): CompanyTypeInstance =>
    new CompanyTypeInstance(companyType);
}
