import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import { Language } from '../helpers';
import { OptionInterface } from '../components/Filters';
import { translations } from '../i18n';

interface LanguageProviderInterface {
  languages: OptionInterface[];
  selectedLanguage: OptionInterface;
  setSelectedLanguage: Dispatch<SetStateAction<OptionInterface>>;
  translate: (value: string) => string;
}

const allowedLanguages = (): OptionInterface[] => [
  {
    label: 'Français',
    value: 'fr-FR',
  },
  {
    label: 'English',
    value: 'en-US',
  },
];

const languageInitier = (value: string): OptionInterface[] =>
  allowedLanguages().filter((item) => value === item.value);
const initSelectedLanguage = (): OptionInterface =>
  languageInitier(new Language().get())[0] ||
  languageInitier(navigator.language)[0] || {
    label: 'English',
    value: 'en-US',
  };

const initialState = {
  languages: allowedLanguages(),
  selectedLanguage: initSelectedLanguage(),
  setSelectedLanguage: (): void => {
    return;
  },
  translate: (value: string): string => {
    return value;
  },
};

export const LanguageContext =
  createContext<LanguageProviderInterface>(initialState);

const findTranslation = (
  keys: string[],
  translations: any /*eslint-disable-line*/
): string | undefined => {
  const key = keys[0];
  if (keys.length && translations[key.toString()]) {
    keys.shift();
    return findTranslation(keys, translations[key.toString()]);
  } else if (!keys.length) {
    return translations;
  }
  return undefined;
};

export const LanguageProvider: React.FC = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState<OptionInterface>(
    initSelectedLanguage()
  );
  const translate = (value: string): string =>
    findTranslation(
      value.split('.'),
      translations[selectedLanguage.value as string]
    ) || value;
  return (
    <LanguageContext.Provider
      value={{
        languages: allowedLanguages(),
        selectedLanguage,
        setSelectedLanguage,
        translate,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
