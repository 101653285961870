import React, { MutableRefObject, useContext, useState } from 'react';
import { User } from '../../../actions';
import { confirmPassword, newPassword } from '../Field';
import { Form } from '../Form';
import { useRouteMatch, useLocation } from 'react-router';
import { LanguageContext } from '../../../contexts';
import { validatePassword } from './PasswordValidator';
import { PasswordValidationBlock } from './PasswordValidationBlock';

export const ResetPasswordApplyForm: React.FC = () => {
  const { translate } = useContext(LanguageContext);
  const { search } = useLocation();
  const { params } = useRouteMatch<{ token: string }>();
  const fields = [newPassword(), confirmPassword()];
  const [hasError, setHasError] = useState<boolean>(false);
  return (
    <>
      {hasError && (
        <div className="alert alert-danger">
          {translate(`forms.changePassword.invalid`)}
        </div>
      )}
      <Form
        fields={fields}
        validate={(ref): boolean => {
          const isValidated = validatePassword(ref, fields);
          setHasError(!isValidated);
          return isValidated;
        }}
        submitForm={(
          data: any, // eslint-disable-line
          ref: MutableRefObject<{ reset: () => void }>
        ): Promise<void> => {
          return new User().resetPasswordApply({
            data: {
              ...data,
              email: new URLSearchParams(search).get('username'),
              token: params.token,
            },
            ref,
          });
        }}
        successMessage="resetPasswordApply"
      />
      <PasswordValidationBlock />
    </>
  );
};
