import { useContext, useEffect } from 'react';
import { UserContext } from '../contexts';
import { useHistory, useLocation } from 'react-router-dom';
import { Token } from '../helpers';
import { ROUTES } from '../actions/BCM_Constant';

export const useLogged = (): void => {
  const { push } = useHistory();
  const location = useLocation();
  const { isLogged } = useContext(UserContext);

  if (!isLogged) {
    push('/login');
  }

  const roles = new Token().getDecoded().roles;
  if (
    location.pathname === '/' &&
    !roles?.some((role) => ['ROLE_SUPPLIER', 'ROLE_CUSTOMER'].includes(role))
  ) {
    if (roles?.some((role) => role === 'ROLE_INTERNAL_OPERATOR')) {
      push(ROUTES.DemandsInternal);
    } else if (roles?.some((role) => role === 'ROLE_EXTERNAL_OPERATOR')) {
      push(ROUTES.DemandsExternal);
    }
    if (
      roles?.some((role) =>
        ['ROLE_MOVEMENT_SUPPLIER', 'ROLE_MOVEMENT_CUSTOMER'].includes(role)
      )
    ) {
      push(ROUTES.Movement);
    }
  }
};
