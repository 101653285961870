import React, { useEffect, useState } from 'react';
import { SzIcon } from 'react-theme-components';
import './returnToTop.scss';

const MAX_SCROLL = 120;

export const ReturnToTop: React.FC = () => {
  const [top, setTop] = useState(window.scrollY < MAX_SCROLL);

  const handleScroll = (): void => {
    setTop(window.scrollY < MAX_SCROLL);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return (): void => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <div
      className={`return-to-top position-fixed transition ${
        top ? 'd-none' : ''
      }`}
      onClick={(): void => window.scrollTo({ top: 0, behavior: 'smooth' })}
    >
      <SzIcon
        variant="line"
        icon="arrow-up-1"
        className="sz-icon-line arrow-up-1 size-4 transition position-relative p-3 rounded-circle bg-primary text-white pointer"
      />
    </div>
  );
};
