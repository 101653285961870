import React, { useContext } from 'react';
import { SzIcon, SzTypographie } from 'react-theme-components';
import { ClassNameInterface } from '../../interfaces';
import { LanguageContext } from '../../contexts';

interface ResetFilterInterface {
  resetFilters: () => void;
}

export const ResetFilter: React.FC<
  ClassNameInterface & ResetFilterInterface
> = ({ className, resetFilters }) => {
  const { translate } = useContext(LanguageContext);
  return (
    <div
      className={`${
        className || ''
      } pt-3 pt-md-0 text-center text-lg-left m-auto align-items-center cursor-pointer`}
      onClick={(): void => resetFilters()}
    >
      <span className="d-inline-flex align-items-center m-auto px-0 py-1 px-md-2">
        <SzIcon icon="synchronize-arrow-1" variant="line" />
        <SzTypographie variant="text" className="ml-3 m-0">
          {translate('filters.reset.label')}
        </SzTypographie>
      </span>
    </div>
  );
};
