import jwtDecode from 'jwt-decode';
import {
  CompanyInstance,
  CurrencyInterface,
  ModelInterface,
  OrganizationInstance,
  PreferenceInterface,
  TokenInterface,
} from '../interfaces';
import { RoleInterface } from '../interfaces/RoleInterface';
import { AxiosResponse } from 'axios';
import moment from 'moment';

export const sprintf = (haystack: string, values: string[]): string => {
  let v = '';
  haystack
    .split('%s')
    .forEach(
      (item, index) =>
        (v += `${item}${values[parseInt(index.toString())] || ''}`)
    );
  return v;
};

export const getTimeOffset = (): number => new Date().getTimezoneOffset();

export const getDate = (date): string => moment(date).format('YYYY-MM-DD');

export const getDecodedToken = (token: string): TokenInterface =>
  token ? (jwtDecode(token) as TokenInterface) : ({} as TokenInterface);

abstract class LocalStorage {
  protected key = '';

  public get(): string | null {
    return localStorage.getItem(this.key);
  }

  public set(value: string): void {
    return localStorage.setItem(this.key, value);
  }

  public delete(): void {
    return localStorage.removeItem(this.key);
  }
}

export class RefreshToken extends LocalStorage {
  protected key = 'refresh_token';
}

export class Token extends LocalStorage {
  protected key = 'token';

  public getDecoded(): TokenInterface {
    return getDecodedToken(this.get() || '');
  }
}

export class Username extends LocalStorage {
  protected key = 'username';
}
export class UserId extends LocalStorage {
  protected key = 'userId';
}

abstract class DecodedStorage extends LocalStorage {
  public getDecoded(): ModelInterface | RoleInterface | string[] | null {
    return JSON.parse(super.get() || '{}') as ModelInterface;
  }

  public setDecoded(value: ModelInterface | null): void {
    super.set(JSON.stringify(value));
  }
}

export class Organization extends DecodedStorage {
  protected key = 'organization';

  public getDecoded(): OrganizationInstance {
    return super.getDecoded() as OrganizationInstance;
  }

  public setDecoded(value: OrganizationInstance): void {
    super.setDecoded(value);
  }
}

export class Company extends DecodedStorage {
  protected key = 'company';

  public getDecoded(): CompanyInstance {
    return super.getDecoded() as CompanyInstance;
  }

  public setDecoded(value: CompanyInstance): void {
    super.setDecoded(value);
  }
}
export class Roles extends DecodedStorage {
  protected key = 'roles';

  public getDecoded(): RoleInterface {
    return super.getDecoded() as RoleInterface;
  }

  public setDecoded(value: ModelInterface | string[] | null): void {
    super.set(JSON.stringify(value));
  }
}

export class Indexes extends DecodedStorage {
  protected key = 'indexes';

  public getDecoded(): string[] {
    return super.getDecoded() as string[];
  }

  public setDecoded(value: ModelInterface | string[] | null): void {
    super.set(JSON.stringify(value));
  }
}

export class Preference extends DecodedStorage {
  protected key = 'preference';

  public getDecoded(): PreferenceInterface | CurrencyInterface {
    return super.getDecoded() as PreferenceInterface;
  }

  public setDecoded(value: PreferenceInterface | CurrencyInterface): void {
    super.setDecoded(value);
  }
}

export class Currency extends Preference {
  public getDecoded(): CurrencyInterface {
    const preference = super.getDecoded() as PreferenceInterface;
    return preference.currency || {};
  }

  public setDecoded(value: CurrencyInterface): void {
    const preference = super.getDecoded() as PreferenceInterface;
    preference.currency = value;
    super.setDecoded(preference);
  }
}

export class Language extends Preference {
  public get(): string {
    const preference = super.getDecoded() as PreferenceInterface;
    return preference.language || '';
  }

  public set(value: string): void {
    const preference = super.getDecoded() as PreferenceInterface;
    preference.language = value;
    super.setDecoded(preference);
  }
}

export const axiosInterceptorError = (
  response: AxiosResponse
): Promise<never> | undefined => {
  if (!response.status) {
    return Promise.reject(response);
  }
  const { status } = response;
  if (401 === status || 403 === status) {
    if (response.config.url == '/refresh') {
      const providers = [Token, Preference, Username, Company, Organization];
      providers.forEach((provider) => new provider().delete());
      window.location.pathname = '/';
      return new Promise((_, reject) => {
        reject(response);
      });
    }
  }
};
