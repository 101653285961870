import React, { useContext } from 'react';
import { Bar } from '../Abstract';
import { ChartContext } from '../../../contexts';
import { formatTitleToK, TopFilters } from './Filters';

export const Co2: React.FC = () => {
  const { total } = useContext(ChartContext);

  return (
    <Bar
      type="co2"
      unit="teqCO2"
    >
      <TopFilters
        {...{
          additional: 'teqCO2',
          formatTitle: (value): JSX.Element => formatTitleToK(value, 'teqCO2'),
          total,
        }}
      />
    </Bar>
  );
};
