import * as React from 'react';
import { ClassNameInterface, DropdownInterface } from '../../../../interfaces';
import { Dropdown } from '../../../Dropdown';

export const NavDropdown: React.FC<ClassNameInterface & DropdownInterface> = ({
  className,
  dropdownToggleClass,
  ...rest
}) => (
  <Dropdown
    {...{
      className: `nav-item ${className || ''}`,
      dropdownToggleClass: `nav-link ${dropdownToggleClass || ''}`,
      ...rest,
    }}
  />
);
