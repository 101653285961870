import { AbstractSerializer } from './AbstractSerializer';

export class MaterialSerializer extends AbstractSerializer {
  serialize(
    obj: { [key: string]: string | number | [] } /* eslint-disable-line */
  ): this {
    this.perPage = obj.perPage;
    this.page = obj.page;
    this.language = obj.language;
    this.idOrganizations = obj.idOrganizations;
    this.idCompanies = obj.idCompanies;
    this.idMaterialFamily = obj.materialFamilyFilterValues;
    this.idMaterialSubFamily = obj.materialSubFamilyFilterValues;
    this.fullname = obj.searchMaterialFilter;
    return this;
  }
}
