import React, { useContext } from 'react';
import { SzTypographie } from 'react-theme-components';
import './passwordValidationBlock.scss';
import { LanguageContext } from '../../../contexts';

const requirements: string[] = ['length', 'upper', 'lower', 'digit', 'special'];

export const PasswordValidationBlock: React.FC = () => {
  const { translate } = useContext(LanguageContext);
  const t = (value: string): string =>
    translate(`forms.password.conditions.${value}`);
  return (
    <div className="col-12 pt-3">
      <div className="pt-3 px-3 border rounded-lg">
        <SzTypographie variant="h1" className="m-0">
          {t('title')}
        </SzTypographie>
        <SzTypographie variant="text" className="child-green-round">
          {requirements.map((requirement, index) => (
            <p key={index} className="m-0 pl-3 position-relative">
              {t(`contains.${requirement}`)}
            </p>
          ))}
        </SzTypographie>
      </div>
    </div>
  );
};
