import React, { useContext } from 'react';
import { LayoutBase } from '../components/Layout';
import { LanguageContext } from '../contexts';
import { ComponentWithTitle } from '../components/Common';

export const Consent: React.FC = () => {
  const { translate } = useContext(LanguageContext);
  return (
    <LayoutBase withFooter>
      <ComponentWithTitle title="pages.consent" logo="icon.png">
        <div className="card cookies-card p-5 m-5 ">
          <p>{translate('pages.consent.body.p1')}</p>
          <br />
          <p>{translate('pages.consent.body.p2')}</p>
          <br />
          <p>
            {translate('pages.consent.body.p3.t1')}
            <a
              href={`resources/${translate(
                'layout.footer.nav.cookies-policy.filename'
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate('pages.consent.body.p3.l1')}
            </a>
            {translate('pages.consent.body.p3.t2')}
          </p>
          <br />
          <p>{translate('pages.consent.body.p4')}</p>
          <br />
          <p>{translate('pages.consent.body.p5')}</p>
          <br />
          <p>{translate('pages.consent.body.p6')}</p>
          <br />
          <p>{translate('pages.consent.body.p7')}</p>
          <br />
          <p>
            {translate('pages.consent.body.p8.t1')}
            <a id={'BCM-consent-optout'} href={`#`} rel="noopener noreferrer">
              {translate('pages.consent.body.p3.l1')}
            </a>
            {translate('pages.consent.body.p8.t2')}
          </p>
          <br />
          <p>
            {translate('pages.consent.body.p9.t1')}
            <a
              href={translate('pages.consent.body.p9.l1')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate('pages.consent.body.p9.l1')}
            </a>
            .
          </p>
          <p>{translate('pages.consent.body.p10')}</p>
          <br />
          <p>
            {translate('pages.consent.body.p11.t1')}
            <a
              href={`resources/${translate(
                'layout.footer.nav.privacy-policy.filename'
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate('pages.consent.body.p11.l1')}
            </a>
            .
          </p>
          <br />
          <p>{translate('pages.consent.body.p12')}</p>
          <br />
        </div>
      </ComponentWithTitle>
    </LayoutBase>
  );
};
