import { MutableRefObject } from 'react';
import { FieldInterface } from '../Field';

export const validatePassword = (
  ref: MutableRefObject<HTMLFormElement>,
  fields: FieldInterface[]
): boolean => {
  if (ref.current[fields[0].name].value !== ref.current[fields[1].name].value) {
    return false;
  }

  const strongRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
  );
  if (!ref.current[fields[0].name].value.match(strongRegex)) {
    return false;
  }

  return true;
};
