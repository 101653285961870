import { ModelActions, ModelInstance, ModelInterface } from './Common';
import {
  MaterialFamilyInstance,
  MaterialFamilyInterface,
} from './MaterialFamily';
import {
  MaterialSubFamilyInstance,
  MaterialSubFamilyInterface,
} from './MaterialSubFamily';
import {
  MaterialReferentialInstance,
  MaterialReferentialInterface,
} from './MaterialReferential';

export interface MaterialInterface extends ModelInterface {
  countryCode: string;
  description: string;
  idDefaultUnit: string;
  idMaterial: string;
  localCountryLegalMaterialCode: string;
  localCountryMaterialName: string;
  localDefaultUnit: string;
  localITSystemMaterialUID: string;
  localMaterialFamilyCode: string;
  localMaterialFamilyName: string;
  localMaterialSubFamilyCode: string;
  localMaterialSubFamilyName: string;
  materialCode: string;
  materialFamily: MaterialFamilyInterface;
  materialName: string;
  materialSubFamily: MaterialSubFamilyInterface;
  materialReferential: MaterialReferentialInterface;
  photo?: string;
}

export class MaterialInstance extends ModelInstance {
  code = '';
  countryCode = '';
  description = '';
  materialFamily?: MaterialFamilyInstance = undefined;
  materialReferential?: MaterialSubFamilyInstance = undefined;
  materialSubFamily?: MaterialReferentialInstance = undefined;
  photos?: string[] = undefined;

  constructor(material: MaterialInterface) {
    super(material);
    this.code = material.materialCode;
    this.countryCode = material.countryCode;
    this.description = material.description;
    this.id = material.idMaterial;
    this.name = material.materialName;
    this.materialFamily =
      material.materialFamily &&
      new MaterialFamilyInstance(material.materialFamily);
    this.materialSubFamily =
      material.materialSubFamily &&
      new MaterialSubFamilyInstance(material.materialSubFamily);
    this.materialReferential =
      material.materialReferential &&
      new MaterialReferentialInstance(material.materialReferential);
    this.photos = material.photo?.split(';').map((p) => p);
  }
}

export class Material extends ModelActions {
  getMany = (materials: MaterialInterface[]): MaterialInstance[] =>
    materials.map((material) => new MaterialInstance(material));
  getOne = (material: MaterialInterface): MaterialInstance =>
    new MaterialInstance(material);
}
