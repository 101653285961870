import { AxiosResponse } from 'axios';
import { APIPlatform } from './API';
import { Dispatch, MutableRefObject } from 'react';
import { LOGOUT, UPDATE_CLIENT, UserActionInterface } from '../contexts';
import {
  Company as CompanyStorage,
  getDecodedToken,
  Indexes,
  Organization as OrganizationStorage,
  Preference,
  RefreshToken,
  Token,
  UserId,
  Username,
} from '../helpers';
import { Company, Organization } from '../interfaces/providers';

interface RefInterface {
  ref: MutableRefObject<{
    reset: () => void;
  }>;
}
interface FormdataInterface {
  data: FormData;
}

interface ResetPasswordInterface extends RefInterface, FormdataInterface {}

type ValueInterface =
  | { payload: Record<string, unknown> | string; type: string }
  | Record<string, unknown>;

interface LoginInterface extends ResetPasswordInterface {
  updateClient: any; // eslint-disable-line
}

interface RegisterInterface extends ResetPasswordInterface {
  updateClient: (value: ValueInterface) => void;
}

interface RegisterInterface extends ResetPasswordInterface {
  updateClient: (value: ValueInterface) => void;
}

interface LogoutInterface {
  updateClient: Dispatch<UserActionInterface>;
}

export class User extends APIPlatform {
  public endpoint = '/users';

  public login({ data, updateClient }: LoginInterface): Promise<void> {
    data.append('grant_type', 'password');
    data.append('client_id', '' + process.env.REACT_APP_OAUTH_CLIENT_ID);
    data.append(
      'client_secret',
      '' + process.env.REACT_APP_OAUTH_CLIENT_SECRET
    );
    this.endpoint = '';
    return this.postRequest({
      config: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
      data,
      endpoint: '/login',
    }).then(({ status, data }: AxiosResponse) => {
      if (200 !== status) {
        return;
      }

      const accessToken = data.access_token;
      const token = new Token();
      const decoded = getDecodedToken(accessToken);
      // Administrators are not allowed on this platform
      if (
        decoded.roles.includes('ROLE_ADMIN') ||
        (decoded.roles.includes('ROLE_EXTERNAL_OPERATOR') &&
          decoded.roles.includes('ROLE_INTERNAL_OPERATOR'))
      ) {
        // the empty message has been asked by the client
        throw new Error();
      }
      new RefreshToken().set(data.refresh_token);
      token.set(accessToken);
      new Username().set(decoded.username);
      new UserId().set(decoded.user_id);
      new Preference().setDecoded(decoded.preference);
      new Indexes().setDecoded(decoded.indexes);
      updateClient({
        payload: accessToken,
        type: UPDATE_CLIENT,
      });
      decoded.organization_id &&
        new Organization()
          .getOne({ id: decoded.organization_id })
          .then(({ data }) => new OrganizationStorage().setDecoded(data));
      new Company()
        .getOne({ id: decoded.company_id })
        .then(({ data }) => new CompanyStorage().setDecoded(data));
    });
  }

  public resetPasswordRequest({
    data,
    ref,
  }: ResetPasswordInterface): Promise<void> {
    this.endpoint = '';
    return this.postRequest({
      data,
      endpoint: `/reset-password/request`,
    })
      .then(({ status }: AxiosResponse) => User.resetForm({ ref, status }))
      .catch();
  }

  static resetForm({ ref, status }: RefInterface & { status: number }): void {
    if (200 === status) {
      ref.current.reset();
    }
  }

  public resetPasswordApply({
    data,
    ref,
  }: ResetPasswordInterface): Promise<void> {
    this.endpoint = '';
    return this.postRequest({
      data: {
        ...data,
      },
      endpoint: '/reset-password/apply',
    })
      .then(({ status }: AxiosResponse) => User.resetForm({ ref, status }))
      .catch();
  }

  public static logout({ updateClient }: LogoutInterface): void {
    const providers = [
      Token,
      Preference,
      Username,
      CompanyStorage,
      OrganizationStorage,
    ];
    providers.forEach((provider) => new provider().delete());
    updateClient({
      type: LOGOUT,
    });
  }

  public register({ data, ref }: RegisterInterface): Promise<void> {
    return this.postRequest({
      data,
      endpoint: '/users',
    }).then(({ status }: AxiosResponse) => {
      if (201 === status) {
        ref.current.reset();
      }
      return;
    });
  }

  public refreshToken(): Promise<void> {
    const data = new FormData();
    data.append('grant_type', 'refresh_token');
    data.append('client_id', '' + process.env.REACT_APP_OAUTH_CLIENT_ID);
    data.append('refresh_token', new RefreshToken().get() || '');
    this.endpoint = '';
    return this.postRequest({
      config: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
      data,
      endpoint: '/login',
    }).then(({ status, data }: AxiosResponse) => {
      if (200 === status) {
        const accessToken = data.access_token;
        const token = new Token();
        const decoded = getDecodedToken(accessToken);
        if (decoded.roles.includes('ROLE_ADMIN')) {
          throw new Error();
        }
        new RefreshToken().set(data.refresh_token);
        token.set(accessToken);
        new Username().set(decoded.username);
        new Preference().setDecoded(decoded.preference);
        decoded.organization_id &&
          new Organization()
            .getOne({ id: decoded.organization_id })
            .then(({ data }) => new OrganizationStorage().setDecoded(data));
        new Company()
          .getOne({ id: decoded.company_id })
          .then(({ data }) => new CompanyStorage().setDecoded(data));
      } else {
        User.logout({ updateClient: () => null });
        window.location.pathname = '/login';
      }
    });
  }

  public sendDemandMail({ data }): Promise<void> {
    this.endpoint = '';
    return this.postRequest({
      data,
      endpoint: `/send_demand_mail`,
    })
      .then(() => console.log())
      .catch();
  }
}
