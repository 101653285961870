import { AbstractAPIPlatformProvider } from '../interfaces/providers/abstractAPIPlatform';

export class Document extends AbstractAPIPlatformProvider {
  public endpoint = 'documents';

  downloadDocument = ({ id }: { id: string }): Promise<any> =>
    this.request()
      .get(this.getUrl(`/${id}`, true))
      .then((data) => data.data);
}
