import React, { useContext, useState } from 'react';
import { MaterialInstance } from '../../interfaces/model';
import {
  ADD_ITEM_CATALOG,
  BaseStoreContext,
  LanguageContext,
  SET_SELECTED_ITEM,
} from '../../contexts';
import { EmbedCard } from '../Card';
import { useHistory } from 'react-router';
import InfiniteScroll from 'react-infinite-scroller';
import { Material } from '../../interfaces/providers';
import { Spinner } from 'react-bootstrap';
import { OptionInterface } from '../Filters';

interface CatalogItemsInterface {
  materialFamilyFilterValues: OptionInterface[];
  materialSubFamilyFilterValues: OptionInterface[];
  perPage: number;
  searchMaterialFilter: string;
}

export const CatalogItems: React.FC<CatalogItemsInterface> = ({
  materialFamilyFilterValues,
  materialSubFamilyFilterValues,
  perPage,
  searchMaterialFilter,
}) => {
  const { push } = useHistory();
  const { catalog, loading, dispatch, itemFiltersSelected } =
    useContext(BaseStoreContext);
  const { translate } = useContext(LanguageContext);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const loader = (
    <div className="w-100 d-flex py-5">
      <span className="m-auto d-flex">
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
        <span className="m-auto pl-1">{translate(`pages.catalog.loader`)}</span>
      </span>
    </div>
  );

  if (loading) {
    return loader;
  }

  if (!catalog.length) {
    return (
      <div className="w-100 d-flex py-5">
        <span className="m-auto d-flex">
          <span className="m-auto pl-1">
            {translate(`pages.catalog.no_results`)}
          </span>
        </span>
      </div>
    );
  }

  return (
    <div className="w-100">
      <InfiniteScroll
        pageStart={1}
        loadMore={(page): void => {
          new Material({
            filters: {
              idOrganizations: itemFiltersSelected.organization,
              idCompanies: itemFiltersSelected.plant,
              materialFamilyFilterValues,
              materialSubFamilyFilterValues,
              searchMaterialFilter,
              page,
              perPage,
            },
          })
            .getList()
            .then(({ data }) => {
              setHasMore(data.length === perPage);
              dispatch({
                payload: data,
                type: ADD_ITEM_CATALOG,
              });
              if (data.length < perPage) {
                setHasMore(false);
              }
            });
        }}
        hasMore={hasMore}
        loader={loader}
      >
        <div className="row m-0">
          {catalog.map((item: MaterialInstance, key: number) => (
            <div className="p-3 col-md-6 col-lg-4 h-100" key={key}>
              <EmbedCard
                image={{
                  alt: item.name,
                  src: `/materials/${
                    (item.photos && item.photos[0]) || 'undefined.jpg'
                  }`,
                }}
                link={`/catalogs/${item.id}`}
                onClick={(event): void => {
                  event.preventDefault();
                  dispatch({
                    type: SET_SELECTED_ITEM,
                    payload: item,
                  });
                  push(`/catalogs/${item.id}`);
                }}
                title={`${item.code} - ${item.name}`}
              />
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};
