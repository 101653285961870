import React, { useContext } from 'react';
import { Mixed } from '../Abstract';
import { MixedChartContext } from '../../../contexts/MixedChartContext';
import { formatTitleToK, TopFilters } from './Filters';
import { OptionInterface } from '../../Filters';

export const IndexValue: React.FC = () => {
  const { selectedIndex, selectedMaterial, total } =
    useContext(MixedChartContext);

  return (
    <Mixed type="indexes">
      <TopFilters
        {...{
          additional: '',
          formatTitle: (value): JSX.Element => formatTitleToK(value, ''),
          total,
        }}
      >
        <div className="my-auto fs-4 text-primary">
          {(selectedIndex as OptionInterface)?.label ||
            (selectedMaterial as OptionInterface)?.label ||
            ''}
        </div>
      </TopFilters>
    </Mixed>
  );
};
