import { AbstractProvider } from './abstract';
import { Container as ContainerModel } from '../model';
import { MANAGEMENT, PROVIDER } from '../../actions/BCM_Constant';

export class Container extends AbstractProvider {
  endpoint = PROVIDER.CONTAINERS;
  management = MANAGEMENT.COMPANY;
  model = new ContainerModel();
  name = PROVIDER.CONTAINERS;
}
