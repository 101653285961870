import React from 'react';
import { Layout, ReturnToTop } from '../components/Layout';
import { ComponentWithTitle } from '../components/Common';
import { Catalog as CatalogComponent } from '../components/Catalog';
import { useLogged } from '../hooks';

export const Catalog: React.FC = () => {
  useLogged();
  return (
    <Layout withDisclaimer withFooter>
      <ComponentWithTitle title="pages.catalog" icon="book">
        <CatalogComponent />
      </ComponentWithTitle>
      <ReturnToTop />
    </Layout>
  );
};
