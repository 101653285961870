/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useContext, useState, useEffect } from 'react';
import { Layout } from '../../components/Layout';
import { ComponentWithTitle } from '../../components/Common';
import { useLogged } from '../../hooks';
import { BaseStoreContext, LanguageContext } from '../../contexts';
import Table from '../../components/TableContainer/Table';
import {
  SzAlert,
  SzButton,
  SzDatePicker,
  SzModal,
  SzSelect,
} from 'react-theme-components';
import TimePicker from 'rc-time-picker';
import {
  ContainerDemand,
  ContainerDemandStatus,
} from '../../interfaces/providers';
import { UserId } from '../../helpers';
import { ContainerDemandStatusInstance } from '../../interfaces';
import './Demand.scss';

import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { User } from '../../actions';
import { Materials } from './Materials';

export const STATUS = {
  InProgress: 'In Progress',
  Planned: 'Planned',
  NewDate: 'New Date',
  Closed: 'Closed',
  Canceled: 'Canceled',
  NoReturn: 'No Return',
};
export const INPROGRESS_STATUS = [
  STATUS.InProgress,
  STATUS.Planned,
  STATUS.NewDate,
];
export const CLOSED_STATUS = [STATUS.Closed, STATUS.Canceled, STATUS.NoReturn];

export const DemandsInternal: React.FC = () => {
  useLogged();
  const { itemFiltersSelected } = useContext(BaseStoreContext);
  const { translate } = useContext(LanguageContext);

  const [inProgressTableData, setInprogressTableData] = useState<any[]>([]);
  const [closedTableData, setClosedTableData] = useState<any[]>([]);
  const [pageInprogress, setPageInprogress] = useState(1);
  const [pageClosed, setPageClosed] = useState(1);
  const [pgCountClosed, setPgCountClosed] = useState(1);
  const [pgCountInprogress, setPgCountInprogress] = useState(1);
  const [perPageClosed, setPerPageClosed] = useState(10);
  const [perPageInprogress, setPerPageInprogress] = useState(10);
  const [inprogressStatusList, setInprogressStatusList] = useState<any[]>([]);
  const [closedStatusList, setClosedStatusList] = useState<any[]>([]);
  const [isClosedTableLoading, setIsClosedTableLoading] = useState(false);
  const [isInprogressTableLoading, setIsInprogressTableLoading] =
    useState(false);
  const [StatusList, setStatusList] = useState<any[]>([]);
  const [bookingDate, setBookingDate] = useState<any>(new Date());
  const [bookingTime, setBookingTime] = useState<any>(new Date());
  const [selectedDemand, setSelectedDemand] = useState<any>();
  const [successUpdate, setSucessUpdate] = useState<boolean>(false);
  const [failureUpdate, setFailureUpadate] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [show, setShow] = useState<boolean>(false);
  const [failureMessage, setFailureMessage] = useState<string>('');
  const [inprogressFilters, setInprogressFilters] = useState<any[]>([]);
  const [closedFilters, setClosedFilters] = useState<any[]>([]);
  const [sortInprogressTable, setSortInprogressTable] = useState([]);
  const [sortClosedTable, setSortClosedTable] = useState([]);

  function StatutInprogressDemandFilter({ column }: { column: any }) {
    const filterList = [
      { value: '', label: translate(`pages.demands.status.all`) },
      ...inprogressStatusList,
    ];
    const { filterValue = filterList[0], setFilter } = column;
    return (
      <SzSelect
        id={'container-select'}
        options={filterList}
        value={filterValue}
        onChange={(value: any) => {
          setFilter(value);
        }}
      />
    );
  }
  function StatutClosedDemandFilter({ column }: { column: any }) {
    const filterList = [
      { value: '', label: translate(`pages.demands.status.all`) },
      ...closedStatusList,
    ];
    const { filterValue = filterList[0], setFilter } = column;
    return (
      <SzSelect
        id={'container-select'}
        options={filterList}
        value={filterValue}
        onChange={(value: any) => {
          setFilter(value);
        }}
      />
    );
  }

  const TABLE_COLUMN = [
    {
      Header: translate('table.header.demands.idDemand'),
      accessor: 'id',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: translate('table.header.demands.client'),
      accessor: 'companyName',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: translate('table.header.demands.container'),
      accessor: 'containerName',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: translate('table.header.demands.materials'),
      accessor: 'materials',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: translate('table.header.demands.date'),
      accessor: 'bookingDate',
      disableFilters: true,
    },
  ];

  const INPROGRESS_TABLE_CULUMN = [
    ...TABLE_COLUMN,
    {
      Header: translate('table.header.demands.status'),
      accessor: 'status',
      Filter: StatutInprogressDemandFilter,
      disableSortBy: true,
    },
    {
      Header: translate('table.header.common.actions'),
      accessor: 'controlButtons',
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  const CLOSED_TABLE_COLUMN = [
    ...TABLE_COLUMN,
    {
      Header: translate('table.header.demands.status'),
      accessor: 'status',
      Filter: StatutClosedDemandFilter,
      disableSortBy: true,
    },
  ];

  const initialSortBy = [
    {
      id: 'bookingDate',
      desc: true,
    },
  ];

  const formatedTableData = (demands: any[]) => {
    return demands.map((demand: any) => {
      return {
        id: demand.idDemand,
        companyName: (
          <div className="demands__nameCell">{demand.company?.name}</div>
        ),
        containerName: (
          <div className="demands__nameCell">{demand.container.name}</div>
        ),
        materials: <Materials demand={demand} />,
        bookingDate: (
          <div className="demands__dateCell col-12">
            <div className="col-lg-12 col-md-1 p-0">
              {demand.bookingDate
                ? `${new Date(demand.bookingDate).toLocaleDateString(
                    navigator.language
                  )}
                  ${new Date(demand.bookingDate)
                    .toISOString()
                    .substring(11, 19)}`
                : ''}
              {'   '}
              {!(
                demand.status.name === STATUS.Closed ||
                demand.status.name === STATUS.Canceled ||
                demand.status.name === STATUS.NoReturn
              ) && (
                <i
                  className={`sz-icon-bold cursor-pointer calendar-3 cursor-pointer`}
                  onClick={() => {
                    setShow(true);
                    setBookingDate(new Date(demand.bookingDate));
                    setBookingTime(
                      moment(demand.bookingDate).utc().format('HH:mm:ss')
                    );
                    setSelectedDemand(demand);
                  }}
                ></i>
              )}
            </div>
          </div>
        ),
        status: (
          <div className="demands__statusCell">
            {demand.status
              ? translate(`pages.demands.status.${demand.status.name}`)
              : ''}
          </div>
        ),
        controlButtons: getStatus(demand, demand.status),
      };
    });
  };

  async function loadDataInprogress() {
    setIsInprogressTableLoading(true);
    const statusFilter = inprogressFilters.find((f) => f.id === 'status');
    const statusFilterValue =
      statusFilter && statusFilter?.value.value !== ''
        ? [statusFilter?.value]
        : inprogressStatusList.map((s) => s.value);

    await new ContainerDemand({
      filters: {
        perPage: perPageInprogress,
        page: pageInprogress,
        idOrganizations: itemFiltersSelected.organization,
        idCompanies: itemFiltersSelected.plant,
        internalOperator: new UserId().get(),
        idStatus: statusFilterValue,
        orderByDate: sortInprogressTable['Date'],
      },
    })
      .getList()
      .then(({ data, total }) => {
        setInprogressTableData(data);
        setPgCountInprogress(Math.ceil(total / perPageInprogress));
        setIsInprogressTableLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsInprogressTableLoading(false);
      });
  }

  async function loadDataClosed() {
    setIsClosedTableLoading(true);
    const statusFilter = closedFilters.find((f) => f.id === 'status');
    const statusFilterValue =
      statusFilter && statusFilter?.value.value != ''
        ? [statusFilter?.value]
        : closedStatusList.map((s) => s.value);

    await new ContainerDemand({
      filters: {
        perPage: perPageClosed,
        page: pageClosed,
        idOrganizations: itemFiltersSelected.organization,
        idCompanies: itemFiltersSelected.plant,
        internalOperator: new UserId().get(),
        idStatus: statusFilterValue,
        orderByDate: sortClosedTable['Date'],
      },
    })
      .getList()
      .then(({ data, total }) => {
        setClosedTableData(data);
        setPgCountClosed(Math.ceil(total / perPageClosed));
        setIsClosedTableLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsClosedTableLoading(false);
      });
  }

  // Handle of change booking date and status
  const handleUpdateDemand = async (
    demand: any,
    statusLabel: string,
    booking?: boolean
  ) => {
    setIsInprogressTableLoading(true);
    const formatedBookingDate = `${moment(bookingDate).format(
      'YYYY-MM-DD'
    )} ${bookingTime}`;
    if (moment(formatedBookingDate) < moment()) {
      setFailureUpadate(true);
      setFailureMessage(translate(`forms.externalDemand.invalidBookingDate`));
      new Promise((resolve) => setTimeout(resolve, 5000)).then((r) => {
        setFailureUpadate(false);
      });
      setIsInprogressTableLoading(false);
      return;
    }
    const idStatus = StatusList.find((s) => s.name === statusLabel)?.id;

    const data = {
      id: demand.id,
      idDemandStatus: idStatus,
      internalOperator: new UserId().get(),
    };
    if (booking) {
      data['bookingDate'] = formatedBookingDate;
    }
    await new ContainerDemand()
      .update({
        data,
      })
      .then(() => {
        new User().sendDemandMail({
          data: {
            demandStatus: statusLabel,
            type: 'external',
            operators: demand.externalOperator,
            demand: demand.idDemand,
          },
        });
      })
      .then(() => {
        loadDataInprogress();
        if (closedStatusList.includes(idStatus)) {
          loadDataClosed();
        }
        setSucessUpdate(true);
        booking
          ? setSuccessMessage(
              translate('forms.internalDemand.successUpdateBookingDate')
            )
          : setSuccessMessage(
              translate('forms.internalDemand.successUpdateStatus')
            );
        new Promise((resolve) => setTimeout(resolve, 5000)).then((r) => {
          setSucessUpdate(false);
        });
      })
      .catch((e) => {
        setFailureUpadate(true);
        new Promise((resolve) => setTimeout(resolve, 5000)).then((r) => {
          setFailureUpadate(false);
        });
        setIsInprogressTableLoading(false);
        console.error(e);
      });
  };

  const getStatus = (demand: any, status: ContainerDemandStatusInstance) => {
    if (status.name === STATUS.InProgress) {
      return (
        <div className="demands__ButtonCell">
          <button
            type="button"
            className=" bg-primary text-white btn"
            onClick={() => {
              return handleUpdateDemand(demand, STATUS.Planned);
            }}
          >
            <i className={`my-auto size-5 material-icons`}>check</i>
          </button>
          <button
            type="button"
            className=" bg-primary text-white btn"
            onClick={() => {
              return handleUpdateDemand(demand, STATUS.Canceled);
            }}
          >
            <i className={`my-auto size-5 material-icons`}>close</i>
          </button>
        </div>
      );
    } else if (
      status.name === STATUS.NewDate ||
      status.name === STATUS.Planned
    ) {
      return (
        <div className="demands__ButtonCell">
          <button type="button" className=" bg-primary text-white btn" disabled>
            <i className={`my-auto size-5 material-icons`}>check</i>
          </button>
          <button
            type="button"
            className=" bg-primary text-white btn"
            onClick={() => {
              return handleUpdateDemand(demand, STATUS.Canceled);
            }}
          >
            <i className={`my-auto size-5 material-icons`}>close</i>
          </button>
        </div>
      );
    } else {
      return (
        <div className="demands__ButtonCell">
          <button type="button" className="bg-primary text-white btn" disabled>
            <i className={`my-auto size-5 material-icons`}>check</i>
          </button>
          <button type="button" className=" bg-primary text-white btn" disabled>
            <i className={`my-auto size-5 material-icons`}>close</i>
          </button>
        </div>
      );
    }
  };

  // Handler of table filters
  const handleInprogressFiltersChange = (filter: any[]) => {
    if (JSON.stringify(filter) !== JSON.stringify(inprogressFilters)) {
      setPageInprogress(1);
      setInprogressFilters(filter);
    }
  };
  const handleClosedFiltersChange = (filter: any[]) => {
    if (JSON.stringify(filter) !== JSON.stringify(closedFilters)) {
      setPageClosed(1);
      setClosedFilters(filter);
    }
  };

  useEffect(() => {
    async function loadStatus() {
      await new ContainerDemandStatus()
        .getList()
        .then(({ data }) => {
          const inprogressStatus: any[] = [];
          const closedStatus: any[] = [];

          data.map((status) => {
            if (INPROGRESS_STATUS.includes(status.name))
              inprogressStatus.push({
                value: status.id,
                label: translate(`pages.demands.status.${status.name}`),
              });
            else if (CLOSED_STATUS.includes(status.name))
              closedStatus.push({
                value: status.id,
                label: translate(`pages.demands.status.${status.name}`),
              });
          });
          setStatusList(data);
          setInprogressStatusList(inprogressStatus);
          setClosedStatusList(closedStatus);
        })
        .catch((e) => {
          console.error(e);
        });
    }

    try {
      setIsInprogressTableLoading(true);
      setIsClosedTableLoading(true);
      loadStatus();
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (inprogressStatusList.length > 0) {
      loadDataInprogress();
    }
  }, [
    pageInprogress,
    perPageInprogress,
    inprogressStatusList,
    inprogressFilters,
    sortInprogressTable,
    itemFiltersSelected,
  ]);

  useEffect(() => {
    if (closedStatusList.length > 0) {
      loadDataClosed();
    }
  }, [
    pageClosed,
    perPageClosed,
    closedStatusList,
    closedFilters,
    sortClosedTable,
    itemFiltersSelected,
  ]);

  return (
    <>
      <Layout withFooter>
        <ComponentWithTitle title="pages.demands.inprogress" icon="apps">
          <div className="demands row pt-1 m-0 w-100">
            {successUpdate && (
              <div className="col-9 pt-1">
                <SzAlert variant="success" transparent>
                  {successMessage}
                </SzAlert>
              </div>
            )}
            {failureUpdate && (
              <div className="col-9 pt-1">
                <SzAlert variant="danger" transparent>
                  {failureMessage}
                </SzAlert>
              </div>
            )}
            <Table
              type="hidden"
              columns={INPROGRESS_TABLE_CULUMN}
              data={formatedTableData(inProgressTableData)}
              disabledColumnActionsList={[]}
              isInternalPagination={true}
              onPageChange={setPageInprogress}
              nbPerPage={perPageInprogress}
              onNbPerPageChange={setPerPageInprogress}
              pageCount={pgCountInprogress}
              initialFilters={inprogressFilters}
              onFiltersChange={handleInprogressFiltersChange}
              isLoading={isInprogressTableLoading}
              initialSortBy={initialSortBy}
              onSortTableChange={setSortInprogressTable}
            />
          </div>
        </ComponentWithTitle>
        <ComponentWithTitle title="pages.demands.closed" icon="apps">
          <div className="demands row pt-1 m-0 w-100">
            <Table
              type="hidden"
              columns={CLOSED_TABLE_COLUMN}
              data={formatedTableData(closedTableData)}
              disabledColumnActionsList={[]}
              isInternalPagination={true}
              onPageChange={setPageClosed}
              nbPerPage={perPageClosed}
              onNbPerPageChange={setPerPageClosed}
              pageCount={pgCountClosed}
              initialFilters={closedFilters}
              onFiltersChange={handleClosedFiltersChange}
              isLoading={isClosedTableLoading}
              initialSortBy={initialSortBy}
              onSortTableChange={setSortClosedTable}
            />
          </div>
        </ComponentWithTitle>
      </Layout>
      <SzModal
        title={translate(`pages.demands.bookingDate`)}
        show={show}
        centered={true}
        handleClose={(): void => {
          setShow(false);
        }}
        size={'lg'}
      >
        <div className="row pt-1 pb-3 m-0 w-100">
          {translate(`pages.demands.chooseDate`)}
        </div>
        <div className="row pt-1 pb-3 m-0 w-100">
          <div className="col-6 pt-2 m-0 ">
            <SzDatePicker
              {...{
                format: 'dd/MM/yyyy',
                startMinDate: new Date(),
                onChangeStartDate: (v): void => setBookingDate(v),
                classNameStartDate: 'col-12',
                startDate: bookingDate,
              }}
            />
          </div>
          <div className="col-6 pt-2 m-0">
            <TimePicker
              showSecond={false}
              defaultValue={moment(bookingDate).utc()}
              onChange={(value) => setBookingTime(value.format('HH:mm:ss'))}
              placeholder={translate(`pages.demands.chooseTime`)}
              popupClassName={'rc-time-picker-panel-customer'}
              placement={'bottomLeft'}
              minuteStep={5}
            />
          </div>
        </div>
        <div className="col-12 pt-3 m-2">
          <SzButton
            className="my-auto"
            variant="secondary"
            onClick={() => {
              setShow(false);
              handleUpdateDemand(selectedDemand, STATUS.NewDate, true);
            }}
          >
            {translate(`pages.demands.validate`)}
          </SzButton>
        </div>
      </SzModal>
    </>
  );
};
