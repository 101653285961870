import React, { useContext } from 'react';
import {
  ClassNameInterface,
  DisabledInterface,
  LinkInterface,
} from '../../../../interfaces';
import { Link, useHistory } from 'react-router-dom';
import './navitem.scss';
import { LanguageContext } from '../../../../contexts';

export const NavItem: React.FC<
  ClassNameInterface & DisabledInterface & LinkInterface
> = ({ children, disabled, to, className }) => {
  const { translate } = useContext(LanguageContext);
  const { location } = useHistory();
  return (
    <li
      className={`text-center nav-item mx-auto mx-xl-0 ml-xl-3 d-flex text-uppercase size-3 ${
        className || ''
      } ${to === location.pathname && 'border-bottom'}`}
    >
      <Link
        {...{
          'aria-disabled': disabled,
          className: `nav-link my-auto nav-item-route active${
            (disabled && ' disabled') || ''
          }${(to === location.pathname && ' active') || ''}`,
          to,
        }}
      >
        {children && translate(`appbar.routes.${children.toString()}`)}
      </Link>
    </li>
  );
};
