import React, { Dispatch, SetStateAction, useContext } from 'react';
import { SzSelect } from 'react-theme-components';
import { ClassNameInterface } from '../../interfaces';
import './filter.scss';
import { BaseStoreContext, LanguageContext } from '../../contexts';
import { sprintf } from '../../helpers';

import PropTypes from 'prop-types';

function SzSelectInternal({
  id,
  options,
  value,
  className,
  isDisabled,
  isMulti,
  isMultiList,
  isSearchable,
  name,
  onChange,
  placeholder,
  isClearable,
  defaultOptions,
  noOptionsMessage,
}) {
  return (
    <SzSelect
      id={id}
      placeholder={placeholder}
      options={options}
      value={value}
      onChange={onChange}
      name={name}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isMulti={isMulti}
      isMultiList={isMultiList}
      className={className}
      defaultOptions={defaultOptions}
      noOptionsMessage={noOptionsMessage}
    />
  );
}

SzSelectInternal.propTypes = {
  id: PropTypes.string,
  defaultOptions: PropTypes.array,
  isClearable: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.any,
  ]),
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isMultiList: PropTypes.bool,
  isSearchable: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.node,
  noOptionsMessage: PropTypes.string,
};

export interface OptionInterface {
  label: string;
  value?: string | boolean;
}

interface DropdownFilterInterface {
  isMulti?: boolean | undefined;
  options?: OptionInterface[];
  placeholder?: string;
}

interface SelectFilterInterface extends DropdownFilterInterface {
  onChange: Dispatch<SetStateAction<OptionInterface[]>>;
  value: OptionInterface[] | OptionInterface;
}

interface SelectOneFilterInterface {
  onChange: Dispatch<SetStateAction<OptionInterface | [] | undefined>>;
  value: OptionInterface | [] | undefined | null;
}

export const SelectFilter: React.FC<
  ClassNameInterface & DropdownFilterInterface
> = ({ placeholder, ...rest }) => {
  const { translate } = useContext(LanguageContext);
  return (
    <SzSelectInternal
      {...{
        ...rest,
        isSearchable: false,
        placeholder: translate(`filters.${placeholder}.placeholder`),
      }}
    />
  );
};

export const MaterialFamilyFilter: React.FC<
  ClassNameInterface & SelectFilterInterface
> = ({ ...rest }) => (
  <SelectFilter
    {...{
      ...rest,
      name: 'idMaterialFamily',
      placeholder: 'materialFamily',
    }}
  />
);

export const MaterialSubFamilyFilter: React.FC<
  ClassNameInterface & SelectFilterInterface
> = ({ ...rest }) => (
  <SelectFilter
    {...{
      ...rest,
      name: 'idMaterialSubFamily',
      placeholder: 'materialSubFamily',
    }}
  />
);

export const MaterialReferentialFilter: React.FC<
  ClassNameInterface & SelectFilterInterface
> = ({ ...rest }) => (
  <SelectFilter
    {...{
      ...rest,
      isMulti: true,
      name: 'idMaterialReferential',
      placeholder: 'materialReferential',
    }}
  />
);

export const IndexFilter: React.FC<
  ClassNameInterface & SelectFilterInterface
> = ({ ...rest }) => (
  <SelectFilter
    {...{
      ...rest,
      isMulti: true,
      name: 'idMaterialIndex',
      placeholder: 'indexes',
    }}
  />
);

export const ByFilter: React.FC<
  ClassNameInterface & SelectOneFilterInterface
> = ({ ...rest }) => (
  <SelectFilter
    {...{
      ...rest,
      name: 'by',
      options: [
        {
          label: 'by year',
          value: 'year',
        },
        {
          label: 'by month',
          value: 'month',
        },
      ],
      placeholder: 'by',
    }}
  />
);

export const YearFilter: React.FC<
  ClassNameInterface & SelectOneFilterInterface
> = ({ ...rest }) => {
  const options: OptionInterface[] = [];
  for (let i = 0; i < 20; i++) {
    const value = `${2020 - i}`;
    options.push({ label: value, value });
  }
  return <SelectFilter {...{ ...rest, options, placeholder: 'year' }} />;
};

export const OrganizationFilter: React.FC<
  ClassNameInterface & SelectFilterInterface
> = ({ ...rest }) => {
  const {
    filters: { organizations },
  } = useContext(BaseStoreContext);
  return (
    <SelectFilter
      {...{
        ...rest,
        isMulti: true,
        isMultiList: true,
        isClearable: true,
        options: organizations || [],
        name: 'idOrganization',
        placeholder: 'organization',
      }}
    />
  );
};

export const PlantFilter: React.FC<
  ClassNameInterface & SelectFilterInterface
> = ({ ...rest }) => {
  const {
    filters: { plants },
  } = useContext(BaseStoreContext);
  return (
    <SelectFilter
      {...{
        ...rest,
        isMulti: true,
        isMultiList: true,
        isClearable: true,
        options: plants || [],
        name: 'idCompany',
        placeholder: 'plant',
      }}
    />
  );
};

export const ContractFilter: React.FC<
  ClassNameInterface & SelectFilterInterface
> = ({ ...rest }) => {
  const { translate } = useContext(LanguageContext);
  const t = (value: string): string =>
    translate(sprintf('filters.contract.options.%s', [value]));
  return (
    <SelectFilter
      {...{
        ...rest,
        isMulti: false,
        options: [
          {
            label: t('both'),
            value: '',
          },
          {
            label: t('services'),
            value: true,
          },
          {
            label: t('valorisations'),
            value: false,
          },
        ],
        placeholder: 'contract',
      }}
    />
  );
};
