import React, {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { MaterialInstance } from '../interfaces/model';
import {
  CommonFilterInterface,
  CommonFilterSelectedInterface,
  MaterialFilterInterface,
} from '../interfaces/Filter';
import { Organization } from '../interfaces/providers';
import { OptionInterface } from '../components/Filters';
import { UserContext } from './UserContext';

export const ADD_ITEM_CATALOG = 'ADD_ITEM_CATALOG';
export const SET_CATALOG = 'SET_CATALOG';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_ITEM_FILTERS_SELECTED = 'SET_ITEM_FILTERS_SELECTED';
export const SET_OPEN_FILTERS = 'SET_OPEN_FILTERS';
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';

export const INIT_ANALYSIS_MATERIALS = 'INIT_ANALYSIS_MATERIALS';
export const SET_ANALYSIS_MATERIALS = 'SET_ANALYSIS_MATERIALS';
export const SET_ANALYSIS_SELECTED_MATERIALS =
  'SET_ANALYSIS_SELECTED_MATERIALS';

export const INIT_MOVEMENT_MATERIALS = 'INIT_MOVEMENT_MATERIALS';
export const SET_MOVEMENT_MATERIALS = 'SET_MOVEMENT_MATERIALS';
export const SET_MOVEMENT_SELECTED_MATERIALS =
  'SET_MOVEMENT_SELECTED_MATERIALS';

interface BaseStoreInterface {
  dispatch: Dispatch<ActionInterface>;
  catalog: MaterialInstance[];
  analysis: MaterialFilterInterface;
  movement: MaterialFilterInterface;
  filters: CommonFilterInterface;
  itemFiltersSelected: CommonFilterSelectedInterface;
  itemSelected?: MaterialInstance;
  openFilters: boolean;
  loading?: boolean;
  setSelectedMaterials: (selection: MaterialInstance[], page: string) => void;
}

const defaultValue: BaseStoreInterface = {
  dispatch: () => {
    return;
  },
  loading: false,
  catalog: [],
  analysis: {
    materials: [],
    selectedMaterials: [],
  },
  movement: {
    materials: [],
    selectedMaterials: [],
  },
  filters: {
    contracts: [],
    organizations: [],
    plants: [],
  },
  itemFiltersSelected: {
    contract: [],
    organization: [],
    plant: [],
  },
  itemSelected: undefined,
  openFilters: false,
  setSelectedMaterials: (selection) => {
    return;
  },
};

export interface ActionInterface {
  payload:
    | boolean
    | CommonFilterInterface
    | CommonFilterSelectedInterface
    | MaterialInstance
    | MaterialInstance[]
    | OptionInterface[]
    | string[]
    | { [key: string]: OptionInterface[] }
    | string
    | { loading: boolean; data: MaterialInstance[] };
  type: string;
}

function reducer(
  state: BaseStoreInterface,
  { payload, type }: ActionInterface
): BaseStoreInterface {
  switch (type) {
    case ADD_ITEM_CATALOG: {
      const catalog = payload as MaterialInstance[];
      return {
        ...state,
        catalog: [...state.catalog, ...catalog],
      };
    }
    case SET_CATALOG: {
      const catalog = (payload as any).data as MaterialInstance[];
      return {
        ...state,
        catalog,
        loading: (payload as any).loading,
      };
    }
    case SET_FILTERS: {
      const filters = payload as CommonFilterInterface;
      return {
        ...state,
        filters: {
          ...state.filters,
          ...filters,
        },
      };
    }
    case SET_ITEM_FILTERS_SELECTED: {
      const filters = payload as CommonFilterSelectedInterface;
      if (filters.organization) {
        filters.plant = [];
      }
      return {
        ...state,
        itemFiltersSelected: {
          ...state.itemFiltersSelected,
          ...filters,
        },
      };
    }
    case SET_OPEN_FILTERS: {
      return {
        ...state,
        openFilters: !!payload,
      };
    }
    case SET_SELECTED_ITEM: {
      const itemSelected = payload as MaterialInstance;
      return {
        ...state,
        itemSelected,
      };
    }
    case INIT_ANALYSIS_MATERIALS: {
      return {
        ...state,
        analysis: {
          materials: payload as MaterialInstance[],
          selectedMaterials: (payload as MaterialInstance[]).map((i) => i.id),
        },
      };
    }
    case SET_ANALYSIS_MATERIALS: {
      return {
        ...state,
        analysis: {
          ...state.analysis,
          materials: payload as MaterialInstance[],
        },
      };
    }
    case SET_ANALYSIS_SELECTED_MATERIALS: {
      return {
        ...state,
        analysis: {
          ...state.analysis,
          selectedMaterials: payload as string[],
        },
      };
    }
    case INIT_MOVEMENT_MATERIALS: {
      return {
        ...state,
        movement: {
          materials: payload as MaterialInstance[],
          selectedMaterials: (payload as MaterialInstance[]).map((i) => i.id),
        },
      };
    }
    case SET_MOVEMENT_MATERIALS: {
      return {
        ...state,
        movement: {
          ...state.movement,
          materials: payload as MaterialInstance[],
        },
      };
    }
    case SET_MOVEMENT_SELECTED_MATERIALS: {
      return {
        ...state,
        movement: {
          ...state.movement,
          selectedMaterials: payload as string[],
        },
      };
    }
    default:
      return state;
  }
}

export const BaseStoreContext = createContext(defaultValue);

export const objectToOptionInterface = (
  data: any[] /*eslint-disable-line*/,
  fields: string[] = [],
  separator = ''
): OptionInterface[] =>
  data.map((item) => ({
    label: fields.length
      ? fields.map((field) => item[field.toString()]).join(separator)
      : item.name,
    value: item.id,
  }));

export const BaseStoreProvider: React.FC = ({ children }) => {
  const [{ filters, ...rest }, dispatch] = useReducer(reducer, defaultValue);
  const { isLogged } = useContext(UserContext);

  useEffect(() => {
    if (isLogged) {
      new Organization({ filters: { perPage: 1000 } })
        .getList()
        .then(({ data }) =>
          dispatch({
            payload: {
              organizations: objectToOptionInterface(
                data,
                ['level', 'name'],
                ' - '
              ),
            },
            type: SET_FILTERS,
          })
        );
    }
    return;
  }, [dispatch, isLogged]);

  const getSelector = (page) => {
    switch (page) {
      case 'analysis':
        return SET_ANALYSIS_SELECTED_MATERIALS;
      case 'movement':
        return SET_MOVEMENT_SELECTED_MATERIALS;
      default:
        return SET_ANALYSIS_SELECTED_MATERIALS;
    }
  };

  const setSelectedMaterials = (
    selection: MaterialInstance[],
    page: string
  ) => {
    dispatch({
      payload: selection,
      type: getSelector(page),
    });
  };

  return (
    <BaseStoreContext.Provider
      value={{
        ...rest,
        filters,
        dispatch,
        setSelectedMaterials,
      }}
    >
      {children}
    </BaseStoreContext.Provider>
  );
};
