import React, { MutableRefObject, useContext } from 'react';
import { SzIcon, SzTypographie } from 'react-theme-components';
import { Form } from '../Form';
import { User } from '../../../actions';
import { email } from '../Field';
import { LanguageContext, UserContext } from '../../../contexts';

export const ResetPasswordRequestForm: React.FC = () => (
  <Form
    back
    fields={[
      {
        ...email(),
        icon: 'people-man-1',
      },
    ]}
    submitForm={(
      data: FormData,
      ref: MutableRefObject<{ reset: () => void }>
    ): Promise<void> => {
      return new User().resetPasswordRequest({ data, ref });
    }}
    successMessage="resetPasswordRequest"
    withRecaptcha
  />
);

export const ResetPasswordForCurrentUserRequestForm: React.FC = () => {
  const { username } = useContext(UserContext);
  const { translate } = useContext(LanguageContext);

  return (
    <Form
      alertsTop={false}
      buttonContent={
        <span className="d-inline-flex align-items-center m-auto">
          <SzIcon icon="synchronize-arrow-1" variant="line" />
          <SzTypographie variant="text" className="ml-3 m-0">
            {translate('forms.password.reset.label')}
          </SzTypographie>
        </span>
      }
      fields={[]}
      keepButton={true}
      submitForm={(
        data: FormData,
        ref: MutableRefObject<{ reset: () => void }>
      ): Promise<void> => {
        data['email'] = username ?? '';
        return new User().resetPasswordRequest({ data, ref });
      }}
      successMessage="password.reset"
      withRecaptcha
    />
  );
};
