import React, { useContext } from 'react';
import { OptionInterface, SelectFilter } from '../Filters';
import { Language as LanguageLS, Preference } from '../../helpers';
import { LanguageContext } from '../../contexts';
import { Preference as PreferenceProvider } from '../../actions';
import { PreferenceInterface } from '../../interfaces';

export const Language: React.FC = () => {
  const { languages, selectedLanguage, setSelectedLanguage } =
    useContext(LanguageContext);
  const updateLanguage = (option: OptionInterface): void => {
    setSelectedLanguage(option);
    new LanguageLS().set(option.value as string);
    new PreferenceProvider().update({
      data: {
        language: option.value,
      },
      id: (new Preference().getDecoded() as PreferenceInterface)['@id'],
    });
  };

  return (
    <SelectFilter
      {...{
        onChange: updateLanguage,
        options: languages,
        value: selectedLanguage,
      }}
    />
  );
};
