import React, { useContext, useState, useEffect } from 'react';
import { Layout } from '../../components/Layout';
import { SzTypographie, SzButton, SzIcon } from 'react-theme-components';
import { BaseStoreContext, LanguageContext } from '../../contexts';
import { useLogged } from '../../hooks';
import { ComponentWithTitle } from '../../components/Common';
import Table from '../../components/TableContainer/Table';
import { Document } from '../../actions';
import './Documents.scss';

export const Documents: React.FC = () => {
  useLogged();
  const { translate } = useContext(LanguageContext);
  const { itemFiltersSelected: { plant, organization } } = useContext(BaseStoreContext);
  const [page , setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pgCount, setPgCount] = useState(0);
  const [sortTable, setSortTable] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [currentDownloadId, setCurrentDownloadId] = useState<string>('')
  const initialSortBy = [
    {
      id: 'createdAt',
      desc: true,
    },
  ];

  const TABLE_COLUMN = [
    {
      Header: translate('table.header.documents.name'),
      accessor: 'name',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: translate('table.header.documents.company'),
      accessor: 'company',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: translate('table.header.documents.type'),
      accessor: 'type',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: translate('table.header.documents.createdAt'),
      accessor: 'createdAt',
      isSorted: false,
      disableFilters: true,
    },
    {
      Header: '',
      accessor: 'controlButtons',
      disableFilters: true,
      disableSortBy: true,
    },
  ];


  const downloadFile = async (documentObject: any) => {
    try {
      setCurrentDownloadId(documentObject.id)
      const extension = documentObject.blobname.split(".").pop()
      const data = await new Document()
        .downloadDocument({ id: `${documentObject.id}/download` })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([data]))
      link.setAttribute('download', `${documentObject.filename}.${extension}`)
      document.body.appendChild(link)
      link.click()
      setCurrentDownloadId("")
      return true
    } catch (error) {
      setCurrentDownloadId("")
    }
  } 
  
  const formatedTableData = (documents: any[]) => {
    return documents.map((document: any) => {
      return {
        id: document.id,
        name: document.filename,
        company: (
          <div className="documents__companyCell">{document.companyName}</div>
        ),
        type: document.type,
        createdAt: (
          <div className="documents__dateCell">
            {
              document.createdAt
              ? `${new Date(document.createdAt).toLocaleDateString()} ${new Date(
                  document.createdAt
                ).toLocaleTimeString()}`
              : ''
            }
            {'  '}
          </div>
        ),
        controlButtons: (
          <div>
            <SzButton
              className="my-auto h-100"
              variant="secondary"
              onClick={(e) => {
                downloadFile(document)
              }}
              isDisabled={document.id === currentDownloadId}
              loader={document.id === currentDownloadId}
            >
              <span className="d-inline-flex align-items-center m-auto px-0 py-1 px-md-2">
                <SzIcon variant="line" icon="common-file-text-download" />
              </span>
            </SzButton>
        </div>
        ),
      };
    });
  };

  async function loadData() {
    setIsTableLoading(true);
    await new Document({
      filters: {
        perPage: perPage,
        page: page,
        organizations: organization,
        companies: plant,
        'order[createdAt]': sortTable[translate('table.header.documents.createdAt')],
      },
    })
    .getList()
      .then(({ data, total }) => {
        setTableData(data);
        setPgCount(Math.ceil(total / perPage));
        setIsTableLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsTableLoading(false);
      });
  }

  useEffect(() => {
    loadData();
  }, [
    page,
    perPage,
    sortTable,
    plant,
    organization
  ]);

  return (
    <Layout withFooter>
      <ComponentWithTitle title="pages.documents" icon="description">
        <div className="card p-4 d-block col-12">
          <SzTypographie variant="text" className="fs-2 col-12 mb-0">
              <div className="documents row pt-1 m-0 w-100">
                <Table
                  type="hidden"
                  columns={TABLE_COLUMN}
                  data={formatedTableData(tableData)}
                  disabledColumnActionsList={[
                    translate('table.header.documents.name'),
                    translate('table.header.documents.company'),
                    translate('table.header.documents.type')
                  ]}
                  isInternalPagination={true}
                  onPageChange={setPage}
                  nbPerPage={perPage}
                  onNbPerPageChange={setPerPage}
                  pageCount={pgCount}
                  isLoading={isTableLoading}
                  initialSortBy={initialSortBy}
                  onSortTableChange={setSortTable}
                  withFilters={false}
                />
            </div>
          </SzTypographie>
        </div>
      </ComponentWithTitle>
    </Layout>
  );
};

