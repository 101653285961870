import { APIPlatform } from './API';
import { SyntheticEvent } from 'react';

interface ContactInterface {
  data: SyntheticEvent<Element, Event>;
}

export class Contact extends APIPlatform {
  public endpoint = '/users';

  public send({ data }: ContactInterface): Promise<void> {
    this.endpoint = '';
    return this.postRequest({
      data,
      endpoint: '/contact',
    })
      .then(() => {
        return;
      })
      .catch();
  }
}
