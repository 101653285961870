import React, { useContext, useRef } from 'react';
import { Bar as ChartBar } from 'react-chartjs-2';
import './bar.scss';
import { ChartContext } from '../../../contexts';
import { Loader } from '../../Loader';
import { BarInterface } from './Bar';
import { FilterMaterials } from './FilterMaterials';

export function formatNumber(number: number) {
  if (number % 1 !== 0) {
    return new Intl.NumberFormat('fr-FR', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(number);
  }
  return new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
}

export const StackedBars: React.FC<BarInterface> = ({
  children,
  options = {},
  type,
}) => {
  const ref = useRef(null);
  const { data } = useContext(ChartContext);

  const total = (index: number) => {
    const totals = data?.datasets.map((item) => {
      return item.data[index];
    });
    return totals?.reduce(function (a, b) {
      return a + b;
    }, 0);
  };

  return (
    <div className="bg-white py-3">
      <div className="px-4 pb-3">
        {children}
        {!data ? (
          <Loader text={`charts.${type}`} />
        ) : (
          <div className="pt-4 pb-2">
            <div className="chart-container">
              <ChartBar
                {...{
                  data,
                  options: {
                    ...options,
                    layout: { responsive: true },
                    title: {
                      display: false,
                    },
                    plugins: {
                      legend: {
                        display:
                          data?.datasets?.filter(
                            (dataset) => dataset.label != 'NA'
                          ).length > 0,
                        position: 'bottom',
                        align: 'start',
                      },
                      tooltip: {
                        mode: 'x',
                        position: 'nearest',
                        callbacks: {
                          title(tooltipItem: any) {
                            const title = [tooltipItem[0].label];
                            title.push(
                              `${formatNumber(
                                total(tooltipItem[0].dataIndex) ?? 0
                              )} t`
                            );
                            return title;
                          },
                          label(tooltipItem: any) {
                            const label = tooltipItem.dataset.label || '';
                            return `${label} : ${formatNumber(
                              tooltipItem.raw
                            )}  t`;
                          },
                        },
                        // Title Style
                        titleColor: '#030F40',
                        titleFont: { size: 16, family: 'DINPro' },
                        titleSpacing: 10,
                        titleMarginBottom: 12,
                        padding: 10,
                        // Body Style
                        bodyColor: '#030F40',
                        bodyFont: { size: 12 },
                        bodySpacing: 5,

                        // Global Style
                        backgroundColor: '#fff',
                      },
                    },
                    scales: {
                      x: {
                        grid: {
                          display: false,
                        },
                        stacked: true,
                      },
                      y: {
                        beginAtZero: true,
                        display: true,
                        stacked: true,
                      },
                    },
                  },
                  ref,
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
