import React, {
  Dispatch,
  MutableRefObject,
  ReactElement,
  SyntheticEvent,
  useContext,
  useState,
  useRef,
} from 'react';
import { FieldInterface } from './Field';
import { Link, useHistory } from 'react-router-dom';
import {
  FormContext,
  FormProvider,
  LanguageContext,
  SET_ERROR,
  SET_LOADING,
  SET_SUCCESS,
  SET_VALUE,
} from '../../contexts';
import { SzAlert, SzButton, SzInput, SzTextArea } from 'react-theme-components';
import './form.scss';
import Recaptcha from 'react-recaptcha';

export interface LinkInterface {
  label: string;
  path: string;
}

export interface BaseFormInterface {
  additionalLinks?: LinkInterface[];
  alertsTop?: boolean;
  back?: boolean;
  buttonContent?: ReactElement;
  buttonText?: string;
  keepButton?: boolean;
  submitForm?: (
    e: any, // eslint-disable-line
    ref: MutableRefObject<HTMLFormElement>
  ) => Promise<void>;
  successMessage?: string;
  validate?: (ref: MutableRefObject<HTMLFormElement>) => boolean;
  withRecaptcha?: boolean;
}

export interface FormInterface extends BaseFormInterface {
  fields: FieldInterface[];
}

export const jsonToFormData = (
  element: any /*eslint-disable-line*/
): FormData => {
  const fd = new FormData();
  Object.keys(element).forEach((e) => fd.append(e, element[e])); // eslint-disable-line
  return fd;
};
// eslint-disable-line
export const formatformToJson = (
  elements: any /*eslint-disable-line*/
): any /*eslint-disable-line*/ => {
  const formData = new FormData(elements);
  return Object.fromEntries(formData);
};

export interface BaseInputInterface {
  field: FieldInterface;
}

const Field: React.FC<BaseInputInterface> = ({ field }) => {
  const { translate } = useContext(LanguageContext);
  const { dispatch, isLoading, values } = useContext(FormContext);
  return (
    <div className={field.className || 'col-12'}>
      <div className="py-3">
        {'textarea' === field.type ? (
          <SzTextArea
            label={
              field.label && translate(`forms.fields.${field.label}.label`)
            }
            autocomplete={false}
            disabled={isLoading}
            rows={3}
            placeholder={translate(
              `forms.fields.${field.placeholder}.placeholder`
            )}
            value={values[field.name] || ''}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => {
              dispatch({
                payload: {
                  name: field.name,
                  value: event.currentTarget.value,
                },
                type: SET_VALUE,
              });
              return;
            }}
          />
        ) : (
          <SzInput
            type={field.type}
            autocomplete={false}
            name={field.name}
            value={values[field.name] || ''}
            onChange={({ target }): void => {
              dispatch({
                payload: {
                  name: field.name,
                  value: target.value,
                },
                type: SET_VALUE,
              });
              return;
            }}
            label={
              field.label && translate(`forms.fields.${field.label}.label`)
            }
            labelClassName="d-block position-relative required"
            required
            disabled={isLoading}
            icon={field.icon}
            placeholder={translate(
              `forms.fields.${field.placeholder}.placeholder`
            )}
          />
        )}
      </div>
    </div>
  );
};

interface FormPropertiesInterface {
  onSubmit: (event: SyntheticEvent) => void;
  ref: MutableRefObject<HTMLFormElement>;
}

interface InnerFormPropertiesInterface extends BaseFormInterface {
  dispatch: Dispatch<any>; // eslint-disable-line
  recaptchaRef: Recaptcha | null;
  ref: MutableRefObject<HTMLFormElement>;
  withRecaptcha?: boolean;
  setCaptchaOk?: any;
}

function noop() {} // eslint-disable-line

function resetRecaptchaIfNeeded({ recaptchaRef, values }: any): Promise<any> {
  if (recaptchaRef && !values['g-recaptcha-response']) {
    return Promise.reject(new Error('No captcha.'));
  }

  return Promise.resolve(values);
}

const formProperties = ({
  dispatch,
  recaptchaRef,
  ref,
  submitForm,
  validate,
  setCaptchaOk,
}: InnerFormPropertiesInterface): FormPropertiesInterface => {
  return {
    onSubmit: (event: SyntheticEvent): void => {
      event.preventDefault();
      dispatch({
        type: 'SET_LOADING',
        payload: true,
      });
      const values = formatformToJson(event.target);

      if (validate && !validate(ref)) {
        dispatch({
          type: SET_LOADING,
          payload: false,
        });
        return;
      }

      resetRecaptchaIfNeeded({ recaptchaRef, values })
        .then((values: any) => {
          if (!submitForm) {
            return Promise.resolve();
          }

          return submitForm(values, ref);
        })
        .then(() => {
          dispatch({ type: SET_SUCCESS });
          Object.keys(values).forEach((name: string) =>
            dispatch({
              payload: {
                name,
                value: '',
              },
              type: SET_VALUE,
            })
          );
        })
        .catch((err: Error) => {
          console.error('An error occured while submitting the form', err);
          dispatch({ type: SET_ERROR });
        })
        .finally(() => {
          recaptchaRef?.reset();
          setCaptchaOk(false);
          dispatch({
            type: SET_LOADING,
            payload: false,
          });
        });
    },
    ref,
  };
};

interface AlertsInterface {
  error: boolean;
  isLoading: boolean;
  success: boolean;
  successMessage?: string;
}

const Alerts: React.FC<AlertsInterface> = ({
  error,
  isLoading,
  success,
  successMessage,
}) => {
  const { translate } = useContext(LanguageContext);

  return !isLoading && error ? (
    <div className="col-12">
      <SzAlert variant="danger">{translate(`forms.errorMessage`)}</SzAlert>
    </div>
  ) : successMessage && !isLoading && success ? (
    <div className="col-12">
      <SzAlert variant="success">
        {translate(`forms.${successMessage}.successMessage`)}
      </SzAlert>
    </div>
  ) : null;
};

let hasCaptcha = false;

const BaseForm: React.FC<BaseFormInterface> = ({
  additionalLinks,
  alertsTop = true,
  back = false,
  buttonContent,
  buttonText,
  children,
  keepButton = false,
  successMessage,
  withRecaptcha,
  ...rest
}) => {
  const { selectedLanguage, translate } = useContext(LanguageContext);
  const { dispatch, error, isLoading, success } = useContext(FormContext);
  const [recaptchaRef, setRecaptchaRef] = useState<Recaptcha | null>(null);
  const [captchaOk, setCaptchaOk] = useState(false);
  const { goBack, push } = useHistory();
  const ref = useRef<HTMLFormElement>();
  const formProps = formProperties({
    dispatch,
    ref: ref as MutableRefObject<HTMLFormElement>,
    recaptchaRef,
    withRecaptcha,
    setCaptchaOk,
    ...rest,
  });

  setTimeout(() => {
    if (withRecaptcha && !hasCaptcha && recaptchaRef) {
      (recaptchaRef as any)._renderGrecaptcha();
    }
  }, 1000);

  return (
    <form {...formProps}>
      {alertsTop ? (
        <Alerts
          {...{
            error,
            isLoading,
            success,
            successMessage,
          }}
        />
      ) : null}
      {!success && children}
      <div className="col-12 text-center d-flex m-auto pt-3">
        {!keepButton && success ? (
          <SzButton
            isDisabled={isLoading}
            onClick={(): void => push('/')}
            variant="secondary"
          >
            <i className={`align-middle size-5 material-icons`}>arrow_back</i>{' '}
            {translate('forms.returnToHome')}
          </SzButton>
        ) : (
          <>
            {back && (
              <div className="pr-3 d-flex">
                <SzButton
                  isDisabled={isLoading}
                  onClick={(): void => goBack()}
                  variant="secondary"
                >
                  <i className={`align-middle size-5 material-icons`}>
                    arrow_back
                  </i>
                </SzButton>
              </div>
            )}
            <SzButton
              {...{
                isDisabled: isLoading,
                loader: isLoading,
                type: 'submit',
                variant: 'secondary',
                onClick: (event: any) => {
                  if (withRecaptcha && recaptchaRef && !captchaOk) {
                    event.preventDefault();
                    recaptchaRef.execute();
                    return false;
                  }

                  return;
                },
              }}
            >
              {buttonContent
                ? buttonContent
                : translate(`forms.submit.${buttonText || 'classic'}`)}
            </SzButton>
          </>
        )}
      </div>
      {additionalLinks ? (
        <div className="text-center w-100 pt-1">
          {additionalLinks.map(
            (additionalLink: LinkInterface, index: number) => (
              <Link key={index} to={additionalLink.path}>
                {translate(`forms.${additionalLink.label}.additionalLink`)}
              </Link>
            )
          )}
        </div>
      ) : (
        ''
      )}
      {!alertsTop ? (
        <Alerts
          {...{
            error,
            isLoading,
            success,
            successMessage,
          }}
        />
      ) : null}
      {withRecaptcha ? (
        <div className="d-flex pt-2">
          <Recaptcha
            className="m-auto"
            hl={(selectedLanguage.value as string).split('-')[0]}
            size="invisible"
            ref={setRecaptchaRef}
            verifyCallback={(resp) => {
              setCaptchaOk(true);
              if (ref && ref.current) {
                const button = ref.current.querySelector(
                  'button[type="submit"]'
                ) as unknown as any;
                if (button) {
                  button.click();
                }
              }
            }}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onloadCallback={() => {
              hasCaptcha = true;
            }}
          />
        </div>
      ) : null}
    </form>
  );
};

export const Form: React.FC<FormInterface> = ({ fields, ...rest }) => (
  <FormProvider>
    <BaseForm {...rest}>
      {fields.map((field: FieldInterface, key: number) => (
        <Field {...{ field }} key={key} />
      ))}
    </BaseForm>
  </FormProvider>
);
