import React, { useContext } from 'react';
import { ClassNameInterface } from '../../../interfaces';
import './custombar.scss';
import { LanguageContext } from '../../../contexts';
import { sprintf } from '../../../helpers';
import { OverlayTrigger } from 'react-bootstrap';

interface CustomBarInterface {
  label?: string;
  placement?: 'left' | 'right' | 'top' | 'bottom';
  subtype?: string;
  title?: JSX.Element;
  tooltip?: JSX.Element;
  total?: number;
  trigger?: 'hover' | 'click';
  type?: string;
  value: number;
}

const roundToUpperInt = (value: number): number => {
  const v = value.toFixed(0);
  return parseInt(`${parseInt(v[0]) + 1}${'0'.repeat(v.length - 1)}`);
};

const Bar: React.FC<CustomBarInterface> = ({ subtype, total, type, value }) => (
  <div className={`bg-${subtype} h-100 w-50 mx-auto pointer`}>
    <div className="d-flex h-100">
      <div
        className={`bg-${type} flex-grow-1 mt-auto h-${
          value && Math.floor((value / (total || roundToUpperInt(value))) * 100)
        } transition`}
      />
    </div>
  </div>
);

export const CustomBar: React.FC<ClassNameInterface & CustomBarInterface> = ({
  className,
  label,
  placement = 'left',
  subtype,
  title,
  tooltip,
  total,
  trigger = 'hover',
  type = 'primary',
  value,
}) => {
  if (!subtype) subtype = type + '-light';

  const { translate } = useContext(LanguageContext);
  const date = new Date();
  date.setDate(0);
  return (
    <div
      className={`bg-white d-flex flex-column custom-bar col-6 pt-4 ${
        className || ''
      }`}
    >
      <span className="text-center d-block fs-3">
        {sprintf(translate(`charts.${label}.label`), [''])}
      </span>
      <span
        className={`fs-xs-3 fs-lg-4 m-0 pb-3 text-center d-block text-${type} font-weight-bold primary`}
      >
        {title}
      </span>
      {tooltip ? (
        <OverlayTrigger
          {...{
            delay: { show: 250, hide: 400 },
            overlay: tooltip,
            placement,
            trigger,
          }}
        >
          <Bar {...{ subtype, total, type, value }} />
        </OverlayTrigger>
      ) : (
        <Bar {...{ subtype, total, type, value }} />
      )}
    </div>
  );
};
