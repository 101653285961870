import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import './bar.scss';
import { SzCheckbox, SzModal } from 'react-theme-components';
import { LanguageContext, BaseStoreContext } from '../../../contexts';
import {
  MaterialFamilyFilter,
  MaterialSubFamilyFilter,
  OptionInterface,
  SearchFilter,
} from '../../Filters';

type OptionWithArrayInterface = OptionInterface | OptionInterface[];

export const FilterMaterials: React.FC<{ page: string }> = ({ page }) => {
  const [show, setShow] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const { setSelectedMaterials, analysis, movement } =
    useContext(BaseStoreContext);
  const [family, setFamily] = useState<OptionWithArrayInterface>([]);
  const [subFamily, setSubFamily] = useState<OptionWithArrayInterface>([]);
  const [selection, setSelection] = useState<string[]>([]);

  const selectedMaterials: string[] = useMemo(() => {
    switch (page) {
      case 'analysis':
        return analysis.selectedMaterials;
      case 'movement':
        return movement.selectedMaterials;
      default:
        return analysis.selectedMaterials;
    }
  }, [analysis, movement]);
  const materials = useMemo(() => {
    switch (page) {
      case 'analysis':
        return analysis.materials;
      case 'movement':
        return movement.materials;
      default:
        return analysis.materials;
    }
  }, [analysis, movement]);

  useEffect(() => {
    setSelection(selectedMaterials);
  }, [JSON.stringify(selectedMaterials)]);

  const update = (
    payload: SetStateAction<OptionWithArrayInterface>,
    updater: Dispatch<SetStateAction<OptionWithArrayInterface>>
  ): void =>
    updater(
      !Array.isArray(payload) && (payload as OptionInterface).value
        ? (payload as OptionInterface)
        : []
    );

  const isNoMaterials = () => {
    return (
      materials.filter((m) =>
        `${m.code} - ${m.name}`.toLowerCase().includes(search.toLowerCase())
      ).length === 0
    );
  };
  const updateSelection = (family, subFamily) => {
    const newMaterials = materials.filter(
      (m) =>
        `${m.code} - ${m.name}`.toLowerCase().includes(search.toLowerCase()) &&
        ((family as OptionInterface).value
          ? m.materialFamily?.id === (family as OptionInterface).value
          : true) &&
        ((subFamily as OptionInterface).value
          ? m.materialSubFamily?.id === (subFamily as OptionInterface).value
          : true)
    );

    setSelection(newMaterials.map((m) => m.id));
  };

  const updateFamily = (
    payload: SetStateAction<OptionWithArrayInterface>
  ): void => {
    update(payload, setFamily);
    updateSelection(payload, subFamily);
  };
  const updateSubFamily = (
    payload: SetStateAction<OptionWithArrayInterface>
  ): void => {
    update(payload, setSubFamily);
    updateSelection(family, payload);
  };

  const { translate } = useContext(LanguageContext);

  const subFamilies = materials
    .filter((m) => {
      const f = family as any;
      if (!f || f.length === 0) return true;
      if (f && m.materialFamily && m.materialFamily.id === f.value) {
        return true;
      }

      return false;
    })
    .map((m) => ({
      label: m.materialSubFamily?.name || '',
      value: m.materialSubFamily?.id || '',
    }))
    .filter((m) => m && m.label)
    .filter(
      (m, index, self) => index === self.findIndex((t) => t.value === m.value)
    )
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (!subFamilies.length || !(subFamily as any).value) return;
    if (subFamilies.find((e) => e.value === (subFamily as any).value)) return;
    setSubFamily([]);
  }, [subFamilies]);

  useEffect(() => {
    updateSelection(family, subFamily);
  }, [search]);

  return (
    <>
      <SzModal
        title={`${translate(`pages.analysis.materialfilter.title`)}`}
        show={show}
        handleClose={(): void => {
          setShow(false);
          if (
            !selection.every((m) => selectedMaterials.find((s) => s === m)) ||
            selectedMaterials.length !== selection.length
          ) {
            setSelectedMaterials(selection as any, page);
          }
        }}
        size={'xl'}
      >
        <div className="row">
          <div className="col-12 col-md-6">
            <MaterialFamilyFilter
              className="w-100 pb-3"
              options={[
                {
                  label: 'None',
                  value: undefined,
                },
                ...materials
                  .map((m) => ({
                    label: m.materialFamily?.name || '',
                    value: m.materialFamily?.id || '',
                  }))
                  .filter((m) => m && m.label)
                  .filter(
                    (m, index, self) =>
                      index === self.findIndex((t) => t.value === m.value)
                  )
                  .sort((a, b) => a.label.localeCompare(b.label)),
              ]}
              onChange={(v): void => updateFamily(v as OptionInterface[])}
              value={family as OptionInterface}
            />
            <MaterialSubFamilyFilter
              className="w-100 pb-3"
              options={[
                {
                  label: 'None',
                  value: undefined,
                },
                ...subFamilies,
              ]}
              onChange={(v): void => updateSubFamily(v as OptionInterface[])}
              value={subFamily as OptionInterface}
            />
            <SearchFilter
              className="w-100"
              onChange={setSearch}
              value={search}
            />
          </div>
          <div className="col-12 col-md-6 pt-3 pt-md-0">
            <SzCheckbox
              id="select-all-materials1"
              name="select-all-materials1"
              className="ml-3"
              disabled={isNoMaterials()}
              checked={selection.length > 0}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                selection.length === 0
                  ? updateSelection(family, subFamily)
                  : setSelection([])
              }
              type="checkbox"
              label={
                'Tout ' + (selection.length !== 0 ? 'dé-' : '') + 'sélectionner'
              }
            />

            {materials
              .filter(
                (m) =>
                  `${m.code} - ${m.name}`
                    .toLowerCase()
                    .includes(search.toLowerCase()) &&
                  ((family as OptionInterface).value
                    ? m.materialFamily?.id === (family as OptionInterface).value
                    : true) &&
                  ((subFamily as OptionInterface).value
                    ? m.materialSubFamily?.id ===
                      (subFamily as OptionInterface).value
                    : true)
              )
              .map((material, key) => (
                <SzCheckbox
                  key={key}
                  id={`${material.id}-${key}`}
                  name={`${material.id}-${key}`}
                  className="ml-3"
                  checked={selection.includes(material.id)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    if (e.target.checked) {
                      setSelection((selection) => [
                        ...(new Set([...selection, material.id]) as any),
                      ]);
                    } else {
                      setSelection((selection) =>
                        selection.filter((m) => m !== material.id)
                      );
                    }
                  }}
                  type="checkbox"
                  label={material.code + ' - ' + material.name}
                />
              ))}
          </div>
        </div>
      </SzModal>
      <div className="d-flex align-items-center justify-content-center">
        {materials.length ? (
          <button
            type="button"
            className="font-family-bold text-uppercase position-relative overflow-hidden d-flex align-items-center justify-content-center border-0 py-3 bg-primary text-white btn h-100"
            onClick={(): void => setShow(true)}
          >
            {translate(`pages.analysis.materialfilter.name`)} (
            {selection.length || 0})
          </button>
        ) : null}
      </div>
    </>
  );
};
