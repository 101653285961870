export interface ExportMovementDataInterface {
  detailsData: ExportDataInterface[];
  monthlyData: ExportDataInterface[];
}

export interface ExportDataInterface {
  countryCode: string;
  companyLocalName: string;
  startDatePeriod: string;
  endDatePeriod: string;
  materialCode: string;
  materialName: string;
  materialUnitName: string;
  quantity?: number | null;
  volumeTeqCO2?: number | null;
  quantityPrevYear?: number | null;
  volumeTeqCO2PrevYear?: number | null;
  quantityEvolution?: number | null;
  volumeTeqCO2Evolution?: number | null;
  weightNoteRef?: string | null;
}

export interface ExportSerializationResultInterface {
  headers: ExportHeadersInterface;
  data: ExportSerializedDataInterface[];
  detailsData: ExportSerializedDataInterface[];
}

interface ExportHeadersInterface {
  countryCode: string;
  companyLocalName: string;
  startDatePeriod: string;
  endDatePeriod: string;
  materialCode: string;
  materialName: string;
  materialUnitName: string;
  quantity: string;
  volumeTeqCO2: string;
  quantityPrevYear: string;
  volumeTeqCO2PrevYear: string;
  quantityEvolution: string;
  volumeTeqCO2Evolution: string;
  weightNoteRef: string | null;
}

interface ExportSerializedDataInterface {
  countryCode: string;
  companyLocalName: string;
  startDatePeriod: string;
  endDatePeriod?: string | null;
  materialCode: string;
  materialName: string;
  materialUnitName: string;
  quantity?: number | null;
  volumeTeqCO2?: number | null;
  quantityPrevYear?: number | null;
  volumeTeqCO2PrevYear?: number | null;
  quantityEvolution?: number | null;
  volumeTeqCO2Evolution?: number | null;
  weightNoteRef?: string | null;
}

export interface ExportSerializerConstructorInterface {
  translate: (v: string) => string;
}

export class MovementSerializer {
  translate: (v: string) => string;

  constructor({ translate }: ExportSerializerConstructorInterface) {
    this.translate = (v: string): string => translate(`export.${v}`);
  }

  public serialize(
    data: ExportMovementDataInterface
  ): ExportSerializationResultInterface {
    const monthlyData: ExportDataInterface[] = data.monthlyData;
    const detailsData: ExportDataInterface[] = data.detailsData;
    const monthlyColumns = Object.keys(data.monthlyData[0]) as string[];
    const detailsColumns = Object.keys(data.detailsData[0]) as string[];
    detailsColumns[2] = 'executionDate';
    const columns = [...monthlyColumns, ...detailsColumns].filter(
      (v, i, a) => a.indexOf(v) === i
    );

    const headers = columns.reduce((acc, column) => {
      acc[column] = this.translate('labels.' + column);
      return acc;
    }, {}) as ExportHeadersInterface;

    const serializedDetailsData: ExportSerializedDataInterface[] = [];
    const serializedMonthlyData: ExportSerializedDataInterface[] = [];

    for (let i = 0; i < monthlyData.length; i++) {
      serializedMonthlyData.push(
        columns.reduce((acc, column) => {
          const value = monthlyData[i][column];
          acc[column] = this.mapColumnValue(column, value);
          return acc;
        }, {}) as ExportSerializedDataInterface
      );
    }
    for (let i = 0; i < detailsData.length; i++) {
      serializedDetailsData.push(
        columns.reduce((acc, column) => {
          const value = detailsData[i][column];
          acc[column] = this.mapColumnValue(column, value);
          return acc;
        }, {}) as ExportSerializedDataInterface
      );
    }
    return {
      headers,
      detailsData: serializedDetailsData,
      data: serializedMonthlyData,
    };
  }

  private mapColumnValue(column: string, value: any): any {
    switch (column) {
      case 'startDatePeriod': {
        return new Date(value);
      }
      case 'endDatePeriod': {
        const d = new Date(value);
        d.setHours(d.getHours() - 2);
        return d;
      }
      case 'countryCode':
        return this.translate('countries.' + value);

      case 'materialName':
        return value === 'export.labels.others'
          ? this.translate('labels.others')
          : value;

      case 'quantity':
      case 'quantityPrevYear':
      case 'volumeTeqCO2':
      case 'volumeTeqCO2PrevYear':
        return Math.round((value + Number.EPSILON) * 100) / 100;
      case 'quantityEvolution':
      case 'volumeTeqCO2Evolution':
        return value !== null ? value / 100 : '';

      default:
        return value as string;
    }
  }
}
