import React, {
  createContext,
  useState,
} from 'react';
import { Token } from '../../../helpers';
import { TypeSelectorContextInterface } from './TypeSelectorContext';


export const MovementTypeSelectorContext = createContext<TypeSelectorContextInterface>({
  selected: '',
  setSelected: () => null,
});

export const MovementTypeSelectorProvider: React.FC = ({ children }) => {
  const roles = new Token()
    .getDecoded()
    .roles.filter((r) =>
      [ 'ROLE_MOVEMENT_CUSTOMER','ROLE_MOVEMENT_SUPPLIER'].includes(r)
    );
  const [selected, setSelected] = useState<string>(roles[1] || '');

  return (
    <MovementTypeSelectorContext.Provider value={{ selected, setSelected }}>
      {children}
    </MovementTypeSelectorContext.Provider>
  );
};
