import React, { MutableRefObject, SyntheticEvent, useContext } from 'react';
import { Form, jsonToFormData } from './Form';
import { password, username } from './Field';
import { User } from '../../actions';
import {
  BaseStoreContext,
  SET_ITEM_FILTERS_SELECTED,
  SET_OPEN_FILTERS,
  UserContext,
  LanguageContext,
  INIT_ANALYSIS_MATERIALS,
  INIT_MOVEMENT_MATERIALS,
} from '../../contexts';
import { useHistory } from 'react-router';
import { Language } from '../../helpers';

export const Login: React.FC = () => {
  const { goBack } = useHistory();
  const { dispatch } = useContext(UserContext);
  const { languages, selectedLanguage, setSelectedLanguage } =
    useContext(LanguageContext);
  const baseStore = useContext(BaseStoreContext);

  return (
    <Form
      additionalLinks={[
        {
          label: 'login',
          path: '/reset-password',
        },
      ]}
      fields={[
        {
          ...username(),
          icon: 'people-man-1',
        },
        password(),
      ]}
      submitForm={(
        data: SyntheticEvent<Element, Event>,
        ref: MutableRefObject<{ reset: () => void }>
      ): Promise<void> => {
        baseStore.dispatch({
          payload: {
            organization: [],
            plant: [],
            contract: [],
          },
          type: SET_ITEM_FILTERS_SELECTED,
        });
        baseStore.dispatch({
          payload: [],
          type: INIT_ANALYSIS_MATERIALS,
        });
        baseStore.dispatch({
          payload: [],
          type: INIT_MOVEMENT_MATERIALS,
        });
        baseStore.dispatch({
          type: SET_OPEN_FILTERS,
          payload: false,
        });

        return new User()
          .login({
            data: jsonToFormData(data),
            ref,
            updateClient: dispatch,
          })
          .then(() => {
            const userLanguage = new Language().get();
            const lang = languages.find(({ value }) => value === userLanguage);
            if (selectedLanguage.value !== userLanguage && lang) {
              setSelectedLanguage(lang);
            }
            goBack();
          });
      }}
      withRecaptcha
    />
  );
};
