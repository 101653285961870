import React, { useContext, useEffect } from 'react';
import { Layout } from '../components/Layout';
import { useHistory, useRouteMatch } from 'react-router';
import './catalogItem.scss';
import { SzIcon, SzTypographie } from 'react-theme-components';
import {
  BaseStoreContext,
  LanguageContext,
  SET_SELECTED_ITEM,
} from '../contexts';
import { Material } from '../interfaces/providers';
import { MaterialInstance } from '../interfaces/model';
import { useLogged } from '../hooks';
import { ComponentWithTitle } from '../components/Common';
import { Tile } from '../components/Tile';
import { Loader } from '../components/Loader';

export const CatalogItem: React.FC = () => {
  useLogged();
  const { translate } = useContext(LanguageContext);
  const { goBack } = useHistory();
  const {
    params: { id },
  } = useRouteMatch<Record<string, string>>();
  const { itemSelected, dispatch } = useContext(BaseStoreContext);

  useEffect(() => {
    if (!(itemSelected && id === itemSelected.id)) {
      new Material()
        .getOne({ id })
        .then(({ data }: { data: MaterialInstance }) =>
          dispatch({
            payload: data,
            type: SET_SELECTED_ITEM,
          })
        );
    }
  }, [dispatch, id, itemSelected]);

  return (
    <Layout withFooter>
      <ComponentWithTitle
        icon="book"
        title={`${
          itemSelected
            ? `${itemSelected?.materialFamily?.name} / ${itemSelected?.name}`
            : translate('pages.catalogItem.loader')
        }`}
        withoutTranslation
      >
        <div className="col-12">
          <Tile className="p-5">
            <div className="position-relative">
              <span className="position-absolute top-0 bottom-0">
                <span className="w-100 h-100 sticky-top top-4 z-1">
                  <span
                    className="cursor-pointer my-auto"
                    onClick={(): void => goBack()}
                  >
                    <SzIcon
                      variant="line"
                      icon="sz-icon-line arrow-left-1"
                      className="size-4"
                    />{' '}
                    <span className="my-auto">
                      {translate('pages.catalogItem.back')}
                    </span>
                  </span>
                </span>
              </span>
              <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                {!itemSelected ? (
                  <div className="d-flex">
                    <Loader text="pages.catalogItem" />
                  </div>
                ) : (
                  <>
                    <div className="w-100 d-flex pt-4">
                      <SzTypographie
                        variant="text"
                        className="fs-4 mr-auto mb-0"
                      >
                        {itemSelected.name}
                      </SzTypographie>
                      <SzTypographie variant="text" className="fs-4 mb-0">
                        {itemSelected.code}
                      </SzTypographie>
                    </div>
                    <div className="w-100 d-flex flex-column">
                      <SzTypographie variant="text" className="fs-2 pt-1 mb-0">
                        {(
                          itemSelected?.materialFamily?.name || ''
                        ).toUpperCase()}
                        <span className="px-2">|</span>
                        {(
                          itemSelected?.materialSubFamily?.name || ''
                        ).toUpperCase()}
                      </SzTypographie>
                      <SzTypographie variant="text" className="fs-2 pt-4">
                        {itemSelected.description}
                      </SzTypographie>
                    </div>
                    <div className="pt-4">
                      {itemSelected?.photos?.map((p, k) => (
                        <img
                          key={k.toString()}
                          src={`/materials/${p || 'undefined.jpg'}`}
                          className="w-100 img-fluid"
                          alt={`${itemSelected?.name} - ${itemSelected?.code} n°${k} illustration`}
                        />
                      )) || (
                        <img
                          src="/materials/undefined.jpg"
                          className="w-100 img-fluid"
                          alt={`${itemSelected?.name} - ${itemSelected?.code} illustration`}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Tile>
        </div>
      </ComponentWithTitle>
    </Layout>
  );
};
