import React, { useContext, useEffect } from 'react';
import { Token } from '../../helpers';
import { TypeSelectorContext } from './context/TypeSelectorContext';
import { BaseStoreContext, LanguageContext } from '../../contexts';
import { ClassNameInterface } from '../../interfaces';
import './typeSelector.scss';
import { SzButton } from 'react-theme-components';

export const TypeSelector: React.FC<ClassNameInterface> = ({ className }) => {
  const t = new Token().getDecoded().roles || [];
  if (!(t.includes('ROLE_CUSTOMER') && t.includes('ROLE_SUPPLIER'))) {
    return null;
  }

  const { translate } = useContext(LanguageContext);
  const { selected, setSelected } = useContext(TypeSelectorContext);
  const {
    setSelectedMaterials,
    itemFiltersSelected: { contract },
  } = useContext(BaseStoreContext);

  useEffect(() => {
    if (
      contract &&
      !Array.isArray(contract) &&
      typeof contract['value'] === 'boolean'
    ) {
      if (contract['value'] && selected !== 'ROLE_CUSTOMER') {
        setSelected('ROLE_CUSTOMER');
      } else if (!contract['value'] && selected !== 'ROLE_SUPPLIER') {
        setSelected('ROLE_SUPPLIER');
      }
    }
  }, [contract]);

  const handleTypeSelected = (type) => {
    setSelected(type);
    setSelectedMaterials([], 'analysis');
    setSelectedMaterials([], 'movement');
    setSelectedMaterials([], 'circularEconomy');
  };

  return (
    <div className={className || 'col-12 d-flex pt-4'}>
      <div className="d-flex m-0 ml-auto">
        <div className="d-flex">
          {[
            {
              label: 'customer',
              value: 'ROLE_CUSTOMER',
            },
            {
              label: 'supplier',
              value: 'ROLE_SUPPLIER',
            },
          ].map(({ label, value }, key) => (
            <SzButton
              className="my-auto type-selector"
              key={key}
              onClick={(): void => handleTypeSelected(value)}
              variant={selected === value ? 'primary' : 'secondary'}
            >
              <span>{translate(`charts.typeSelector.${label}`)}</span>
            </SzButton>
          ))}
        </div>
      </div>
    </div>
  );
};
