import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { axiosInterceptorError, Token } from '../helpers';

interface EndpointInterface {
  endpoint?: string;
}

interface ConfigInterface {
  config?: AxiosRequestConfig;
}

interface CustomHeadersInterface {
  Accept: string;
  Authorization?: string;
  'Content-Type': string;
}

const getHeaders = (): Record<string, string> => {
  const h: Record<string, string> = {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  };
  if (new Token().get()) {
    h['Authorization'] = `Bearer ${new Token().get()}`;
  }

  return h;
};

class API {
  endpoint = '';
  filter = {};
  pagination = {};

  getBaseUrl = (): string => '';

  request(): AxiosInstance {
    const instance = axios.create({
      baseURL: this.getBaseUrl(),
      headers: getHeaders(),
    });
    instance.interceptors.response.use((r) => r, axiosInterceptorError);
    return instance;
  }

  async deleteRequest({ endpoint }: EndpointInterface): Promise<AxiosResponse> {
    return this.request().delete(`${this.endpoint}${endpoint || ''}`);
  }

  async getRequest({
    endpoint = '',
  }: EndpointInterface): Promise<AxiosResponse> {
    return this.request().get(
      `${this.endpoint}${endpoint}${
        new URLSearchParams({
          ...this.pagination,
          ...this.filter,
        }).toString() || ''
      }`
    );
  }

  async patchRequest({
    data,
    endpoint = '',
  }: AxiosRequestConfig & EndpointInterface): Promise<AxiosResponse> {
    return this.request().patch(
      `${this.endpoint}${endpoint}`,
      JSON.stringify(data),
      {
        headers: {
          Authorization: `Bearer ${new Token().get()}`,
          'Content-Type': 'application/merge-patch+json',
        },
      }
    );
  }

  async postRequest({
    config = {},
    data,
    endpoint = '',
  }: AxiosRequestConfig &
    EndpointInterface &
    ConfigInterface): Promise<AxiosResponse> {
    return this.request().post(`${this.endpoint}${endpoint}`, data, config);
  }

  public async putRequest({
    data,
    endpoint,
  }: AxiosRequestConfig & EndpointInterface): Promise<AxiosResponse> {
    return this.request().put(
      `${this.endpoint}${endpoint || ''}`,
      JSON.stringify(data)
    );
  }
}

export class APIPlatform extends API {
  endpoint = '';

  getBaseUrl = (): string => process.env.REACT_APP_API_ENTRYPOINT || '';
}
