import React, { useContext } from 'react';
import { ChartContext } from '../../../contexts';
import { formatTitleToK, TopFilters } from './Filters';
import { StackedBars } from '../Abstract/StackedBars';

export const CircularEconomy: React.FC = () => {
  const { total } = useContext(ChartContext);

  return (
    <StackedBars type="circularEconomy">
      <TopFilters
        {...{
          additional: 't',
          formatTitle: (value): JSX.Element => formatTitleToK(value, 't'),
          total,
        }}
      />
    </StackedBars>
  );
};
