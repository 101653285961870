import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MovementCo2Chart } from '../interfaces/providers';
import { Currency, getDate } from '../helpers';
import {
  BaseStoreContext,
  INIT_MOVEMENT_MATERIALS,
  SET_MOVEMENT_MATERIALS,
} from './BaseStoreContext';
import { ChartContext, DataInterface, defaultContext } from './ChartContext';
import { LanguageContext } from './LanguageContext';
import { Co2ChartInstance } from '../interfaces/model';
import { OptionInterface } from '../components/Filters';
import { formatLabelYearMonthUTC } from '../components/Chart/Analysis/Filters';
import { MovementTypeSelectorContext } from '../components/TypeSelector/context/MovementTypeSelectorContext';

interface ChartProviderInterface {
  color?: string;
  label: string;
  startDate: Date;
  endDate: Date;
}

export const MovementCo2ChartProvider: React.FC<ChartProviderInterface> = ({
  children,
  color = '#aadc14',
  label,
  startDate,
  endDate,
}) => {
  const { translate } = useContext(LanguageContext);
  const {
    itemFiltersSelected: { organization, plant, contract },
    movement: { materials, selectedMaterials },
    dispatch,
  } = useContext(BaseStoreContext);
  const endDeliveryDate = getDate(endDate);
  const startDeliveryDate = getDate(startDate);

  const [data, setData] = useState<DataInterface>(defaultContext.data);
  const [total, setTotal] = useState<number>(defaultContext.total);
  const { selected } = useContext(MovementTypeSelectorContext);
  const finalSelectedMaterials = useMemo(
    () =>
      !materials.length || materials.length === selectedMaterials.length
        ? []
        : selectedMaterials.length
        ? selectedMaterials
        : [],
    [materials.length, selectedMaterials]
  );

  const filters = {
    currencyCode: new Currency().getDecoded()?.value,
    endDeliveryDate,
    idCompanies: plant,
    idMaterials: finalSelectedMaterials,
    idOrganizations: organization,
    isService: Array.isArray(contract)
      ? contract[0]
      : (contract as OptionInterface).value,
    role: selected,
    startDeliveryDate,
  };

  const compute = (filters: any, { reset = true }) => {
    new MovementCo2Chart({ filters })
      .getChart()
      .then(({ data, materials: m }) => {
        if (JSON.stringify(finalSelectedMaterials) === JSON.stringify([])) {
          dispatch({
            payload: m,
            type: reset ? INIT_MOVEMENT_MATERIALS : SET_MOVEMENT_MATERIALS,
          });
        }

        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;

        const currentMonth: Co2ChartInstance = {
          label: formatLabelYearMonthUTC(year, month),
          value: 0,
        };

        const labels: string[] = [];
        const d: number[] = [];
        let t = 0;
        data
          .filter(
            (item) => JSON.stringify(item) !== JSON.stringify(currentMonth)
          )
          .forEach((item) => {
            const dateString = item.label.toLocaleDateString(
              navigator.language,
              {
                month: 'short',
                year: 'numeric',
              }
            );
            labels.push(dateString.replace(/^./, dateString[0].toUpperCase()));
            d.push(item.value);
            t += item.value;
          });
        setTotal(t);
        setData({
          labels,
          datasets: [
            {
              label: translate(label),
              data: d,
              type: 'line',
              borderColor: color,
              backgroundColor: 'transparent',
              pointBorderColor: color,
              pointBackgroundColor: color,
              pointHoverBackgroundColor: color,
              pointHoverBorderColor: color,
            },
          ],
        });
      });
  };

  useEffect(() => {
    setData(undefined);
    compute({ ...filters }, { reset: true });
  }, [
    filters.currencyCode,
    filters.endDeliveryDate,
    JSON.stringify(filters.idMaterials),
    JSON.stringify(filters.idCompanies),
    JSON.stringify(filters.idOrganizations),
    filters.isService,
    filters.role,
    filters.startDeliveryDate,
  ]);

  return (
    <ChartContext.Provider
      value={{
        ...{ materials, selectedMaterials },
        data,
        dispatch,
        total,
      }}
    >
      {children}
    </ChartContext.Provider>
  );
};
