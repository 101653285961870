import React, { useContext } from 'react';
import './datepicker.scss';
import { formatTitleToK, TopFilters } from './Filters';
import { Currency } from '../../../helpers';
import { ChartContext } from '../../../contexts';
import { Bars } from '../Abstract/Bars';

export const Revenue: React.FC = () => {
  const currency = new Currency().getDecoded()?.label || '€';
  const { total } = useContext(ChartContext);
  return (
    <Bars type="revenue" unit={currency}>
      <TopFilters
        {...{
          additional: currency,
          formatTitle: (value): JSX.Element => formatTitleToK(value, currency),
          total,
        }}
      />
    </Bars>
  );
};
