import React, { Component } from 'react';

export default function withTracker(WrappedComponent) {
  const HOC = class extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
  HOC.displayName = 'hoc';

  return HOC;
}
