import { AbstractProvider } from './abstract';
import { MaterialFamily as MaterialFamilyModel } from '../model';
import { MANAGEMENT, PROVIDER } from '../../actions/BCM_Constant';

export class MaterialFamily extends AbstractProvider {
  endpoint = PROVIDER.MATERIAL_FAMILIES;
  management = MANAGEMENT.MATERIAL;
  model = new MaterialFamilyModel();
  name = PROVIDER.MATERIALS;
}
