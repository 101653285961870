import React, { useContext, useEffect } from 'react';
import { Dropdown, Navbar } from 'react-bootstrap';
import './filtersBar.scss';
import {
  ContractFilter,
  OptionInterface,
  OrganizationFilter,
  PlantFilter,
  ResetFilter,
} from '../../Filters';
import {
  BaseStoreContext,
  objectToOptionInterface,
  SET_FILTERS,
  SET_ITEM_FILTERS_SELECTED,
} from '../../../contexts';
import { Company } from '../../../interfaces/providers';

export const FiltersBar: React.FC = () => {
  const {
    dispatch,
    itemFiltersSelected: { contract, plant, organization },
    openFilters,
  } = useContext(BaseStoreContext);
  const resetFilters = (): void => {
    dispatch({
      payload: {
        organization: [],
        plant: [],
        contract: [],
      },
      type: SET_ITEM_FILTERS_SELECTED,
    });
  };

  useEffect(() => {
    new Company({
      filters: {
        perPage: 1000,
        idOrganizations: ((organization as OptionInterface[]) || []).map(
          (o) => o.value
        ),
      },
    })
      .getList()
      .then(({ data }) =>
        dispatch({
          payload: {
            plants: objectToOptionInterface(
              data.filter(
                (item) => item.companyType && item.companyType.code !== '01'
              )
            ),
          },
          type: SET_FILTERS,
        })
      );
  }, [dispatch, organization]);

  return (
    <Navbar
      {...{
        className: `border-bottom bg-primary text-white row m-0 py-2 py-md-0 ${
          openFilters ? 'show-filters' : 'hide-filters'
        }`,
        expand: 'md',
        expanded: true,
      }}
    >
      <div className="container" id="filtersBar">
        <Navbar.Collapse>
          <div className="px-0 d-flex col-filter">
            <OrganizationFilter
              {...{
                onChange: (value): void => {
                  dispatch({
                    payload: {
                      organization: value as OptionInterface[],
                    },
                    type: SET_ITEM_FILTERS_SELECTED,
                  });
                },
                value: organization,
              }}
            />
          </div>
          <div className="d-md-none">
            <Dropdown.Divider />
          </div>
          <div className="px-0 d-flex col-filter">
            <PlantFilter
              {...{
                onChange: (value): void =>
                  dispatch({
                    payload: {
                      plant: value as OptionInterface[],
                    },
                    type: SET_ITEM_FILTERS_SELECTED,
                  }),
                value: plant,
              }}
            />
          </div>
          <div className="d-md-none">
            <Dropdown.Divider />
          </div>
          <div className="px-0 col-filter">
            <ContractFilter
              {...{
                onChange: (value): void =>
                  dispatch({
                    payload: {
                      contract: value as OptionInterface[],
                    },
                    type: SET_ITEM_FILTERS_SELECTED,
                  }),
                value: contract,
              }}
            />
          </div>
          <div className="d-md-none">
            <Dropdown.Divider />
          </div>
          <ResetFilter resetFilters={resetFilters} />
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};
