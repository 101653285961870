import React, { SyntheticEvent, useContext } from 'react';
import { Card } from './Card';
import { SzIcon } from 'react-theme-components';
import { ClassNameInterface } from '../../interfaces';
import { LanguageContext } from '../../contexts';

interface EmbedCardInterface {
  image: {
    alt: string;
    src?: string;
  };
  link: string;
  onClick: (event: SyntheticEvent) => void;
  title: string;
}

export const EmbedCard: React.FC<ClassNameInterface & EmbedCardInterface> = ({
  className,
  image,
  link,
  onClick,
  title,
}) => {
  const { translate } = useContext(LanguageContext);
  return (
    <Card className={className}>
      <div className="card-body row m-0">
        <img
          className="rounded-0 h-100 w-100"
          alt={image.alt}
          src={image.src}
        />
        <h4 className="card-title pt-3 w-100">{title}</h4>
        <div className="h-100 mt-auto">
          <a
            onClick={onClick}
            href={link}
            className="card-text text-decoration-none text-primary d-flex fs-inherit"
          >
            <SzIcon
              icon="arrow-rectangle-right"
              className="size-3 my-auto d-flex text-primary text-center"
              variant="bold"
            />
            <span className="pl-1 font-weight-light my-auto">
              {translate('pages.catalog.item.more')}
            </span>
          </a>
        </div>
      </div>
    </Card>
  );
};
