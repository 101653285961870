import React, { Dispatch, SetStateAction, useContext, useReducer } from 'react';
import { SzDatePicker } from 'react-theme-components';
import { Layout } from '../components/Layout';
import { ComponentWithTitle } from '../components/Common';
import { Co2, Volume } from '../components/Chart/Analysis';
import { getTimeOffset } from '../helpers';
import { useLogged } from '../hooks';
import { BaseStoreContext, LanguageContext } from '../contexts';
import { OptionInterface } from '../components/Filters';
import { MovementCo2ChartProvider } from '../contexts/MovementCo2ChartContext';
import {
  MovementVolumeChartProvider,
} from '../contexts/MovementVolumeChartContext';
import { MovementExportAllChart } from '../components/Button';
import { FilterMaterials } from '../components/Chart';
import { MovementTypeSelector } from '../components/TypeSelector/MovementTypeSelector';
import { MovementTypeSelectorProvider } from '../components/TypeSelector/context/MovementTypeSelectorContext';
import { BaseChartsInterface, ChartInterface, ChartsInterface, MultiChartProviderInterface, PeriodeInterface, VolumeChartInterface } from '../interfaces/ChartInterface';

const volumeCharts: (MultiChartProviderInterface & BaseChartsInterface)[] = [
  {
    component: <Volume />,
    title: 'pages.movement.volume',
    icon: 'poll',
    options: {
      Tonnages: {
        label: 'charts.volume.label',
        color: '#030f40',
        sum: true,
        unit: 't',
      },
      Unites: {
        label: 'charts.volume_units.label',
        color: '#77B7FC',
        type: 'line',
      },
    },
  },
];

const co2Charts: ChartsInterface[] = [
  {
    component: <Co2 />,
    label: 'charts.co2.label',
    title: 'pages.movement.co2',
    icon: 'trending_up',
  },
];

const MovementVolumeChart: React.FC<
  VolumeChartInterface & PeriodeInterface
> = ({ chart, startDate, endDate, children }) => (
  <MovementVolumeChartProvider
    {...chart}
    startDate={startDate}
    endDate={endDate}
  >
    <ComponentWithTitle {...chart}>
      <div className="col-12 pt-2 px-0 px-md-3">{children}</div>
    </ComponentWithTitle>
    <div className="dropdown-divider col-12 my-4" />
  </MovementVolumeChartProvider>
);

const MovementCo2Chart: React.FC<ChartInterface & PeriodeInterface> = ({
  chart,
  startDate,
  endDate,
  children,
}) => {
  const { translate } = useContext(LanguageContext);

  return (
    <MovementCo2ChartProvider
      {...chart}
      startDate={startDate}
      endDate={endDate}
    >
      <ComponentWithTitle {...chart}>
        <div className="col-12 pt-2 px-0 px-md-3">
          {children}
          <div className="py-2 container">
            <div className="col-12">
              <span className="text-muted">
                {translate('pages.movement.co2.disclaimer')}
              </span>
            </div>
          </div>
        </div>
      </ComponentWithTitle>
      <div className="dropdown-divider col-12 my-4" />
    </MovementCo2ChartProvider>
  );
};

const SET_END_DATE = 'SET_END_DATE';
const SET_START_DATE = 'SET_START_DATE';

interface ActionInterface {
  payload: SetStateAction<Date>;
  type: string;
}

interface PeriodeStoreInterface extends PeriodeInterface {
  dispatch: Dispatch<ActionInterface>;
}

const getLastYear = (): Date => {
  const startDate = new Date();
  startDate.setFullYear(new Date().getFullYear() - 1);
  return startDate;
};

const defaultStore: PeriodeStoreInterface = {
  dispatch: () => {
    return;
  },
  endDate: new Date(),
  startDate: getLastYear(),
};

const reducer = (
  state: PeriodeStoreInterface = defaultStore,
  { payload, type }: ActionInterface
): PeriodeStoreInterface => {
  switch (type) {
    case SET_END_DATE: {
      return {
        ...state,
        endDate: payload as Date,
      };
    }
    case SET_START_DATE: {
      return {
        ...state,
        startDate: payload as Date,
      };
    }
    default:
      return state;
  }
};

export const Movement: React.FC = () => {
  useLogged();
  const { itemFiltersSelected } = useContext(BaseStoreContext);
  const contract = (
    itemFiltersSelected.contract.length
      ? itemFiltersSelected.contract[0]
      : itemFiltersSelected.contract
  ) as OptionInterface;

  const [state, dispatch] = useReducer(reducer, defaultStore);
  const { startDate, endDate } = state;

  return (
    <Layout withDisclaimer withFooter>
      <MovementTypeSelectorProvider>
        <div className="col-12 d-flex ml-3 header-wrapper">
          <div className="">
            <SzDatePicker
              {...{
                format: 'dd/MM/yyyy',
                dateRange: true,
                endDate,
                endMinDate: startDate,
                onChangeEndDate: (v): void =>
                  dispatch({ type: SET_END_DATE, payload: v as Date }),
                onChangeStartDate: (v): void =>
                  dispatch({ type: SET_START_DATE, payload: v as Date }),
                startDate,
              }}
            />
          </div>
          <FilterMaterials page={'movement'} />
          <MovementExportAllChart startDate={startDate} endDate={endDate} />
          <MovementTypeSelector className="h-100" />
        </div>
        {volumeCharts
          .filter(() => !(contract && contract.value === true))
          .map((chart, key) => (
            <MovementVolumeChart
              key={key}
              chart={chart}
              startDate={startDate}
              endDate={endDate}
            >
              {chart.component}
            </MovementVolumeChart>
          ))}
        {co2Charts
          .filter(() => !(contract && contract.value === true))
          .map((chart, key) => (
            <MovementCo2Chart
              key={key}
              chart={chart}
              startDate={startDate}
              endDate={endDate}
            >
              {chart.component}
            </MovementCo2Chart>
          ))}
      </MovementTypeSelectorProvider>
    </Layout>
  );
};
