import React, { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ApplicationInsights,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

interface AppInsightContextStateInterface {
  initialized: boolean;
  appInsights?: ApplicationInsights;
}

const defaultState: AppInsightContextStateInterface = {
  initialized: false,
  appInsights: undefined,
};

export const AppInsightsContext =
  createContext<AppInsightContextStateInterface>(defaultState);

export const AppInsightProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const instrumentationKey = process.env.REACT_APP_APP_INSIGHTS || '';

  const [appInsights, setAppInsights] = useState<
    ApplicationInsights | undefined
  >(defaultState.appInsights);
  const [initialized, setInitialized] = useState<boolean>(
    defaultState.initialized
  );
  useEffect(() => {
    let ai = appInsights;
    if (!initialized) {
      const reactPlugin = new ReactPlugin();
      ai = new ApplicationInsights({
        config: {
          instrumentationKey: instrumentationKey,
          maxBatchInterval: 0,
          disableFetchTracking: false,
          disableTelemetry: false,
          enableUnhandledPromiseRejectionTracking: true,
          /*eslint-disable-next-line*/
          extensions: [reactPlugin as any],
          extensionConfig: {
            [reactPlugin.identifier]: { history },
          },
        },
      });

      ai.loadAppInsights();
      setInitialized(true);
    }

    if (!ai) {
      setAppInsights(ai);
    } else {
      ai.trackException({
        exception: new Error('some error'),
        severityLevel: SeverityLevel.Error,
      });
    }
  }, [appInsights, history, initialized, instrumentationKey]);
  return (
    <AppInsightsContext.Provider value={{ appInsights, initialized }}>
      {children}
    </AppInsightsContext.Provider>
  );
};
