import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MovementVolumeChart } from '../interfaces/providers';
import { Currency, getDate } from '../helpers';
import {
  BaseStoreContext,
  INIT_MOVEMENT_MATERIALS,
  SET_MOVEMENT_MATERIALS,
} from './BaseStoreContext';
import { ChartContext, DataInterface, defaultContext } from './ChartContext';
import { LanguageContext } from './LanguageContext';
import { OptionInterface } from '../components/Filters';
import { Co2ChartInstance } from '../interfaces';
import { formatLabelYearMonthUTC } from '../components/Chart/Analysis/Filters';
import { MovementTypeSelectorContext } from '../components/TypeSelector/context/MovementTypeSelectorContext';

export interface ChartProviderInterface {
  options: { [key: string]: GraphInterface };
}
interface InternalChartProviderInterface extends ChartProviderInterface {
  startDate: Date;
  endDate: Date;
}
interface GraphInterface {
  color: string;
  label: string;
  sum?: boolean;
  type?: string;
  unit?: string;
}

export const MovementVolumeChartProvider: React.FC<
  InternalChartProviderInterface
> = ({ children, options, startDate, endDate }) => {
  const { translate } = useContext(LanguageContext);

  const {
    itemFiltersSelected: { organization, plant, contract },
    movement: { materials, selectedMaterials },
    dispatch,
  } = useContext(BaseStoreContext);
  const endDeliveryDate = getDate(endDate);
  const startDeliveryDate = getDate(startDate);

  const [data, setData] = useState<DataInterface>(defaultContext.data);
  const [total, setTotal] = useState<number>(defaultContext.total);
  const { selected } = useContext(MovementTypeSelectorContext);
  const finalSelectedMaterials = useMemo(
    () =>
      !materials.length || materials.length === selectedMaterials.length
        ? []
        : selectedMaterials.length
        ? selectedMaterials
        : [],
    [materials.length, selectedMaterials]
  );

  const filters = {
    currencyCode: new Currency().getDecoded()?.value,
    endDeliveryDate,
    idCompanies: plant,
    idMaterials: finalSelectedMaterials,
    idOrganizations: organization,
    isService: Array.isArray(contract)
      ? contract[0]
      : (contract as OptionInterface).value,
    role: selected,
    startDeliveryDate,
  };

  const compute = (filters: any, { reset = true }) => {
    new MovementVolumeChart({ filters })
      .getChart()
      .then(({ data, materials: m }) => {
        if (JSON.stringify(finalSelectedMaterials) === JSON.stringify([])) {
          dispatch({
            payload: m,
            type: reset ? INIT_MOVEMENT_MATERIALS : SET_MOVEMENT_MATERIALS,
          });
        }

        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        const currentMonth: Co2ChartInstance = {
          label: formatLabelYearMonthUTC(year, month),
          value: 0,
        };
        const labels: string[] = [];
        const datasets: any[] = [];
        let t = 0;
        data.forEach((graph) => {
          const graphOptions: GraphInterface = options[graph.type];
          if (!graphOptions) return;

          const d: number[] = [];
          graph.data
            .filter(
              (item) => JSON.stringify(item) !== JSON.stringify(currentMonth)
            )
            .forEach((item) => {
              let dateString = item.label.toLocaleDateString(
                navigator.language,
                {
                  month: 'short',
                  year: 'numeric',
                }
              );
              dateString = dateString.replace(
                /^./,
                dateString[0].toUpperCase()
              );
              if (!labels.includes(dateString)) {
                // Based on the fact that the api "fill" empty dates before returning data
                // So only first graph will add labels
                labels.push(dateString);
              }
              d.push(item.value);
              if (graphOptions.sum) t += item.value;
            });

          if (graphOptions.type === 'line') {
            datasets.push({
              label: translate(graphOptions.label),
              data: d,
              type: 'line',
              unit: graphOptions.unit,
              borderColor: graphOptions.color,
              backgroundColor: 'transparent',
              pointBorderColor: graphOptions.color,
              pointBackgroundColor: graphOptions.color,
              pointHoverBackgroundColor: graphOptions.color,
              pointHoverBorderColor: graphOptions.color,
              yAxisID: 'y-axis-1',
              order: 1,
            });
          } else {
            datasets.push({
              label: translate(graphOptions.label),
              backgroundColor: graphOptions.color,
              data: d,
              unit: graphOptions.unit,
              order: 2,
            });
          }
        });

        //datasets.reverse(); // Pour que la courbe soit devant les barres...

        setTotal(t);
        setData({
          labels,
          datasets,
        });
      });
  };

  useEffect(() => {
    setData(undefined);
    compute({ ...filters }, { reset: true });
  }, [
    filters.currencyCode,
    filters.endDeliveryDate,
    JSON.stringify(filters.idMaterials),
    JSON.stringify(filters.idCompanies),
    JSON.stringify(filters.idOrganizations),
    filters.isService,
    filters.role,
    filters.startDeliveryDate,
  ]);

  return (
    <ChartContext.Provider
      value={{
        ...{ materials, selectedMaterials },
        data,
        dispatch,
        total,
      }}
    >
      {children}
    </ChartContext.Provider>
  );
};
