import React, { useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { LanguageContext } from '../../contexts';

interface LoaderInterface {
  text?: string;
}

export const Loader: React.FC<LoaderInterface> = ({ text }) => {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="d-flex h-100 bg-white mh-50-vh w-100">
      <div className="m-auto">
        <span className="d-flex">
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
          <span className="m-auto pl-1">{translate(`${text}.loader`)}</span>
        </span>
      </div>
    </div>
  );
};
