import React, {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BarDataInterface } from '../components/Chart';
import { Chart, FilterChart } from '../interfaces/providers';
import { Currency, getDate } from '../helpers';
import {
  ActionInterface,
  BaseStoreContext,
  INIT_ANALYSIS_MATERIALS,
  SET_ANALYSIS_MATERIALS,
} from './BaseStoreContext';
import { ChartInstance, MaterialInstance } from '../interfaces/model';
import { OptionInterface } from '../components/Filters';
import { TypeSelectorContext } from '../components/TypeSelector';
import { formatLabelYearMonthUTC } from '../components/Chart/Analysis/Filters';
import { LanguageContext } from './LanguageContext';
import { GraphInterface, InternalChartProviderInterface } from '../interfaces/ChartInterface';

export type DataInterface = BarDataInterface | undefined;

interface ChartInterface {
  dispatch: Dispatch<ActionInterface>;
  materials: MaterialInstance[];
  selectedMaterials: string[];
}

interface ChartContextInterface extends ChartInterface {
  data: any;
  total: number;
}

export const defaultStore: ChartInterface = {
  dispatch: () => {
    return;
  },
  materials: [],
  selectedMaterials: [],
};

export const defaultContext: ChartContextInterface = {
  ...defaultStore,
  data: undefined,
  total: 0,
};

export const ChartContext =
  createContext<ChartContextInterface>(defaultContext);

export const ChartProvider: React.FC<InternalChartProviderInterface> = ({
  children,
  startDate,
  endDate,
  options,
}) => {
  const {
    itemFiltersSelected: { organization, plant, contract },
    analysis: { materials, selectedMaterials },
    dispatch,
  } = useContext(BaseStoreContext);
  const { translate } = useContext(LanguageContext);

  const endDeliveryDate = getDate(endDate);
  const startDeliveryDate = getDate(startDate);

  const [data, setData] = useState<DataInterface>(defaultContext.data);
  const [total, setTotal] = useState<number>(defaultContext.total);
  const { selected } = useContext(TypeSelectorContext);
  const finalSelectedMaterials: string[] = useMemo(
    () =>
      !materials.length || materials.length === selectedMaterials.length
        ? []
        : selectedMaterials.length
        ? selectedMaterials
        : [],
    [materials.length, selectedMaterials]
  );

  const filters = {
    currencyCode: new Currency().getDecoded()?.value,
    endDeliveryDate,
    idCompanies: plant,
    idMaterials: finalSelectedMaterials,
    idOrganizations: organization,
    isService: Array.isArray(contract)
      ? contract[0]
      : (contract as OptionInterface).value,
    role: selected,
    startDeliveryDate,
  };

  const compute = (filters: any, { reset = true }) => {
    new Chart({ filters }).getChart().then(({ data }) => {
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;

      const currentMonth: ChartInstance = {
        label: formatLabelYearMonthUTC(year, month),
        value: 0,
      };

      const labels: string[] = [];
      const datasets: any[] = [];
      let t = 0;
      data.forEach((graph) => {
        const graphOptions: GraphInterface = options[graph.type];
        if (!graphOptions) return;

        const d: number[] = [];
        graph.data
          .filter(
            (item) => JSON.stringify(item) !== JSON.stringify(currentMonth)
          )
          .forEach((item) => {
            let dateString = item.label.toLocaleDateString(navigator.language, {
              month: 'short',
              year: 'numeric',
            });
            dateString = dateString.replace(/^./, dateString[0].toUpperCase());
            if (!labels.includes(dateString)) {
              // Based on the fact that the api "fill" empty dates before returning data
              // So only first graph will add labels
              labels.push(dateString);
            }
            d.push(item.value);
            if (graphOptions.sum) t += item.value;
          });

        if (graphOptions.type === 'line') {
          datasets.push({
            label: translate(graphOptions.label),
            data: d,
            type: 'line',
            unit: graphOptions.unit,
            borderColor: graphOptions.color,
            backgroundColor: 'transparent',
            pointBorderColor: graphOptions.color,
            pointBackgroundColor: graphOptions.color,
            pointHoverBackgroundColor: graphOptions.color,
            pointHoverBorderColor: graphOptions.color,
            yAxisID: 'y-axis-1',
            order: 1,
          });
        } else {
          datasets.push({
            label: translate(graphOptions.label),
            backgroundColor: graphOptions.color,
            data: d,
            unit: graphOptions.unit,
            order: 2,
          });
        }
      });
      setTotal(t);
      setData({
        labels,
        datasets,
      });
    });
  };

  const getFilterMaterials = (filters: any, { reset = true }) => {
    new FilterChart({ filters }).getMaterials().then(({ materials: m }) => {
      dispatch({
        payload: m,
        type: reset ? INIT_ANALYSIS_MATERIALS : SET_ANALYSIS_MATERIALS,
      });
    });
  };

  useEffect(() => {
    setData(undefined);
    compute({ ...filters }, { reset: true });
  }, [
    options,
    filters.currencyCode,
    filters.endDeliveryDate,
    JSON.stringify(filters.idMaterials),
    JSON.stringify(filters.idCompanies),
    JSON.stringify(filters.idOrganizations),
    filters.isService,
    filters.role,
    filters.startDeliveryDate,
  ]);

  useEffect(() => {
    getFilterMaterials({ ...filters }, { reset: true });
  }, [
    filters.endDeliveryDate,
    JSON.stringify(filters.idCompanies),
    JSON.stringify(filters.idOrganizations),
    filters.isService,
    filters.role,
    filters.startDeliveryDate,
  ]);

  return (
    <ChartContext.Provider
      value={{
        ...{ materials, selectedMaterials },
        data,
        dispatch,
        total,
      }}
    >
      {children}
    </ChartContext.Provider>
  );
};
