import React, { Dispatch, SetStateAction, useContext } from 'react';
import { SzIcon, SzInput } from 'react-theme-components';
import './filter.scss';
import { ClassNameInterface } from '../../interfaces';
import { LanguageContext } from '../../contexts';

interface InputFilterInterface {
  onChange: Dispatch<SetStateAction<string>>;
  value: string;
}

export const SearchFilter: React.FC<
  ClassNameInterface & InputFilterInterface
> = ({ className, onChange, value }) => {
  const { translate } = useContext(LanguageContext);
  return (
    <SzInput
      {...{
        groupClassName: className || 'col-3',
        icon: 'search',
        name: 'search',
        onChange: ({
          currentTarget: { value },
        }: React.FormEvent<HTMLInputElement>): void => onChange(value),
        placeholder: translate('filters.search.placeholder'),
        value,
      }}
    />
  );
};
