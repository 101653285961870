export interface KPIInterface {
  serviceRevenue: number;
  valorisationRevenue: number;
  volume: number;
  volumeTeqCO2: number;
}

export class KPIInstance {
  revenue = 0;
  volume = 0;
  volumeTeqCO2 = 0;
  revenueTotal = 0;

  constructor(kpi: KPIInterface) {
    this.revenue = kpi.valorisationRevenue;
    this.volume = kpi.volume || 0;
    this.volumeTeqCO2 = kpi.volumeTeqCO2 || 0;
    this.revenueTotal = kpi.valorisationRevenue + kpi.serviceRevenue;
  }
}

export class KPI {
  getOne = (line: KPIInterface): KPIInstance => new KPIInstance(line);
}
