import React, { useContext } from 'react';
import { ButtonInterface, ClassNameInterface } from '../../interfaces';
import { SzButton, SzTypographie } from 'react-theme-components';
import { LanguageContext } from '../../contexts';

interface ComponentWithTitleInterface {
  button?: JSX.Element;
  customIcon?: string;
  icon?: string;
  logo?: string;
  title: string;
  withoutTranslation?: boolean;
}

export const ComponentWithTitle: React.FC<
  ClassNameInterface & ComponentWithTitleInterface
> = ({
  button,
  customIcon,
  className,
  children,
  icon,
  title,
  logo,
  withoutTranslation,
}) => {
  const { translate } = useContext(LanguageContext);
  return (
    <>
      <div className={`d-flex col-12 py-3 ${className || ''}`}>
        <span className="d-flex col-12  title-underline position-relative">
          {customIcon ? (
            <i className={`my-auto ${customIcon}`} />
          ) : (
            <i className={`my-auto size-5 material-icons`}>{icon}</i>
          )}
          {logo && (
            <a href="/" style={{ display: 'inline-flex' }}>
              <img
                alt="bcm
                icon"
                src={`/images/${logo}`}
                height={50}
                className="m-auto
                bg-white"
              />
            </a>
          )}
          <SzTypographie
            variant="text"
            className="fs-4 mr-auto mb-0 pl-2 text-uppercase"
          >
            {!withoutTranslation ? translate(`${title}.title`) : title}
          </SzTypographie>
          <div className="d-flex justify-content-lg-end">{button}</div>
        </span>
      </div>
      {children}
    </>
  );
};
