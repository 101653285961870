import React, { useContext } from 'react';
import { Layout } from '../../components/Layout';
import { ComponentWithTitle } from '../../components/Common';
import { Tile } from '../../components/Tile';
import {
  Currency,
  Language,
  ResetPasswordForCurrentUserRequestForm,
} from '../../components/Form';
import { Company, Organization, sprintf, Username } from '../../helpers';
import { LanguageContext } from '../../contexts';
import { CompanyInstance, OrganizationInstance } from '../../interfaces/model';
import { ClassNameInterface } from '../../interfaces';
import { useLogged } from '../../hooks';

interface AccountItemsInnerInterface {
  company: CompanyInstance;
  organization: OrganizationInstance;
  translate: (value: string) => string;
}

interface AccountItemsOuterInterface {
  children: JSX.Element;
  title: string;
}

const accountItems = ({
  company,
  organization,
  translate,
}: AccountItemsInnerInterface): AccountItemsOuterInterface[] => [
  {
    children: (
      <>
        <ul className="list-unstyled">
          <li className="d-flex py-2">
            <i className={`my-auto material-icons`}>mail</i>
            <span className="my-auto pl-1">
              {' '}
              {translate('pages.account.email')}: {new Username().get()}
            </span>
          </li>
          {company && (
            <li className="d-flex py-2">
              <i className={`my-auto material-icons`}>business</i>
              <span className="my-auto pl-1">
                {' '}
                {sprintf(translate(`filters.bar.plant`), [company.name])}
              </span>
            </li>
          )}
          {organization && (
            <li className="d-flex py-2">
              <i className={`my-auto material-icons`}>business</i>
              <span className="my-auto pl-1">
                {' '}
                {sprintf(translate(`filters.bar.organization`), [
                  organization.name,
                ])}
              </span>
            </li>
          )}
        </ul>
        <ResetPasswordForCurrentUserRequestForm />
      </>
    ),
    title: 'pages.account.informations',
  },
  {
    children: <Language />,
    title: 'pages.account.language',
  },
  {
    children: <Currency />,
    title: 'pages.account.currency',
  },
];

const AccountItem: React.FC<ClassNameInterface & { title: string }> = ({
  children,
  className,
  title,
}) => {
  const { translate } = useContext(LanguageContext);
  return (
    <Tile className={`p-3 ${className || ''}`}>
      <h3 className="col-12 fs-2 text-uppercase py-2">{translate(title)}</h3>
      {children}
    </Tile>
  );
};

export const Account: React.FC = () => {
  const { translate } = useContext(LanguageContext);
  const company = new Company().getDecoded();
  const organization = new Organization().getDecoded();
  useLogged();
  return (
    <Layout withFooter>
      <ComponentWithTitle
        title="pages.account"
        className="offset-lg-3 col-lg-6 offset-md-1 col-md-10"
        icon="mail"
      >
        <div className="offset-lg-3 col-lg-6 offset-md-1 col-md-10">
          {accountItems({ company, organization, translate }).map(
            (item, index) => (
              <AccountItem
                className={`p-3 ${index > 0 && 'mt-3'}`}
                {...item}
                key={index}
              />
            )
          )}
        </div>
      </ComponentWithTitle>
    </Layout>
  );
};
