import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { MixedChart } from '../interfaces/providers';
import { Currency, getDate, Indexes } from '../helpers';
import { BaseStoreContext } from './BaseStoreContext';
import { MaterialIndexInstance, MaterialInstance } from '../interfaces/model';
import { OptionInterface } from '../components/Filters';
import { TypeSelectorContext } from '../components/TypeSelector';
import { LanguageContext } from './LanguageContext';
import { formatLabelYearMonthUTC } from '../components/Chart/Analysis/Filters';
import { ChartProviderInterface } from '../interfaces/ChartInterface';

export const RESET = 'RESET';
export const SET_SELECTED_INDEX = 'SET_SELECTED_INDEX';
export const SET_SELECTED_MATERIAL = 'SET_SELECTED_MATERIAL';

const COLORS: string[] = [
  '#030f40',
  '#f67536',
  '#ffc229',
  '#ef8143',
  '#eb4a65',
  '#8FD4FF',
  '#0f1319',
];

interface ActionInterface {
  payload:
    | SetStateAction<string>
    | SetStateAction<OptionInterface | null>
    | SetStateAction<string[]>
    | undefined;
  type: string;
}

interface ChartInterface {
  dispatch: Dispatch<ActionInterface>;
  selectedIndex: OptionInterface | null;
  selectedMaterial: OptionInterface | null;
}

interface ChartContextInterface extends ChartInterface {
  data: any;
  indexes: MaterialIndexInstance[];
  materials: MaterialInstance[];
  total: number;
}

interface MixedChartDatasetInterface {
  label: string;
  type: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
  pointBorderColor: string;
  pointBackgroundColor: string;
  pointHoverBackgroundColor: string;
  pointHoverBorderColor: string;
}

export type DataInterface =
  | { labels: string[]; datasets: MixedChartDatasetInterface[] }
  | undefined;

const defaultStore: ChartInterface = {
  dispatch: () => {
    return;
  },
  selectedIndex: null,
  selectedMaterial: null,
};

const defaultContext: ChartContextInterface = {
  ...defaultStore,
  data: undefined,
  indexes: [],
  materials: [],
  total: 0,
};

const reducer = (
  state: ChartInterface = defaultStore,
  { payload, type }: ActionInterface
): ChartInterface => {
  switch (type) {
    case RESET: {
      return {
        ...defaultStore,
      };
    }
    case SET_SELECTED_INDEX: {
      return {
        ...state,
        selectedIndex: payload as OptionInterface,
        selectedMaterial: null,
      };
    }
    case SET_SELECTED_MATERIAL: {
      return {
        ...state,
        selectedIndex: null,
        selectedMaterial: payload as OptionInterface,
      };
    }
    default:
      return state;
  }
};

export const MixedChartContext =
  createContext<ChartContextInterface>(defaultContext);

export const MixedChartProvider: React.FC<ChartProviderInterface> = ({
  children,
  color = '#77B7FC',
  label,
  startDate,
  endDate,
}) => {
  const [state, dispatch] = useReducer(reducer, defaultStore);
  const {
    itemFiltersSelected: { contract },
  } = useContext(BaseStoreContext);
  const endDeliveryDate = getDate(endDate);
  const startDeliveryDate = getDate(startDate);

  const [data, setData] = useState<DataInterface>(defaultContext.data);
  const [materials, setMaterials] = useState<MaterialInstance[]>(
    defaultContext.materials
  );
  const [indexes, setIndexes] = useState<MaterialIndexInstance[]>(
    defaultContext.indexes
  );
  const { selected } = useContext(TypeSelectorContext);
  const { selectedLanguage } = useContext(LanguageContext);

  const [total, setTotal] = useState<number>(defaultContext.total);
  const idIndex = state.selectedIndex?.value;
  const idMaterial = state.selectedMaterial?.value;
  const memoizedState = useMemo(
    () => ({
      idIndex,
      idMaterial,
    }),
    [idIndex, idMaterial]
  );
  const memoizedFilters = useMemo(
    () => ({
      currencyCode: new Currency().getDecoded()?.value,
      endDeliveryDate,
      isService: Array.isArray(contract)
        ? contract[0]
        : (contract as OptionInterface).value,
      role: selected,
      ...memoizedState,
      startDeliveryDate,
    }),
    [contract, endDeliveryDate, memoizedState, selected, startDeliveryDate]
  );

  const hasSelectedIndex: boolean = useMemo(
    (): boolean => !!(memoizedState.idMaterial || memoizedState.idIndex),
    [memoizedState]
  );

  useEffect(() => {
    if (!hasSelectedIndex && indexes.length) {
      dispatch({
        payload: {
          label: indexes[0].name,
          value: indexes[0].id,
        },
        type: SET_SELECTED_INDEX,
      });
    }
  }, [indexes]);

  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;

  const currentMonth = {
    label: formatLabelYearMonthUTC(year, month).toISOString(),
    value: 0,
  };

  useEffect(() => {
    setData(undefined);
    new MixedChart({ filters: memoizedFilters })
      .getChart()
      .then(({ data, materials, indexes }) => {
        setMaterials(materials);
        const userIndexes = new Indexes().getDecoded()
        let formatedIndexes = indexes.sort((a, b) => a.name.localeCompare(b.name))
        if(userIndexes.length)
          formatedIndexes = formatedIndexes.filter(x => userIndexes.includes(x.id) )
        
        setIndexes(formatedIndexes);
        const datas: {
          color: string;
          data: number[];
          material: MaterialInstance;
          name: string;
        }[] = [];
        let labels: string[] = [];
        let t = 0;
        data.forEach((item) =>
          item.materials.forEach((m) =>
            m.indexValues.forEach(
              (i) =>
                !labels.includes(i.label) &&
                JSON.stringify(i) !== JSON.stringify(currentMonth) &&
                labels.push(i.label)
            )
          )
        );
        labels = labels.sort(
          (first, second) =>
            new Date(first).getTime() - new Date(second).getTime()
        );
        let colorIterator = 0;
        data.forEach((item) => {
          item.materials.forEach((m) => {
            datas.push({
              color: COLORS[parseInt(`${colorIterator % COLORS.length}`)],
              data: labels.map((l) => {
                const v = m.indexValues.filter((i) => l === i.label);
                if (v.length) {
                  return v[0].value;
                }
                return 0;
              }),
              material: m.material,
              name: item.name,
            });
            colorIterator++;
            m.indexValues.forEach((i) => (t += i.value || 0));
          });
        });
        setTotal(t);
        setData({
          labels: labels.map((l) => {
            const dateString = new Date(l).toLocaleDateString(
              (selectedLanguage.value as string) ?? navigator.language,
              {
                month: 'short',
                year: 'numeric',
              }
            );
            return dateString.replace(/^./, dateString[0].toUpperCase());
          }),
          datasets: datas.map(({ data, color, material, name }) => ({
            label: `${material.code}`,
            type: 'line',
            data,
            borderColor: color,
            backgroundColor: 'transparent',
            pointBorderColor: color,
            pointBackgroundColor: color,
            pointHoverBackgroundColor: color,
            pointHoverBorderColor: color,
            yAxisID: 'y-axis-1',
          })),
        });
      });
  }, [color, label, memoizedFilters]);

  return (
    <MixedChartContext.Provider
      value={{
        ...state,
        data,
        dispatch,
        indexes,
        materials,
        total,
      }}
    >
      {children}
    </MixedChartContext.Provider>
  );
};
