import React, { createContext, Dispatch, useReducer } from 'react';

export const SET_ERROR = 'SET_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const SET_SUCCESS = 'SET_SUCCESS';
export const SET_VALUE = 'SET_VALUE';

interface FormInterface {
  dispatch: Dispatch<FormActionInterface>;
  error: boolean;
  isLoading: boolean;
  success: boolean;
  values?: any; // eslint-disable-line
}

const defaultValue: FormInterface = {
  dispatch: () => {
    return;
  },
  error: false,
  isLoading: false,
  success: false,
  values: {},
};

interface PayloadInterface {
  name: string;
  value: string;
}

export interface FormActionInterface {
  payload?: PayloadInterface | boolean | string;
  type: string;
}

function reducer(
  state: FormInterface,
  { payload, type }: FormActionInterface
): FormInterface {
  const { values } = state;
  switch (type) {
    case SET_ERROR: {
      return {
        ...state,
        error: true,
        success: false,
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        isLoading: !!payload,
      };
    }
    case SET_SUCCESS: {
      return {
        ...state,
        error: false,
        success: true,
      };
    }
    case SET_VALUE: {
      values[(payload as PayloadInterface).name] = (
        payload as PayloadInterface
      ).value;
      return {
        ...state,
        values,
      };
    }
    default:
      return state;
  }
}

export const FormContext = createContext(defaultValue);

export const FormProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultValue);

  return (
    <FormContext.Provider value={{ ...state, dispatch }}>
      {children}
    </FormContext.Provider>
  );
};
