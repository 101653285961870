/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useContext } from 'react';
import { SzButton, SzIcon } from 'react-theme-components';
import { LanguageContext } from '../../contexts';
import { MixedChartContext } from '../../contexts/MixedChartContext';
import moment from 'moment';

const XlsxTemplate = require('xlsx-template');

interface ExportItem {
  month: string;
  material: string;
  value: number;
}

export const ExportIndexButton: React.FC = () => {
  const { data, selectedIndex } = useContext(MixedChartContext);
  const { translate } = useContext(LanguageContext);

  const handleClick = () => {
    window.dataLayer.push({
      event: 'sz_conversion',
      sz_conversion_type: `Export Index`,
    });

    const serializeData: ExportItem[] = [];

    data?.datasets?.map((dataset) => {
      data?.labels?.map((month, i) => {
        serializeData.push({
          month: month,
          material: dataset.label,
          value: dataset.data[i],
        });
      });
    });
    return fetch('/templates/export_index.xlsx').then((response) => {
      response.arrayBuffer().then((buffer) => {
        const template = new XlsxTemplate(buffer);
        const values = {
          headersMonth: translate('export.index.month'),
          headersMaterials: translate('export.index.material'),
          headersValue: `${translate('export.index.value')} ${
            selectedIndex?.label
          }`,
          data: serializeData,
        };
        template.substitute(1, values);

        const result = template.generate({ type: 'arraybuffer' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([result]));
        const day = moment(new Date()).format('YYYY-MM-DD');
        const filename = `Export_Index_${selectedIndex?.label}_${day}.xlsx`;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      });
    });
  };

  return (
    <div>
      <SzButton
        className="my-auto h-100"
        variant="secondary"
        isDisabled={selectedIndex == null}
        onClick={handleClick}
      >
        <span className="d-inline-flex align-items-center m-auto px-0 py-1 px-md-2">
          <SzIcon variant="line" icon="common-file-text-download" />
          <span className="pl-2">{translate('charts.export')}</span>
        </span>
      </SzButton>
    </div>
  );
};
