import React, { useContext, useMemo, useState, useEffect } from 'react';
import { NavDropdown } from './Items/NavDropdown';
import { DropdownItem } from '../../Dropdown/DropdownItem';
import { NavItem } from './Items/NavItem';
import { Navbar } from 'react-bootstrap';
import { LinkInterface } from '../../../interfaces';
import { Link } from 'react-router-dom';
import {
  BaseStoreContext,
  LanguageContext,
  SET_ITEM_FILTERS_SELECTED,
  SET_OPEN_FILTERS,
  UserContext,
} from '../../../contexts';
import { SzIcon } from 'react-theme-components';
import { Dropdown } from 'react-bootstrap';
import { User } from '../../../actions';
import { Token } from '../../../helpers';

const AppBarDropdown: React.FC<{ withoutCaret?: boolean }> = ({
  withoutCaret = false,
}) => {
  const { translate } = useContext(LanguageContext);
  const [count, setCount] = useState<number>(0);
  const {
    itemFiltersSelected: { plant, organization },
    openFilters,
  } = useContext(BaseStoreContext);
  useMemo(() => {
    setCount((plant?.length || 0) + (organization?.length || 0));
  }, [plant, organization]);
  return (
    <>
      <span className="fs-3 my-auto d-flex">
        <i className={`material-icons text-white`}>filter_list</i>
        <span className="fs-2 pl-1 my-auto text-white">
          {translate('appbar.filters.label')} {count > 0 && `(${count})`}
        </span>
      </span>
      {!withoutCaret && (
        <span className="my-auto pl-3">
          <SzIcon
            variant="line"
            icon={`arrow-${openFilters ? 'up' : 'down'}-1`}
            className="size-4"
          />
        </span>
      )}
    </>
  );
};
const AvatarDropdown: React.FC = () => (
  <>
    <span className="my-auto">
      <img src="/images/avatar.svg" alt="User avatar" />
    </span>
    <span className="my-auto pl-3">
      <SzIcon variant="line" icon={`arrow-down-1`} className="size-4" />
    </span>
  </>
);
let routes: (LinkInterface & { children: string; roles?: string[] })[] = [];

const navitems: (LinkInterface & { children: string; roles?: string[] })[] = [
  {
    children: 'overview',
    to: '/',
    roles: ['ROLE_SUPPLIER', 'ROLE_CUSTOMER'],
  },
  {
    children: 'analysis',
    to: '/analysis',
    roles: ['ROLE_SUPPLIER', 'ROLE_CUSTOMER'],
  },
  {
    children: 'catalog',
    to: '/catalogs',
    roles: ['ROLE_SUPPLIER', 'ROLE_CUSTOMER'],
  },
  {
    children: 'demandsExternal',
    to: '/demandsExternal',
    roles: ['ROLE_EXTERNAL_OPERATOR'],
  },
  {
    children: 'demandsInternal',
    to: '/demandsInternal',
    roles: ['ROLE_INTERNAL_OPERATOR'],
  },
  {
    children: 'movement',
    to: '/movement',
    roles: ['ROLE_MOVEMENT_CUSTOMER', 'ROLE_MOVEMENT_SUPPLIER'],
  },
  {
    children: 'documents',
    to: '/documents',
    roles: ['ROLE_DOCUMENTS_ACCESS'],
  },
];

export const AppBar: React.FC = () => {
  const { translate } = useContext(LanguageContext);
  const [expanded, setExpanded] = useState(false);
  const { dispatch, isLogged } = useContext(UserContext);
  const baseStore = useContext(BaseStoreContext);
  const expand = 'xl';
  useEffect(() => {
    const roles = new Token().getDecoded().roles;
    const isExternalOperator = roles.some(
      (r) => 'ROLE_EXTERNAL_OPERATOR' === r
    );
    const isInternalOperator = roles.some(
      (r) => 'ROLE_INTERNAL_OPERATOR' === r
    );
    const isUserMovement = roles.some((r) =>
      ['ROLE_MOVEMENT_SUPPLIER', 'ROLE_MOVEMENT_CUSTOMER'].includes(r)
    );
    routes = navitems.filter((n) => n.roles?.some((r) => roles.includes(r)));
    if (isUserMovement && !navitems.some((n) => 'movement' === n.children)) {
      navitems.splice(2, 0, {
        children: 'movement',
        to: '/movement',
      });
    }
    if (
      isExternalOperator &&
      !navitems.some((n) => 'demandsExternal' === n.children)
    ) {
      navitems.push({
        children: 'demandsExternal',
        to: '/demandsExternal',
      });

      const index = navitems.findIndex((n) => 'demandsInternal' === n.children); // find index demandsInternal
      if (index !== -1) navitems.splice(index, 1); // remove element demandsInternal from navitems
    } else if (
      isInternalOperator &&
      !navitems.some((n) => 'demandsInternal' === n.children)
    ) {
      navitems.push({
        children: 'demandsInternal',
        to: '/demandsInternal',
      });

      const index = navitems.findIndex((n) => 'demandsExternal' === n.children); //find index demandsExternal
      if (index !== -1) navitems.splice(index, 1); // remove element demandsExternal from navitems
    }
  }, []);

  return (
    <Navbar
      {...{
        className: `border-bottom bg-white primary p-${expand}-0`,
        expand,
        expanded,
      }}
    >
      <div className="container">
        <div className="navbar-brand p-0">
          <Link to="/">
            <img src="/images/icon.svg" height="50" alt="BCM brand" />
          </Link>
        </div>
        <div className="ml-auto pr-2 pr-sm-3">
          <button
            className="navbar-toggler bg-primary"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={(): void => {
              if (!baseStore.openFilters) {
                setExpanded(false);
              }
              baseStore.dispatch({
                type: SET_OPEN_FILTERS,
                payload: !baseStore.openFilters,
              });
            }}
          >
            <AppBarDropdown withoutCaret />
          </button>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={(): void => {
            if (!expanded) {
              baseStore.dispatch({
                type: SET_OPEN_FILTERS,
                payload: expanded,
              });
            }
            setExpanded(!expanded);
          }}
        >
          <span
            className={`navbar-toggler-icon transition rotate-${
              expanded ? '2' : '0'
            }`}
          />
        </button>
        <Navbar.Collapse>
          <ul className={`navbar-nav d-flex w-100 mt-2 mt-${expand}-0`}>
            <NavDropdown
              {...{
                className: `bg-primary d-none d-xl-block size-3 px-${expand}-3 pointer`,
                disabled: true,
                dropdownToggleClass: `text-white px-0 h-100`,
                name: 'dropdown-navbar',
                onClick: (): void =>
                  baseStore.dispatch({
                    type: SET_OPEN_FILTERS,
                    payload: !baseStore.openFilters,
                  }),
                title: <AppBarDropdown />,
              }}
            />
            {routes.map((navitem: LinkInterface, index: number) => (
              <NavItem key={index} {...navitem} />
            ))}
            {
              <>
                <div className="d-flex flex-column d-xl-none">
                  <Dropdown.Divider />
                  <NavItem to="/account">account</NavItem>
                  <Link
                    className="text-center nav-item my-auto mx-auto mx-xl-0 pl-lg-3 text-uppercase size-3 nav-link nav-item-route active"
                    to={''}
                    onClick={(): void => {
                      baseStore.dispatch({
                        type: SET_ITEM_FILTERS_SELECTED,
                        payload: {
                          organization: [],
                          plant: [],
                          contract: [],
                        },
                      });
                      baseStore.dispatch({
                        type: SET_OPEN_FILTERS,
                        payload: false,
                      });
                      User.logout({
                        updateClient: dispatch,
                      });
                    }}
                  >
                    {translate('appbar.routes.logout')}
                  </Link>
                </div>
                <NavDropdown
                  {...{
                    alignRight: true,
                    className: `align-self-stretch ml-xl-auto pointer d-none d-xl-block`,
                    dropdownToggleClass: `text-primary text-decoration-none px-3 h-100 align-items-center justify-content-between d-flex`,
                    name: 'dropdown-navbar',
                    title: isLogged ? <AvatarDropdown /> : <></>,
                    withoutChildren: !isLogged,
                  }}
                >
                  {isLogged ? (
                    <>
                      <DropdownItem to={'/account'}>
                        <span className="d-block">
                          {translate('appbar.routes.account')}
                        </span>
                      </DropdownItem>
                      <DropdownItem to={'/contact'}>
                        <span className="d-block">
                          {translate('appbar.routes.contact')}
                        </span>
                      </DropdownItem>
                      <Link
                        className="dropdown-item"
                        to={''}
                        onClick={(): void =>
                          User.logout({
                            updateClient: dispatch,
                          })
                        }
                      >
                        {translate('appbar.routes.logout')}
                      </Link>
                    </>
                  ) : null}
                </NavDropdown>
              </>
            }
          </ul>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export * from './FiltersBar';
