import { AbstractProvider } from './abstract';
import { Organization as OrganizationModel } from '../model';
import { MANAGEMENT, PROVIDER } from '../../actions/BCM_Constant';
import { Token } from '../../helpers';

export class Organization extends AbstractProvider {
  management = MANAGEMENT.ORGANIZATION;
  model = new OrganizationModel();
  name = PROVIDER.ORGANIZATIONS;
  token = new Token().get() || '';
}
