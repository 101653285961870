import { APIPlatform } from './API';
import { OptionInterface } from '../components/Filters';

export class Currency extends APIPlatform {
  public endpoint = '/currencies';

  public get(): Promise<OptionInterface[]> {
    return this.getRequest({}).then(({ data }) => {
      return data['hydra:member'];
    });
  }
}
