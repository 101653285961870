import { RouteProps } from 'react-router';
import {
  Consent,
  Login,
  ResetPasswordApply,
  ResetPasswordRequest,
} from '../pages';

export const passwordRoutes: RouteProps[] = [
  {
    component: ResetPasswordRequest,
    path: '/reset-password',
    strict: true,
    exact: true,
  },
  {
    component: ResetPasswordApply,
    path: '/reset-password/:token',
  },
  {
    component: Login,
    path: '/login',
  },
  {
    component: Consent,
    path: '/consent',
  },
];
