import { ModelActions, ModelInstance, ModelInterface } from './Common';
import { CompanyInstance, CompanyInterface } from './Company';

export interface ContainerInterface extends ModelInterface {
  idContainer: string;
  containerName: string;
}

export class ContainerInstance extends ModelInstance {
  constructor(container: ContainerInterface) {
    super(container);
    this.id = container.idContainer;
    this.name = container.containerName;
  }
}

export class Container extends ModelActions {
  getMany = (containers: ContainerInterface[]): ContainerInstance[] =>
    containers.map((container) => new ContainerInstance(container));
  getOne = (container: ContainerInterface): ContainerInstance =>
    new ContainerInstance(container);
}
