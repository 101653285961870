export interface ModelInterface {
  '@id': string;
  code?: string;
  name?: string;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedBy?: string | null;
  updatedAt?: string | null;
  deletedBy?: string | null;
  deletedAt?: string | null;
}

export class ModelInstance {
  '@id' = '';
  id = '';
  code = '';
  name = '';
  createdBy = '';
  createdAt = '';
  updatedBy = '';
  updatedAt = '';
  deletedBy = '';
  deletedAt = '';

  constructor(object: any /*eslint-disable-line*/) {
    this['@id'] = object['@id'];
    this.createdBy = object.createdBy;
    this.createdAt = object.createdAt;
    this.updatedBy = object.updatedBy;
    this.updatedAt = object.updatedAt;
    this.deletedBy = object.deletedBy;
    this.deletedAt = object.deletedAt;
  }
}

export class ModelActions {
  getMany = (objects: any[]): any[] => objects; //eslint-disable-line
  getOne = (object: any): any => object; //eslint-disable-line
}
