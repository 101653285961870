import { ModelActions, ModelInstance } from './Common';

export interface MaterialIndexInterface {
  idMaterialIndexSource: string;
  materialIndexSourceCode: string;
  materialIndexSourceName: string;
  currencyCode: string;
}

export class MaterialIndexInstance extends ModelInstance {
  code = '';
  currencyCode = '';

  constructor(materialIndex: MaterialIndexInterface) {
    super(materialIndex);
    this.code = materialIndex.materialIndexSourceCode;
    this.currencyCode = materialIndex.currencyCode;
    this.id = materialIndex.idMaterialIndexSource;
    this.name = materialIndex.materialIndexSourceName;
  }
}

export class MaterialIndex extends ModelActions {
  getMany = (
    materialIndexes: MaterialIndexInterface[]
  ): MaterialIndexInstance[] =>
    materialIndexes.map(
      (materialIndex) => new MaterialIndexInstance(materialIndex)
    );
  getOne = (materialIndex: MaterialIndexInterface): MaterialIndexInstance =>
    new MaterialIndexInstance(materialIndex);
}
