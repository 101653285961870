import en from './en.json';
import fr from './fr.json';

interface DescriptionInterface {
  description: string;
}

interface LabelInterface {
  label: string;
}

interface NavFileInterface extends LabelInterface {
  filename: string;
}

interface PlaceholderInterface {
  placeholder: string;
}

interface FieldInterface extends LabelInterface, PlaceholderInterface {}

interface TextInterface {
  text: string;
}

interface TitleInterface {
  title: string;
}

interface FiltersInterface {
  filters: string;
}

export interface TranslationInterface {
  [key: string]: {
    appbar: {
      routes: {
        account: string;
        analysis: string;
        catalog: string;
        contact: string;
        login: string;
        logout: string;
        overview: string;
      };
    };
    charts: {
      export: string;
      kpi: {
        loader: string;
      };
      revenue: LabelInterface & {
        loader: string;
      };
      volume: LabelInterface & {
        loader: string;
      };
    };
    export: {
      common: {
        filters: string;
        informations: string;
      };
      index: {
        material: string;
        source: string;
        values: string;
      };
      revenueVolume: {
        materials: string;
        values: string;
      };
    };
    filters: {
      bar: {
        all: string;
        contract: string;
        numberSelected: string;
        organization: string;
        plant: string;
      };
      contract: PlaceholderInterface;
      materialFamily: PlaceholderInterface;
      materialReferential: PlaceholderInterface;
      organization: PlaceholderInterface;
      plant: PlaceholderInterface;
      reset: LabelInterface;
      search: PlaceholderInterface;
      year: PlaceholderInterface;
    };
    forms: {
      contact: {
        successMessage: string;
      };
      errorMessage: string;
      fields: {
        confirmPassword: FieldInterface;
        email: FieldInterface;
        firstname: PlaceholderInterface;
        lastname: PlaceholderInterface;
        messageBody: FieldInterface;
        messageHeader: FieldInterface;
        newPassword: FieldInterface;
        password: FieldInterface;
      };
      login: {
        additionalLink: string;
      };
      resetPasswordApply: {
        successMessage: string;
      };
      resetPasswordRequest: {
        successMessage: string;
      };
      submit: {
        classic: string;
      };
    };
    layout: {
      disclaimer: string;
      footer: {
        nav: {
          'general-usage-terms': NavFileInterface;
          'cookies-policy': NavFileInterface;
          'legal-terms': NavFileInterface;
          'privacy-policy': NavFileInterface;
        };
      };
    };
    pages: {
      account: TitleInterface & {
        currency: string;
        email: string;
        informations: string;
        language: string;
      };
      analysis: {
        indexes: TitleInterface;
        revenue: TitleInterface;
        volume: TitleInterface;
      };
      catalog: TitleInterface & {
        item: {
          more: string;
        };
      };
      catalogItem: {
        back: string;
      };
      contact: DescriptionInterface & TitleInterface;
      login: {
        form: TitleInterface;
        block: DescriptionInterface & TitleInterface;
      };
      resetPasswordApply: DescriptionInterface & TitleInterface;
      resetPasswordRequest: DescriptionInterface & TitleInterface;
      welcome: {
        card: {
          discover: TextInterface & TitleInterface;
          manage: TextInterface & TitleInterface;
          need: TextInterface & TitleInterface;
        };
        overview: DescriptionInterface & TitleInterface;
      };
    };
  };
}

export const translations: TranslationInterface = {
  'en-US': en,
  'fr-FR': fr,
};
