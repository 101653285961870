import { AbstractProvider } from './abstract';
import { MaterialSubFamily as MaterialSubFamilyModel } from '../model';
import { MANAGEMENT, PROVIDER } from '../../actions/BCM_Constant';

export class MaterialSubFamily extends AbstractProvider {
  endpoint = PROVIDER.MATERIAL_SUB_FAMILIES;
  management = MANAGEMENT.MATERIAL;
  model = new MaterialSubFamilyModel();
  name = PROVIDER.MATERIALS;
}
