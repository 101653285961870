import { ClassNameInterface } from '../../interfaces';

export interface FieldInterface extends ClassNameInterface {
  icon?: string;
  label?: string;
  name: string;
  placeholder?: string;
  type?: 'textarea' | 'number' | 'text' | 'email' | 'password';
}

export const email = (className?: string): FieldInterface => ({
  className,
  name: 'email',
  label: 'email',
  placeholder: 'email',
  type: 'email',
});

export const firstname = (className?: string): FieldInterface => ({
  className,
  name: 'firstname',
  placeholder: 'firstname',
});

export const lastname = (className?: string): FieldInterface => ({
  className,
  name: 'lastname',
  placeholder: 'lastname',
});

export const password = (className?: string): FieldInterface => ({
  className,
  name: 'password',
  label: 'password',
  placeholder: 'password',
  type: 'password',
  icon: 'login-keys',
});

export const newPassword = (className?: string): FieldInterface => ({
  ...password(className),
  name: 'password',
  label: 'newPassword',
  placeholder: 'newPassword',
});

export const confirmPassword = (className?: string): FieldInterface => ({
  ...password(className),
  name: 'confirmPassword',
  label: 'confirmPassword',
  placeholder: 'confirmPassword',
});

export const username = (className?: string): FieldInterface => ({
  className,
  name: 'username',
  label: 'email',
  placeholder: 'email',
  type: 'email',
});

export const messageHeader = (className?: string): FieldInterface => ({
  className,
  name: 'messageHeader',
  label: 'messageHeader',
  placeholder: 'messageHeader',
});

export const messageBody = (className?: string): FieldInterface => ({
  className,
  name: 'messageBody',
  label: 'messageBody',
  placeholder: 'messageBody',
  type: 'textarea',
});
