import React from 'react';
import { Form } from './Form';
import { messageBody, messageHeader } from './Field';
import { Contact as ContactAction } from '../../actions';
import { Username } from '../../helpers';

export const Contact: React.FC = () => {
  return (
    <Form
      fields={[messageHeader(), messageBody()]}
      submitForm={(data): Promise<void> => {
        return new ContactAction().send({
          data: { ...data, email: new Username().get() },
        });
      }}
      successMessage="contact"
    />
  );
};
