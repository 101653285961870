import React, { useContext } from 'react';
import { SzTypographie } from 'react-theme-components';
import { Login as LoginForm } from '../components/Form';
import './login.scss';
import { useNotLogged } from '../hooks';
import { LanguageContext } from '../contexts';
import { LayoutBase } from '../components/Layout';

export const Login: React.FC = () => {
  useNotLogged();
  const { translate } = useContext(LanguageContext);
  return (
    <LayoutBase withFooter>
      <div
        className="h-100 bg-suez-login d-flex row mx-0"
        style={{
          background:
            'linear-gradient(rgba(8, 8, 81, 0.74), rgba(8, 8, 81, 0.74)), url(/resources/bg_login.jpg)',
        }}
      >
        <div className="col-md-6 order-2 order-md-1 m-auto pl-md-5">
          <div className="container py-5">
            <div className="p-4 card rounded-0">
              <SzTypographie color="disabled">
                {translate('pages.login.form.title')}
              </SzTypographie>
              <LoginForm />
            </div>
          </div>
        </div>
        <div className="col-md-6 order-1 order-md-2 m-auto pr-md-5">
          <div className="container pr-5 pt-5">
            <h1 className="text-white">
              {translate('pages.login.block.title')}
            </h1>
            <br />
            <p className="text-white letter-spacing">
              {translate('pages.login.block.description')}
            </p>
          </div>
        </div>
      </div>
    </LayoutBase>
  );
};
