import React, { useEffect, useState } from 'react';
import {
  Currency as CurrencyProvider,
  Preference as PreferenceProvider,
} from '../../actions';
import { OptionInterface, SelectFilter } from '../Filters';
import { Currency as CurrencyHelper, Preference } from '../../helpers';
import { CurrencyInterface, PreferenceInterface } from '../../interfaces';

export const Currency: React.FC = () => {
  const currencyHelper = new CurrencyHelper();
  const [currencies, setCurrencies] =
    useState<(OptionInterface & CurrencyInterface)[]>();
  const [currency, setCurrency] = useState<OptionInterface | null>(
    currencyHelper.getDecoded()
      ? currencyHelper.getDecoded()
      : { label: 'EUR €', value: 'eur' }
  );
  const updateCurrency = (currency: OptionInterface): void => {
    const label = currency.label.split(' ');
    currency = {
      ...currency,
      label: label.length > 1 ? label[1] : label[0],
    };
    setCurrency(currency);
    currencyHelper.setDecoded(currency as CurrencyInterface);
    new PreferenceProvider().update({
      data: {
        currency: (currency as CurrencyInterface)['@id'],
      },
      id: (new Preference().getDecoded() as PreferenceInterface)['@id'],
    });
  };
  useEffect(() => {
    new CurrencyProvider().get().then((values) =>
      setCurrencies(
        values.map(
          (value) =>
            ({
              ...(value as CurrencyInterface),
              label: `${(value.value as string).toUpperCase()} ${value.label}`,
              value: value.value,
            } as OptionInterface & CurrencyInterface)
        )
      )
    );
  }, []);

  return (
    <SelectFilter
      {...{
        onChange: updateCurrency,
        options: currencies,
        value: {
          ...currency,
          label: currency
            ? `${(currency.value as string).toUpperCase()} ${currency.label}`
            : '',
        },
      }}
    />
  );
};
