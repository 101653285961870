import { ModelActions, ModelInstance, ModelInterface } from './Common';

export interface InvoiceTypeInterface extends ModelInterface {
  idInvoiceType: string;
  invoiceTypeCode: string;
  invoiceTypeName: string;
}

export class InvoiceTypeInstance extends ModelInstance {
  constructor(invoiceType: InvoiceTypeInterface) {
    super(invoiceType);
    this.id = invoiceType.idInvoiceType;
    this.code = invoiceType.invoiceTypeCode;
    this.name = invoiceType.invoiceTypeName;
  }
}

export class InvoiceType extends ModelActions {
  getMany = (invoiceType: InvoiceTypeInterface[]): InvoiceTypeInstance[] =>
    invoiceType.map((type) => new InvoiceTypeInstance(type));
  getOne = (type: InvoiceTypeInterface): InvoiceTypeInstance =>
    new InvoiceTypeInstance(type);
}
