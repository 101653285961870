import {
  MaterialIndex,
  MaterialIndexInstance,
  MaterialIndexInterface,
} from './MaterialIndex';
import { Material, MaterialInstance, MaterialInterface } from './Material';
import { formatLabelYearMonthUTC } from '../../components/Chart/Analysis/Filters';

export interface ChartInterface {
  year: number;
  month: number;
  value: number;
  materialUnitValue: number;
  mvtTonnageDistributed?: number;
}

export interface VolumeGraphInterface {
  [key: string]: ChartInterface[];
}

export class ChartInstance {
  label = new Date();
  value = 0;

  constructor(chart: ChartInterface) {
    this.label = formatLabelYearMonthUTC(chart.year, chart.month);
    this.value = chart.value || chart.mvtTonnageDistributed || 0;
  }
}

export class Chart {
  getMany = (data: VolumeGraphInterface): MultiGraphInstance[] => {
    const results: MultiGraphInstance[] = [];
    for (const key in data) {
      results.push(
        new MultiGraphInstance(
          key,
          data[key].map((line) => new ChartInstance(line))
        )
      );
    }
    return results;
  };
}

export class MultiGraphInstance {
  type = '';
  data: ChartInstance[] = [];

  constructor(type: string, data: ChartInstance[]) {
    this.type = type;
    this.data = data;
  }
}

export class VolumeGraph {
  getMany = (data: VolumeGraphInterface): MultiGraphInstance[] => {
    const results: MultiGraphInstance[] = [];
    for (const key in data) {
      results.push(
        new MultiGraphInstance(
          key,
          data[key].map((line) => new ChartInstance(line))
        )
      );
    }
    return results;
  };
}
export interface CircularChartInterface {
  year: number;
  month: number;
  mvtTonnageDistributed: number;
  domainName?: string | null;
}

export class CircularChartInstance {
  type = '';
  data: ChartInstance[] = [];

  constructor(type: string, data: ChartInstance[]) {
    this.type = type;
    this.data = data;
  }
}

export class CircularGraph {
  getMany = (lines: CircularChartInterface[]): CircularChartInstance[] => {
    //get Labels + remove duplicates
    const labels = lines
      .map((item) => `${item.year}-${item.month}`)
      .filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
    const data = lines
      .map((item) => {
        item.domainName = item.domainName ?? 'NA';
        return item;
      })
      .reduce((r, a) => {
        if (a.domainName != null) {
          const initRow = {
            year: a.year,
            month: a.month,
            domainName: a.domainName,
            mvtTonnageDistributed: 0,
          };
          r[a.domainName] =
            r[a.domainName] ||
            new Array(labels.length).fill(initRow, 0, labels.length);
          r[a.domainName][labels.indexOf(`${a.year}-${a.month}`)] = a;
        }
        return r;
      }, Object.create(null));
    const results: MultiGraphInstance[] = [];
    for (const key in data) {
      results.push(
        new MultiGraphInstance(
          key,
          data[key].map((line) => new ChartInstance(line))
        )
      );
    }

    return results;
  };
}

export interface Co2ChartInterface {
  year: number;
  month: number;
  volumeTeqCO2: number;
}

export class Co2ChartInstance {
  label = new Date();
  value = 0;

  constructor(chart: Co2ChartInterface) {
    this.label = formatLabelYearMonthUTC(chart.year, chart.month);
    this.value = chart.volumeTeqCO2;
  }
}

export class Co2Chart {
  getMany = (lines: Co2ChartInterface[]): Co2ChartInstance[] =>
    lines.map((line) => new Co2ChartInstance(line));
}

interface MixedDataChartInterface extends MaterialIndexInterface {
  materials: {
    material: MaterialInterface;
    materialIndexValues: ChartInterface[];
  }[];
}

export interface MixedChartInterface {
  data: MixedDataChartInterface[];
  indexes: MaterialIndexInterface[];
  materials: MaterialInterface[];
}

export class MixedChartIndexValueInstance {
  label: string = new Date().toISOString();
  value = 0;

  constructor(chart: ChartInterface) {
    this.label = formatLabelYearMonthUTC(chart.year, chart.month).toISOString();
    this.value = chart.materialUnitValue;
  }
}

export type DataMixedChartType = {
  materials: {
    indexValues: MixedChartIndexValueInstance[];
    material: MaterialInstance;
  }[];
} & MaterialIndexInstance;

export class MixedChartInstance {
  data: DataMixedChartType[] = [];
  materials: MaterialInstance[] = [];
  indexes: MaterialIndexInstance[] = [];
  constructor({ data, indexes, materials }: MixedChartInterface) {
    this.data = data.map((item) => ({
      ...new MaterialIndexInstance(item),
      materials: item.materials.map((m) => ({
        indexValues: m.materialIndexValues.map(
          (i) => new MixedChartIndexValueInstance(i)
        ),
        material: new Material().getOne(m.material),
      })),
    }));
    this.indexes = new MaterialIndex().getMany(indexes);
    this.materials = new Material().getMany(materials);
  }
}
