export const PROVIDER = {
  COMPANIES: 'companies',
  COMPANY_SUB_TYPES: 'company_sub_types',
  COMPANY_TYPES: 'company_types',
  INVOICES: 'invoices',
  INVOICE_LINES: 'invoice_lines',
  INVOICE_STATUS: 'invoice_status',
  INVOICE_TYPE: 'invoice_types',
  MATERIALS: 'materials',
  MATERIAL_FAMILIES: 'material_families',
  MATERIAL_INDEX_SOURCES: 'material_index_sources',
  MATERIAL_INDEX_VALUES: 'material_index_values',
  MATERIAL_REFERENTIALS: 'material_referentials',
  MATERIAL_SUB_FAMILIES: 'material_subfamilies',
  ORGANIZATIONS: 'organizations',
  ORGANIZATION_HIERARCHIES: 'organization_hierarchies',
  ORGANIZATION_TYPES: 'organization_types',
  CONTAINERS: 'containers',
  CONTAINER_DEMANDS: 'container_demands',
  CONTAINER_DEMAND_STATUS: 'container_demand_status',
};
export const MANAGEMENT = {
  COMPANY: 'company',
  INVOICE: 'invoice',
  MATERIAL: 'material',
  ORGANIZATION: 'organization',
};

export enum ROLES {
  ROLE_CUSTOMER = 1,
  ROLE_SUPPLIER = 2,
  ROLE_ADMIN = 3,
  ROLE_INTERNAL_OPERATOR = 3,
  ROLE_EXTERNAL_OPERATOR = 4,
  ROLE_DOCUMENTS_ACCESS = 5,
  ROLE_MOVEMENT_CUSTOMER = 6,
  ROLE_MOVEMENT_SUPPLIER = 7,
}


export enum ROUTES {
  Welcome = '/',
  Analysis = '/analysis',
  Login = '/login',
  Catalog = '/catalogs',
  Account = '/account',
  Movement = '/movement',
  DemandsExternal = '/demandsExternal',
  DemandsInternal = '/demandsInternal',
  CreateDemands = '/demandsExternal/create',
  ResetPasswordRequest = '/reset-password',
  ResetPasswordApply = '/reset-password/:token',
  CatalogItem = '/catalogs/:id',
  Contact = '/contact',
  Consent = '/consent',
  Documents = '/documents',
}

export const EXCLUDED_ROUTES: string[] = [
  ROUTES.Login,
  ROUTES.ResetPasswordRequest,
  ROUTES.ResetPasswordApply,
];
