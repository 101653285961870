import axios, { AxiosInstance } from 'axios';
import { axiosInterceptorError, Language, Token } from '../../helpers';
import { AbstractSerializer } from '../serializer/AbstractSerializer';
import { PossibleValueType } from '../KeyValueInterface';
import { User } from '../../actions';

export interface AbstractProviderConstructorInterface {
  filters?: any; //eslint-disable-line
  pagination?: any; //eslint-disable-line
}

interface OneInterface {
  data: any; //eslint-disable-line
}

interface ListInterface {
  data: any[]; //eslint-disable-line
  total: number;
}

interface CustomHeadersInterface {
  Accept: string;
  Authorization?: string;
  'Content-Type': string;
}

class RefreshTokenSingleton {
  static fetching = false;

  static refresh(): Promise<void> {
    if (this.fetching) {
      return new Promise(() => null);
    } else {
      this.fetching = true;
      return new User().refreshToken().finally(() => (this.fetching = false));
    }
  }
}

export class AbstractProvider {
  endpoint = '';
  env = process.env.REACT_APP_ENV || 'int';
  filters: any = {}; //eslint-disable-line
  ids: string[] = [];
  management = '';
  model: any; //eslint-disable-line
  name = '';
  pagination = {};
  token = new Token().get() || '';
  serializer = new AbstractSerializer();

  constructor({
    filters,
    pagination,
  }: AbstractProviderConstructorInterface = {}) {
    this.filters = { ...filters, language: new Language().get() };
    this.pagination = pagination;
  }

  getHeaders = (): Record<string, string> => {
    const h: Record<string, string> = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    if (this.token) {
      h['Authorization'] = `Bearer ${this.token}`;
    }

    return h;
  };

  request(): AxiosInstance {
    const instance = axios.create({
      headers: this.getHeaders(),
    });
    instance.interceptors.request.use(
      async (config) => {
        if (config.url !== '/login') {
          if (new Token().getDecoded().exp - Date.now() / 1000 <= 60 * 60) {
            RefreshTokenSingleton.refresh().catch(() => {
              User.logout({ updateClient: () => null });
              window.location.pathname = '/';
            });
          }
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
    instance.interceptors.response.use((r) => r, axiosInterceptorError);
    return instance;
  }

  formatQueryParameters = (): string => {
    const queryParametersObject = Object.entries({
      ...this.pagination,
      ...this.serializer.serialize(this.filters),
    }).reduce(
      (a, [k, v]: [string, PossibleValueType]) =>
        v === false
          ? { ...a, [k]: v }
          : v
          ? Array.isArray(v) && v.length
            ? {
                ...a,
                [k]: `[${
                  v[0].value ? v.map((x) => x.value).join(',') : v.join(',')
                }]`,
              }
            : v.value || v.value === false
            ? { ...a, [k]: v.value }
            : v.length || (!Array.isArray(v) && v)
            ? { ...a, [k]: '[]' === v ? '' : v }
            : a
          : a,
      {}
    );
    return Object.keys(queryParametersObject).length
      ? `?${new URLSearchParams(queryParametersObject).toString()}`
      : '';
  };

  getUrl = (value = '', withQueryParameters?: boolean): string =>
    `${process.env.REACT_APP_WDH_API_URL}/${this.management}_management/v1/${
      this.endpoint || this.name
    }${value}${withQueryParameters ? this.formatQueryParameters() : ''}`;

  create = ({
    data,
  }: any): Promise<OneInterface> => //eslint-disable-line
    this.request()
      .post(this.getUrl(), data)
      .then(({ data }) => ({
        data: data,
      }));

  update = ({ data }) =>
    this.request()
      .put(this.getUrl(`/${data.id}`), data, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      })
      .then(({ data }) => ({
        data: data?.data,
      }));

  deleteMany = (): void => {
    Promise.all(
      this.ids.map((id) => this.request().delete(this.getUrl(`/${id}`)))
    );
  };

  getList = (): Promise<ListInterface> =>
    this.request()
      .get(this.getUrl('', true))
      .then(({ data }) => ({
        data: this.model.getMany(data.data ? data.data : data),
        total: data.count ? data.count : data.length,
      }));

  getOne = ({ id }: { id: string }): Promise<OneInterface> =>
    this.request()
      .get(this.getUrl(`/${id}`, true))
      .then(({ data }) => ({
        data: this.model.getOne(data),
      }));
}
