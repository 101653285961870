import React, { useContext } from 'react';
import { Bars } from '../Abstract/Bars';
import { ChartContext } from '../../../contexts';
import { formatTitleToKg, TopFilters } from './Filters';

export const Volume: React.FC = () => {
  const { total } = useContext(ChartContext);

  return (
    <Bars type="volume">
      <TopFilters
        {...{
          additional: 't',
          formatTitle: (value): JSX.Element => formatTitleToKg(value),
          total,
        }}
      />
    </Bars>
  );
};
