import React, { useContext } from 'react';
// import { useTranslation } from 'react-i18next'
import { LanguageContext } from '../../contexts';

/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/**
 * We use react-table lib because the native Suez Design System
 * Component (SDS) does not provide the functionalities we want
 * The react-table lib is cool, but with TYPESCRIPT, we have
 * a lot of problem, so we use JS files
 */
function TablePagination({
  pageIndex,
  pageCount,
  pageSize,
  pageOptions,
  canPreviousPage,
  canNextPage,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
}) {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="table-pagination">
      <div className="table-pagination__top-part">
        <button
          type="button"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </button>{' '}
        <button
          type="button"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {'<'}
        </button>{' '}
        <span className="table-pagination__count">
          {pageIndex + 1} {pageCount ? `/ ${pageCount}` : ''}
        </span>
        <button
          type="button"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {'>'}
        </button>{' '}
        <button
          type="button"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {'>>'}
        </button>{' '}
      </div>
      <div className="table-pagination__line-per-page">
        <p>{translate('table.label.linesPerPage')}</p>
      </div>
      <div>
        <select
          value={pageSize}
          className="table-pagination__select"
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default TablePagination;
