import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../../contexts';

/**
 * We use react-table lib because the native Suez Design System
 * Component (SDS) does not provide the functionalities we want
 * The react-table lib is cool, but with TYPESCRIPT, we have
 * a lot of problem, so we use JS files
 */
function TableColumnFilter({
  // eslint-disable-next-line react/prop-types
  column: { setFilter },
}) {
  const { translate } = useContext(LanguageContext);
  const [value, setValue] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => setFilter(value), 1000);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <>
      <input
        className="table__filter-input custom-input"
        placeholder={translate('table.filterPlaceholder')}
        onChange={(e) => {
          setValue(e.target.value || undefined);
        }}
      />
    </>
  );
}

export default TableColumnFilter;
