import { useEffect, useState } from 'react';
import { OptionInterface } from '../components/Filters';

export const useDebounce = (
  value: string | OptionInterface[],
  delay: number
): string | OptionInterface[] => {
  const [debouncedValue, setDebouncedValue] = useState<
    string | OptionInterface[]
  >(value);

  useEffect((): (() => void) => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return (): void => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
