import { ModelActions, ModelInstance, ModelInterface } from './Common';
import {
  CompanySubTypeInstance,
  CompanySubTypeInterface,
} from './CompanySubType';
import { CompanyTypeInstance, CompanyTypeInterface } from './CompanyType';

export interface CompanyInterface extends ModelInterface {
  companyType: CompanyTypeInterface;
  companySubType: CompanySubTypeInterface;
  idCompany: string;
  companyLocalName: string;
}

export class CompanyInstance extends ModelInstance {
  companyType?: CompanyTypeInstance = undefined;
  companySubType?: CompanySubTypeInstance = undefined;

  constructor(company: CompanyInterface) {
    super(company);
    this.id = company.idCompany;
    this.name = company.companyLocalName;
    this.companyType =
      company.companyType && new CompanyTypeInstance(company.companyType);
    this.companySubType =
      company.companySubType &&
      new CompanySubTypeInstance(company.companySubType);
  }
}

export class Company extends ModelActions {
  getMany = (companies: CompanyInterface[]): CompanyInstance[] =>
    companies.map((company) => new CompanyInstance(company));
  getOne = (company: CompanyInterface): CompanyInstance =>
    new CompanyInstance(company);
}
