/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from 'react';
import { useHistory, Route, useLocation } from 'react-router-dom';

import { Token } from '../helpers';
import { EXCLUDED_ROUTES, ROLES, ROUTES } from '../actions/BCM_Constant';

interface CustomRouteProps {
  children?: React.ReactNode;
  path: string | string[];
  exact: boolean;
  condition: ROLES[];
  personnalizedCondition?: boolean;
}

function hasAccessTo(
  businessRoles: Array<number>,
  currentRoles: Array<number>
) {
  return businessRoles.find((businessRole) =>
    currentRoles.find((currentRole) => currentRole === businessRole)
  );
}

function getEnumKeyByValue(myEnum: any, enumValue: any): any {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : null;
}

function roleNamesToNumber(roleNames: Array<string>): Array<number> {
  return roleNames.map((roleName: string) =>
    parseInt(getEnumKeyByValue(ROLES, roleName), 10)
  );
}

const CustomRoute = (props: CustomRouteProps) => {
  const history = useHistory();
  const location = useLocation();

  const roleNames = new Token().getDecoded().roles;

  const [returnedRoute, setReturnedRoute] = useState(<Route {...props} />);

  const { condition, path } = props;

  React.useEffect(() => {
    if (
      roleNames?.length > 0 &&
      !hasAccessTo(condition, roleNamesToNumber(roleNames)) &&
      !EXCLUDED_ROUTES.includes(String(path)) &&
      path === location.pathname
    ) {
      history.push(ROUTES.Login);
    }

    setReturnedRoute(<Route {...props} />);
  }, [path]);

  return returnedRoute;
};

export default CustomRoute;
