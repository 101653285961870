import React, { useContext } from 'react';
import { OptionInterface, ResetFilter, SelectFilter } from '../../Filters';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  MixedChartContext,
  RESET,
  SET_SELECTED_INDEX,
  SET_SELECTED_MATERIAL,
} from '../../../contexts/MixedChartContext';

const divideBy1000 = (n: number, loop = 0): [number, number] =>
  n < 1000 ? [n, loop] : divideBy1000(n / 1000, loop + 1);

const formatToUnits = (total: number, symbols: string[]): [string, string] => {
  const [n, l]: [number, number] = divideBy1000(total);
  return [n.toFixed(2), symbols[parseInt(l.toString())]];
};

const formattedTitle = (result: string, symbol: string): JSX.Element => (
  <span>
    {result}
    <span className="fs-7-em">{symbol}</span>
  </span>
);

export const formatTitleToK = (
  total: number,
  currency: string
): JSX.Element => {
  const [result, symbol] = formatToUnits(total, ['', 'K', 'M', 'B', 'T']);
  return formattedTitle(result, `${symbol}${currency}`);
};

export const formatTitleToKg = (total: number): JSX.Element => {
  const [result, symbol] = formatToUnits(total, ['t', 'Kt', 'Mt', 'Tt']);
  return formattedTitle(result, symbol);
};
export const formatLabelYearMonthUTC = (year: number, month: number): Date => {
  return new Date(
      Date.UTC( year, parseInt(`${month < 10 ? '0' : ''}${month})`)-1, 2)
    );
};

interface TopFiltersInterface {
  additional?: string;
  formatTitle: (total: number) => JSX.Element;
  total: number;
}

export const TopFilters: React.FC<TopFiltersInterface> = ({
  additional = '',
  children,
  formatTitle,
  total,
}) => (
  <div className="row m-0">
    <div className="offset-lg-4 col-lg-4 pt-3 pt-lg-0 col-12 text-center">
      {children || (
        <OverlayTrigger
          {...{
            placement: 'top',
            delay: { show: 250, hide: 400 },
            overlay: (
              <Tooltip id={'tooltip-filters'}>
                {total
                  .toFixed(2)
                  .toString()
                  .replace(
                    /\B(?=(\d{3})+(?!\d))/g /*eslint-disable-line*/,
                    ','
                  )}
                {additional}
              </Tooltip>
            ),
          }}
        >
          <div className="my-auto fs-4 text-primary pointer">
            {formatTitle(total)}
          </div>
        </OverlayTrigger>
      )}
    </div>
  </div>
);

export const BottomFilters: React.FC = () => {
  const { dispatch, materials, indexes, selectedIndex, selectedMaterial } =
    useContext(MixedChartContext);
  return (
    <div className="row m-0">
      <div className="col-lg-3 p-0" />
      <div className="col-lg-3 d-md-block m-auto py-1 py-lg-0">
        <ResetFilter
          resetFilters={(): void =>
            dispatch({ type: RESET, payload: undefined })
          }
        />
      </div>
      <div className="col-lg-3 col-md-12 px-lg-2 p-0">
        <SelectFilter
          {...{
            name: 'idIndex',
            onChange: (v: OptionInterface): void =>
              dispatch({
                payload: v,
                type: SET_SELECTED_INDEX,
              }),
            options: indexes.map((i) => ({
              label: i.name,
              value: i.id,
            })),
            placeholder: 'indexes',
            value: selectedIndex,
          }}
        />
      </div>
      <div className="col-lg-3 px-lg-2 p-0">
        <SelectFilter
          {...{
            name: 'idMaterial',
            onChange: (v: OptionInterface): void =>
              dispatch({
                payload: v,
                type: SET_SELECTED_MATERIAL,
              }),
            options: materials.map((m) => ({
              label: `${m.code} - ${m.name}`,
              value: m.id,
            })),
            placeholder: 'material',
            value: selectedMaterial,
          }}
        />
      </div>
    </div>
  );
};
