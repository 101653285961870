/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Layout } from '../../components/Layout';
import { ComponentWithTitle } from '../../components/Common';
import { useLogged } from '../../hooks';
import { BaseStoreContext, LanguageContext } from '../../contexts';
import Table from '../../components/TableContainer/Table';
import {
  ContainerDemand,
  ContainerDemandStatus,
} from '../../interfaces/providers';
import { UserId } from '../../helpers';
import { ContainerDemandStatusInstance } from '../../interfaces';
import './Demand.scss';
import { SzAlert, SzSelect } from 'react-theme-components';
import { CLOSED_STATUS, INPROGRESS_STATUS, STATUS } from './DemandsInternal';
import { User } from '../../actions/User';
import { Materials } from './Materials';

export const DemandsExternal: React.FC = () => {
  useLogged();
  const { itemFiltersSelected } = useContext(BaseStoreContext);
  const { translate } = useContext(LanguageContext);
  const { state } = useLocation<{ sucessCreate: boolean }>();
  const history = useHistory();

  const [inProgressTableData, setInprogressTableData] = useState<any[]>([]);
  const [closedTableData, setClosedTableData] = useState<any[]>([]);
  const [pageInprogress, setPageInprogress] = useState(1);
  const [pageClosed, setPageClosed] = useState(1);
  const [pgCountClosed, setPgCountClosed] = useState(1);
  const [pgCountInprogress, setPgCountInprogress] = useState(1);
  const [perPageClosed, setPerPageClosed] = useState(10);
  const [perPageInprogress, setPerPageInprogress] = useState(10);
  const [StatusList, setStatusList] = useState<any[]>([]);
  const [inprogressStatusList, setInprogressStatusList] = useState<any[]>([]);
  const [closedStatusList, setClosedStatusList] = useState<any[]>([]);
  const [isClosedTableLoading, setIsClosedTableLoading] = useState(false);
  const [isInprogressTableLoading, setIsInprogressTableLoading] =
    useState(false);
  const [successUpdate, setSucessUpdate] = useState<boolean>(false);
  const [failureUpdate, setFailureUpadate] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [sucessCreate, setSucessCreate] = useState<boolean>(false);
  const [inprogressFilters, setInprogressFilters] = useState<any[]>([]);
  const [closedFilters, setClosedFilters] = useState<any[]>([]);
  const [sortInprogressTable, setSortInprogressTable] = useState([]);
  const [sortClosedTable, setSortClosedTable] = useState([]);

  function StatutInprogressDemandFilter({ column }: { column: any }) {
    const filterList = [
      { value: '', label: translate(`pages.demands.status.all`) },
      ...inprogressStatusList,
    ];
    const { filterValue = filterList[0], setFilter } = column;
    return (
      <SzSelect
        id={'container-select'}
        options={filterList}
        value={filterValue}
        onChange={(value: any) => {
          setFilter(value);
        }}
      />
    );
  }
  function StatutClosedDemandFilter({ column }: { column: any }) {
    const filterList = [
      { value: '', label: translate(`pages.demands.status.all`) },
      ...closedStatusList,
    ];
    const { filterValue = filterList[0], setFilter } = column;
    return (
      <SzSelect
        id={'container-select'}
        options={filterList}
        value={filterValue}
        onChange={(value: any) => {
          setFilter(value);
        }}
      />
    );
  }

  const TABLE_COLUMN = [
    {
      Header: translate('table.header.demands.idDemand'),
      accessor: 'id',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: translate('table.header.demands.container'),
      accessor: 'containerName',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: translate('table.header.demands.materials'),
      accessor: 'materials',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: translate('table.header.demands.date'),
      accessor: 'bookingDate',
      disableFilters: true,
    },
  ];

  const initialSortBy = [
    {
      id: 'bookingDate',
      desc: true,
    },
  ];

  const INPROGRESS_TABLE_CULUMN = [
    ...TABLE_COLUMN,
    {
      Header: translate('table.header.demands.status'),
      accessor: 'status',
      Filter: StatutInprogressDemandFilter,
      disableSortBy: true,
    },
    {
      Header: translate('table.header.common.actions'),
      accessor: 'controlButtons',
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  const CLOSED_TABLE_COLUMN = [
    ...TABLE_COLUMN,
    {
      Header: translate('table.header.demands.status'),
      accessor: 'status',
      Filter: StatutClosedDemandFilter,
      disableSortBy: true,
    },
  ];

  useEffect(() => {
    if (state?.sucessCreate) {
      setSuccessMessage('forms.externalDemand.successCreateStatus');
      setSucessCreate(true);
      new Promise((resolve) => setTimeout(resolve, 5000)).then((r) => {
        setSucessCreate(false);
      });
      history.replace({
        pathname: '/demandsExternal',
        state: {},
      });
    }
  }, []);

  async function loadDataInprogress() {
    setIsInprogressTableLoading(true);
    const statusFilter = inprogressFilters.find((f) => f.id === 'status');
    const statusFilterValue =
      statusFilter && statusFilter?.value.value !== ''
        ? [statusFilter?.value]
        : inprogressStatusList.map((s) => s.value);

    await new ContainerDemand({
      filters: {
        perPage: perPageInprogress,
        page: pageInprogress,
        idOrganizations: itemFiltersSelected.organization,
        idCompanies: itemFiltersSelected.plant,
        externalOperator: new UserId().get(),
        idStatus: statusFilterValue,
        orderByDate: sortInprogressTable['Date'],
      },
    })
      .getList()
      .then(({ data, total }) => {
        setInprogressTableData(data);
        setPgCountInprogress(Math.ceil(total / perPageInprogress));
        setIsInprogressTableLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsInprogressTableLoading(false);
      });
  }

  async function loadDataClosed() {
    setIsClosedTableLoading(true);
    const statusFilter = closedFilters.find((f) => f.id === 'status');
    const statusFilterValue =
      statusFilter && statusFilter?.value.value != ''
        ? [statusFilter?.value]
        : closedStatusList.map((s) => s.value);

    await new ContainerDemand({
      filters: {
        perPage: perPageClosed,
        page: pageClosed,
        idOrganizations: itemFiltersSelected.organization,
        idCompanies: itemFiltersSelected.plant,
        externalOperator: new UserId().get(),
        idStatus: statusFilterValue,
        orderByDate: sortClosedTable['Date'],
      },
    })
      .getList()
      .then(({ data, total }) => {
        setClosedTableData(data);
        setPgCountClosed(Math.ceil(total / perPageClosed));
        setIsClosedTableLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsClosedTableLoading(false);
      });
  }

  // Handler of change status
  const handleStatusChange = async (demand: any, statusLabel: string) => {
    const idStatus = StatusList.find((s) => s.name === statusLabel).id;
    setIsInprogressTableLoading(true);

    await new ContainerDemand()
      .update({
        data: {
          id: demand.id,
          idDemandStatus: idStatus,
          externalOperator: new UserId().get(),
        },
      })
      .then(({ data }) => {
        new User().sendDemandMail({
          data: {
            demandStatus: statusLabel,
            type: 'internal',
            operators: data.map((operator) => operator.idOperator),
            demand: demand.idDemand,
          },
        });
      })
      .then(() => {
        loadDataInprogress();
        loadDataClosed();
        setSucessUpdate(true);
        setSuccessMessage('forms.internalDemand.successUpdateStatus');
        new Promise((resolve) => setTimeout(resolve, 5000)).then((r) => {
          setSucessUpdate(false);
        });
      })
      .catch((e) => {
        setIsInprogressTableLoading(false);
        setFailureUpadate(true);
        new Promise((resolve) => setTimeout(resolve, 5000)).then((r) => {
          setFailureUpadate(false);
        });
        console.error(e);
      });
  };
  const getStatus = (demand: any, status: ContainerDemandStatusInstance) => {
    if (status.name === STATUS.InProgress || status.name === STATUS.Planned) {
      return (
        <div className="demands__ButtonCell">
          <button
            type="button"
            className=" bg-primary text-white btn"
            onClick={() => {
              return handleStatusChange(demand, STATUS.Canceled);
            }}
          >
            {translate('pages.demands.status.Cancel')}
          </button>
        </div>
      );
    }
    if (status.name === STATUS.NewDate) {
      return (
        <div className="demands__ButtonCell">
          <button
            type="button"
            className="bg-primary text-white btn"
            onClick={() => handleStatusChange(demand, STATUS.Planned)}
          >
            {translate('pages.demands.status.Accept')}
          </button>
          <button
            type="button"
            className=" bg-primary text-white btn"
            onClick={() => handleStatusChange(demand, STATUS.Canceled)}
          >
            {translate('pages.demands.status.Refuse')}
          </button>
        </div>
      );
    }
  };

  // Handler of table filters
  const handleInprogressFiltersChange = (filter: any[]) => {
    if (JSON.stringify(filter) !== JSON.stringify(inprogressFilters)) {
      setPageInprogress(1);
      setInprogressFilters(filter);
    }
  };
  const handleClosedFiltersChange = (filter: any[]) => {
    if (JSON.stringify(filter) !== JSON.stringify(closedFilters)) {
      setPageClosed(1);
      setClosedFilters(filter);
    }
  };

  useEffect(() => {
    async function loadStatus() {
      await new ContainerDemandStatus()
        .getList()
        .then(({ data }) => {
          const inprogressStatus: any[] = [];
          const closedStatus: any[] = [];
          data.map((status) => {
            if (INPROGRESS_STATUS.includes(status.name))
              inprogressStatus.push({
                value: status.id,
                label: translate(`pages.demands.status.${status.name}`),
              });
            else if (CLOSED_STATUS.includes(status.name))
              closedStatus.push({
                value: status.id,
                label: translate(`pages.demands.status.${status.name}`),
              });
          });
          setStatusList(data);
          setInprogressStatusList(inprogressStatus);
          setClosedStatusList(closedStatus);
        })
        .catch((e) => {
          console.error(e);
        });
    }

    try {
      setIsInprogressTableLoading(true);
      setIsClosedTableLoading(true);
      loadStatus();
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (inprogressStatusList.length > 0) {
      loadDataInprogress();
    }
  }, [
    pageInprogress,
    perPageInprogress,
    inprogressStatusList,
    inprogressFilters,
    sortInprogressTable,
    itemFiltersSelected,
  ]);

  useEffect(() => {
    if (closedStatusList.length > 0) {
      loadDataClosed();
    }
  }, [
    pageClosed,
    perPageClosed,
    closedStatusList,
    closedFilters,
    sortClosedTable,
    itemFiltersSelected,
  ]);

  const formatedTableData = (demands: any[]) => {
    return demands.map((demand: any) => {
      return {
        id: demand.idDemand,
        idContainerDemand: demand.name,
        containerName: (
          <div className="demands__createdAtCell">{demand.container.name}</div>
        ),
        materials: <Materials demand={demand} />,
        bookingDate: (
          <div className="demands__dateCell">
            {demand.bookingDate
              ? `${new Date(demand.bookingDate).toLocaleDateString(
                  navigator.language
                )}
                  ${new Date(demand.bookingDate)
                    .toISOString()
                    .substring(11, 19)}`
              : ''}
            {'  '}
          </div>
        ),
        status: (
          <div className="demands__statusCell">
            {demand.status
              ? translate(`pages.demands.status.${demand.status.name}`)
              : ''}
          </div>
        ),
        controlButtons: getStatus(demand, demand.status),
      };
    });
  };

  return (
    <Layout withFooter>
      <ComponentWithTitle title="pages.demands.inprogress" icon="apps">
        <div className="row pt-1 m-0 w-100">
          <button
            type="button"
            className=" bg-primary text-white btn"
            onClick={() => {
              history.push(`/demandsExternal/create`);
            }}
          >
            {translate('pages.demands.NewDemand')}
          </button>
        </div>
        <div className="demands row pt-1 m-0 w-100">
          {(successUpdate || sucessCreate) && (
            <div className="col-9 pt-1">
              <SzAlert variant="success" transparent>
                {translate(`${successMessage}`)}
              </SzAlert>
            </div>
          )}
          {failureUpdate && (
            <div className="col-9 pt-1">
              <SzAlert variant="danger" transparent>
                {translate(`forms.errorMessage`)}
              </SzAlert>
            </div>
          )}
          <Table
            type="hidden"
            columns={INPROGRESS_TABLE_CULUMN}
            data={formatedTableData(inProgressTableData)}
            disabledColumnActionsList={[]}
            isInternalPagination={true}
            onPageChange={setPageInprogress}
            nbPerPage={perPageInprogress}
            onNbPerPageChange={setPerPageInprogress}
            pageCount={pgCountInprogress}
            initialFilters={inprogressFilters}
            onFiltersChange={handleInprogressFiltersChange}
            isLoading={isInprogressTableLoading}
            initialSortBy={initialSortBy}
            onSortTableChange={setSortInprogressTable}
          />
        </div>
      </ComponentWithTitle>

      <ComponentWithTitle title="pages.demands.closed" icon="apps">
        <div className="demands row pt-1 m-0 w-100">
          <Table
            type="hidden"
            columns={CLOSED_TABLE_COLUMN}
            data={formatedTableData(closedTableData)}
            disabledColumnActionsList={[]}
            isInternalPagination={true}
            onPageChange={setPageClosed}
            nbPerPage={perPageClosed}
            onNbPerPageChange={setPerPageClosed}
            pageCount={pgCountClosed}
            initialFilters={closedFilters}
            onFiltersChange={handleClosedFiltersChange}
            isLoading={isClosedTableLoading}
            initialSortBy={initialSortBy}
            onSortTableChange={setSortClosedTable}
          />
        </div>
      </ComponentWithTitle>
    </Layout>
  );
};
