import { ModelActions, ModelInstance } from './Common';

export interface MaterialSubFamilyInterface {
  idMaterialSubFamily: string;
  materialSubFamilyCode: string;
  materialSubFamilyName: string;
}

export class MaterialSubFamilyInstance extends ModelInstance {
  constructor(materialSubFamily: MaterialSubFamilyInterface) {
    super(materialSubFamily);
    this.code = materialSubFamily.materialSubFamilyCode;
    this.id = materialSubFamily.idMaterialSubFamily;
    this.name = materialSubFamily.materialSubFamilyName;
  }
}

export class MaterialSubFamily extends ModelActions {
  getMany = (
    materialSubFamilies: MaterialSubFamilyInterface[]
  ): MaterialSubFamilyInstance[] =>
    materialSubFamilies.map(
      (materialSubFamily) => new MaterialSubFamilyInstance(materialSubFamily)
    );
  getOne = (
    materialSubFamily: MaterialSubFamilyInterface
  ): MaterialSubFamilyInstance =>
    new MaterialSubFamilyInstance(materialSubFamily);
}
