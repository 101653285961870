import { useContext, useEffect } from 'react';
import { UserContext } from '../contexts';
import { useHistory, useLocation } from 'react-router-dom';

export const useNotLogged = (): void => {
  const { push } = useHistory();
  const { isLogged } = useContext(UserContext);
  if (isLogged) {
    push('/');
  }
};
