import React, { useContext, useRef } from 'react';
import { Bar as ChartBar } from 'react-chartjs-2';
import './bar.scss';
import { renderToString } from 'react-dom/server';
import { Loader } from '../../Loader';
import { BarInterface } from './Bar';
import { MixedChartContext } from '../../../contexts/MixedChartContext';
import { BottomFilters } from '../Analysis/Filters';
import { Language } from '../../../helpers';

const Tooltip = (
  title: string,
  month: string,
  value: string,
  unit: string,
  color: string
): string => {

  const formated = value
    .split(': ')[1]
    .replaceAll(' ', '')
    .replaceAll(' ', '')
    .replace(',', '.');
  const v = parseFloat(formated).toLocaleString(new Language().get());

  return renderToString(
    <div className="p-3">
      <span className="d-block h1 text-primary text-center">{title}</span>
      <span className="d-block fs-1 text-muted text-center">{month}</span>
      <span className={`d-block fs-2 text-center`} style={{ color }}>
        {`${v}${unit}`}
      </span>
    </div>
  );
};

export const Mixed: React.FC<BarInterface> = ({
  children,
  options = {},
  type = '',
  unit = '',
}) => {
  const ref = useRef(null);
  const { data } = useContext(MixedChartContext);

  return (
    <div className="bg-white py-3">
      <div className="px-4 pb-3">
        {children}
        {!data ? (
          <Loader text={`charts.${type}`} />
        ) : (
          <div className="pt-4 pb-2">
            <div className="chart-container">
              <ChartBar
                {...{
                  redraw: true,
                  data,
                  options: {
                    ...options,
                    plugins: {
                      legend: {
                        display: true,
                        position: 'bottom',
                        align: 'start',
                      },
                      tooltip: {
                        enabled: false,
                        backgroundColor: '#FFF',
                        titleFontSize: 16,
                        bodyFontColor: '#000',
                        bodyFontSize: 14,
                        displayColors: false,
                        external: (context): void => {
                          let tooltipEl: HTMLElement | null =
                            document.getElementById('chartjs-tooltip');

                          if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                          }

                          const tooltipModel = context.tooltip;
                          if (tooltipModel.opacity === 0) {
                            tooltipEl.innerHTML = `<div class="d-none"></div>`;
                            tooltipEl.style.opacity = '0';
                            return;
                          } else {
                            tooltipEl.innerHTML = Tooltip(
                              data.datasets.filter(
                                (d) =>
                                  d.borderColor ===
                                  tooltipModel.labelColors[0].backgroundColor
                              )[0].label,
                              tooltipModel.title[0],
                              tooltipModel.body[0].lines[0],
                              unit,
                              tooltipModel?.labelColors[0]?.backgroundColor.toString() ||
                                '#77B7FC'
                            );
                          }

                          tooltipEl.classList.remove(
                            'above',
                            'below',
                            'no-transform'
                          );
                          if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                          } else {
                            tooltipEl.classList.add('no-transform');
                          }

                          const position =
                            context.chart.canvas.getBoundingClientRect();

                          tooltipEl.classList.add(
                            'tooltip',
                            'shadow',
                            'bg-white',
                            'p-0'
                          );
                          tooltipEl.style.position = 'absolute';
                          tooltipEl.style.opacity = '1';
                          tooltipEl.style.left = `${
                            position.left +
                            window.pageXOffset +
                            tooltipModel.caretX
                          }px`;
                          tooltipEl.style.top = `${
                            position.top +
                            window.pageYOffset +
                            tooltipModel.caretY
                          }px`;
                        },
                      },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    elements: {
                      line: {
                        tension: 0.5,
                      },
                    },
                    scales: {
                      x: {
                        grid: {
                          display: false,
                        },
                        position: 'top',
                      },
                      'y-axis-1': {
                        type: 'linear',
                        display: true,
                        beginAtZero: true,
                        position: 'left',
                      },
                    },

                    ...options,
                  },
                  ref,
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="px-4">
        <BottomFilters />
      </div>
    </div>
  );
};
