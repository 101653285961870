import { AbstractProvider } from './abstract';
import { Material as MaterialModel } from '../model';
import { MANAGEMENT, PROVIDER } from '../../actions/BCM_Constant';
import { MaterialSerializer } from '../serializer/MaterialSerializer';

export class Material extends AbstractProvider {
  management = MANAGEMENT.MATERIAL;
  model = new MaterialModel();
  name = PROVIDER.MATERIALS;
  serializer = new MaterialSerializer();
}
