import { ModelActions, ModelInstance, ModelInterface } from './Common';

export interface ContainerDemandStatusInterface extends ModelInterface {
  idContainerDemandStatus: string;
  demandStatusCode: string;
  demandStatusName: string;
}

export class ContainerDemandStatusInstance extends ModelInstance {
  constructor(demandStatus: ContainerDemandStatusInterface) {
    super(demandStatus);
    this.id = demandStatus.idContainerDemandStatus;
    this.code = demandStatus.demandStatusCode;
    this.name = demandStatus.demandStatusName;
  }
}

export class ContainerDemandStatus extends ModelActions {
  getMany = (
    demandeStatus: ContainerDemandStatusInterface[]
  ): ContainerDemandStatusInstance[] =>
    demandeStatus.map((status) => new ContainerDemandStatusInstance(status));
  getOne = (
    status: ContainerDemandStatusInterface
  ): ContainerDemandStatusInstance => new ContainerDemandStatusInstance(status);
}
