import React from 'react';
import { Chip } from '../../components/Chip/Chip';

export const Materials: React.FC<any> = ({ demand }) => (
  <div className="demands__MaterialsCell">
    {demand.materials?.map((m, i) => {
      return <Chip key={i} label={m.name} />;
    })}
  </div>
);
