import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CircularEconomyChart } from '../interfaces/providers';
import { getDate } from '../helpers';
import {
  BaseStoreContext,
} from './BaseStoreContext';
import { ChartContext, DataInterface, defaultContext } from './ChartContext';
import { LanguageContext } from './LanguageContext';
import { TypeSelectorContext } from '../components/TypeSelector';
import { Co2ChartInstance } from '../interfaces';
import { formatLabelYearMonthUTC } from '../components/Chart/Analysis/Filters';

export interface ChartProviderInterface {
  options: { [key: string]: GraphInterface };
}
interface InternalChartProviderInterface extends ChartProviderInterface {
  startDate: Date;
  endDate: Date;
}
interface GraphInterface {
  color: string;
  label: string;
  sum?: boolean;
  type?: string;
  unit?: string;
}

export const CircularEconomyChartProvider: React.FC<
  InternalChartProviderInterface
> = ({ children, options, startDate, endDate }) => {
  const { translate } = useContext(LanguageContext);
  const {
    itemFiltersSelected: { organization, plant },
    analysis: { materials, selectedMaterials },
    dispatch,
  } = useContext(BaseStoreContext);
  const endDeliveryDate = getDate(endDate);
  const startDeliveryDate = getDate(startDate);

  const [data, setData] = useState<DataInterface>(defaultContext.data);
  const [total, setTotal] = useState<number>(defaultContext.total);
  const { selected } = useContext(TypeSelectorContext);
  const finalSelectedMaterials = useMemo(
    () =>
      !materials.length || materials.length === selectedMaterials.length
        ? []
        : selectedMaterials.length
        ? selectedMaterials
        : [],
    [materials.length, selectedMaterials]
  );

  const filters = {
    endDeliveryDate,
    idCompanies: plant,
    idMaterials: finalSelectedMaterials,
    idOrganizations: organization,
    mvtTypeCode: selected == 'ROLE_CUSTOMER' ? '02' : '01',
    role: selected,
    startDeliveryDate,
  };

  const compute = (filters: any, { reset = true }) => {
    new CircularEconomyChart({ filters })
      .getChart()
      .then(({ data, materials: m }) => {
        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        const currentMonth: Co2ChartInstance = {
          label: formatLabelYearMonthUTC(year, month),
          value: 0,
        };
        let labels: any[] = [];
        let datasets: any[] = [];
        let t = 0;

        data.forEach((graph) => {
          const graphOptions: GraphInterface = options[graph.type];
          if (!graphOptions) return;

          const d: number[] = [];
          graph.data
          .filter(
            (item) => JSON.stringify(item) !== JSON.stringify(currentMonth)
          )
          .forEach((item) => {
            labels.push(item.label);
            d.push(item.value);

            if (graphOptions.sum) t += item.value;
          });
          datasets.push({
            label: translate(graphOptions.label),
            data: d,
            unit: 't',
            backgroundColor: graphOptions.color,
          });
        });

        labels = labels
          .map((date) => date.getTime()) //Convert Date() to be able to compare
          .filter((date, i, array) => array.indexOf(date) === i) //Remove duplicates
          .sort((a: any, b: any) => a - b) //Sort date
          .map((item) => {
            //Convert To MM. YYYY => Ex: Jan. 2023
            const dateString = new Date(item).toLocaleDateString(
              navigator.language,
              {
                month: 'short',
                year: 'numeric',
              }
            );
            return dateString.replace(/^./, dateString[0].toUpperCase());
          });

        if (datasets.length > 1) {
          datasets = datasets.filter((dataset) => dataset.label != 'NA');
        }

        // datasets.reverse(); // Pour que la courbe soit devant les barres...
        setTotal(t);
        setData({
          labels,
          datasets,
        });
      });
  };

  useEffect(() => {
    setData(undefined);
    compute({ ...filters }, { reset: true });
  }, [
    options,
    filters.endDeliveryDate,
    JSON.stringify(filters.idMaterials),
    JSON.stringify(filters.idCompanies),
    JSON.stringify(filters.idOrganizations),
    filters.mvtTypeCode,
    filters.role,
    filters.startDeliveryDate,
  ]);

  return (
    <ChartContext.Provider
      value={{
        ...{ materials, selectedMaterials },
        data,
        dispatch,
        total,
      }}
    >
      {children}
    </ChartContext.Provider>
  );
};
