import { ModelActions, ModelInstance, ModelInterface } from './Common';

export interface OrganizationInterface extends ModelInterface {
  idOrganization: string;
  organizationLevel: string;
  organizationName: string;
  parentOrganization?: OrganizationInterface;
}

export class OrganizationInstance extends ModelInstance {
  parent?: OrganizationInstance = undefined;
  level: string;

  constructor(organization: OrganizationInterface) {
    super(organization);
    this.id = organization.idOrganization;
    this.name = organization.organizationName;
    this.id = organization.idOrganization;
    this.name = organization.organizationName;
    this.parent =
      organization.parentOrganization &&
      new OrganizationInstance(organization.parentOrganization);
    this.level = organization.organizationLevel;
  }
}

export class Organization extends ModelActions {
  getMany = (organizations: OrganizationInterface[]): OrganizationInstance[] =>
    organizations.map((organization) => new OrganizationInstance(organization));
  getOne = (organization: OrganizationInterface): OrganizationInstance =>
    new OrganizationInstance(organization);
}
