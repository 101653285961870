/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState, useEffect } from 'react';
import { Layout } from '../../components/Layout';
import { ComponentWithTitle } from '../../components/Common';
import { useLogged } from '../../hooks';
import { BaseStoreContext, LanguageContext } from '../../contexts';
import { useHistory } from 'react-router';
import {
  SzAlert,
  SzButton,
  SzDatePicker,
  SzSelect,
} from 'react-theme-components';
import TimePicker from 'rc-time-picker';
import { Container, ContainerDemand } from '../../interfaces/providers';
import { Company, Organization, UserId } from '../../helpers';
import './Demand.scss';

import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { User } from '../../actions';

export const STATUS = {
  InProgress: 'In Progress',
  Planned: 'Planned',
  NewDate: 'New Date',
  Closed: 'Closed',
  Canceled: 'Canceled',
  NoReturn: 'No Return',
};

export const CreateDemands: React.FC = () => {
  useLogged();
  const { itemFiltersSelected } = useContext(BaseStoreContext);
  const { translate } = useContext(LanguageContext);
  const { push } = useHistory();

  const [containerList, setContainerList] = useState<any[]>([]);
  const [selectedContainer, setSelectedContainer] = useState({
    value: '',
    label: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectLoading, setIsSelectLoading] = useState(false);
  const [bookingDate, setBookingDate] = useState<any>(new Date());
  const [bookingTime, setBookingTime] = useState<any>(
    moment()
      .add(75 - (moment().minutes() % 5), 'minutes')
      .startOf('minute')
  );
  const [failureUpdate, setFailureUpadate] = useState<boolean>(false);
  const [failureMessage, setFailureMessage] = useState<string>('');

  const handleSelectionChange = (option) => {
    if (option) {
      setSelectedContainer(option);
    }
  };

  // Handle create container command
  const handleCreateDemand = async () => {
    setIsLoading(true);
    let formatedBookingDate = `${moment(bookingDate).format(
      'YYYY-MM-DD'
    )} ${bookingTime}`;
    if (moment(formatedBookingDate) < moment()) {
      setFailureUpadate(true);
      setFailureMessage(translate(`forms.externalDemand.invalidBookingDate`));
      new Promise((resolve) => setTimeout(resolve, 5000)).then((r) => {
        setFailureUpadate(false);
      });
      setIsLoading(false);
      return;
    }

    formatedBookingDate = `${moment(bookingDate).format('YYYY-MM-DD')} ${moment(
      bookingTime
    ).format('HH:mm:ss')}`;

    const data = {
      idContainer: selectedContainer?.value,
      CreatedBy: new UserId().get(),
      bookingDate: formatedBookingDate,
    };

    await new ContainerDemand()
      .create({
        data,
      })
      .then(({ data }) => {
        new User().sendDemandMail({
          data: {
            demandStatus: STATUS.InProgress,
            type: 'internal',
            operators: data.operators.map((operator) => operator.idOperator),
            bookingDate: formatedBookingDate,
            materials: data.materials.map((material) => material.materialName),
            container: data.containerName,
            company: new Company().getDecoded().name,
            organization: new Organization().getDecoded().name,
            demand: 0,
          },
        });
      })
      .then(() => {
        setIsLoading(false);
        push(`/demandsExternal`, { sucessCreate: true });
      })
      .catch((e) => {
        setFailureUpadate(true);
        setFailureMessage(translate(`forms.errorMessage`));
        new Promise((resolve) => setTimeout(resolve, 5000)).then((r) => {
          setFailureUpadate(false);
        });
        setIsLoading(false);
        console.error(e);
      });
  };

  useEffect(() => {
    async function loadContainers() {
      await new Container({
        filters: {
          idUser: new UserId().get(),
          idOrganizations: itemFiltersSelected.organization,
          idCompanies: itemFiltersSelected.plant,
        },
      })
        .getList()
        .then(({ data }) => {
          setIsSelectLoading(false);
          const options = data.map((e) => {
            return {
              value: e.id,
              label: e.name,
            };
          });
          setContainerList(options);
        })
        .catch((e) => {
          console.error(e);
        });
    }
    try {
      setIsSelectLoading(true);
      setSelectedContainer({ value: '', label: '' });
      loadContainers();
    } catch (error) {
      console.error(error);
    }
  }, [itemFiltersSelected]);

  return (
    <Layout withFooter>
      <ComponentWithTitle title="pages.newDemand" icon="apps">
        <div className="col-12 p-4 w-100 bg-white">
          {failureUpdate && (
            <div className="col-9 pt-1">
              <SzAlert variant="danger" transparent>
                {failureMessage}
              </SzAlert>
            </div>
          )}
          <div className="row m-4 w-100">
            {translate(`pages.newDemand.chooseContainerAndDate`)}
          </div>
          <div className="col-12 m-2 py-2 w-100">
            <SzSelect
              id={'container-select'}
              placeholder={translate(`pages.newDemand.selectContainer`)}
              options={containerList}
              value={selectedContainer}
              onChange={handleSelectionChange}
              isSearchable={true}
              isLoading={isSelectLoading}
            />
          </div>
          <div className="row m-2 pb-3 py-2 w-100">
            <div className="col-6 pt-2 m-0">
              <SzDatePicker
                {...{
                  format: 'dd/MM/yyyy',
                  startMinDate: new Date(),
                  onChangeStartDate: (v): void => setBookingDate(v),
                  classNameStartDate: 'col-12',
                  startDate: bookingDate,
                }}
              />
            </div>
            <div className="col-6 pt-2 m-0">
              <TimePicker
                showSecond={false}
                defaultValue={bookingTime}
                onChange={(value) => setBookingTime(value)}
                placeholder={translate(`pages.demands.chooseTime`)}
                popupClassName={'rc-time-picker-panel-customer'}
                placement={'bottomLeft'}
                minuteStep={5}
              />
            </div>
          </div>
          <div className="col-12 pl-3 pr-3 pt-4 m-2">
            <SzButton
              className="my-auto"
              variant="secondary"
              onClick={() => {
                handleCreateDemand();
              }}
              loader={isLoading}
              isDisabled={selectedContainer.value === null}
            >
              {translate(`pages.demands.validate`)}
            </SzButton>
          </div>
        </div>
      </ComponentWithTitle>
    </Layout>
  );
};
