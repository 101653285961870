import React, { useContext } from 'react';
import { Layout } from '../components/Layout';
import { ComponentWithTitle } from '../components/Common';
import { KPI } from '../components/Chart/Analysis';
import { useLogged } from '../hooks';
import { LanguageContext, UserContext } from '../contexts';
import { Tile } from '../components/Tile';
import { TypeSelectorProvider } from '../components/TypeSelector';

export const Welcome: React.FC = () => {
  useLogged();
  const { translate } = useContext(LanguageContext);
  const { isLogged } = useContext(UserContext);
  return (
    <Layout withDisclaimer withFooter>
      <ComponentWithTitle title="pages.welcome.overview" icon="apps">
        <div className="row pt-2 m-0 w-100">
          <div className="col-md-6 d-none d-md-flex">
            <Tile className="p-4 d-flex">
              <span className="fs-3 text-justify">
                {translate('pages.welcome.overview.description')}
              </span>
            </Tile>
          </div>
          {isLogged ? (
            <div className="col-md-6 pt-4 pt-md-0">
              <TypeSelectorProvider>
                <KPI />
              </TypeSelectorProvider>
            </div>
          ) : null}
        </div>
      </ComponentWithTitle>
    </Layout>
  );
};
