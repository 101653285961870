import { AbstractProvider } from './abstract';
import { ContainerDemand as ContainerDemandModel } from '../model';
import { MANAGEMENT, PROVIDER } from '../../actions/BCM_Constant';

export class ContainerDemand extends AbstractProvider {
  endpoint = PROVIDER.CONTAINER_DEMANDS;
  management = MANAGEMENT.COMPANY;
  model = new ContainerDemandModel();
  name = PROVIDER.CONTAINERS;
}
