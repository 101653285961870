import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Material,
  MaterialFamily,
  MaterialSubFamily,
} from '../../interfaces/providers';
import { BaseStoreContext, SET_CATALOG } from '../../contexts';
import { MaterialInstance, ModelInstance } from '../../interfaces';
import {
  MaterialFamilyFilter,
  MaterialSubFamilyFilter,
  OptionInterface,
  ResetFilter,
  SearchFilter,
} from '../Filters';
import { CatalogItems } from './CatalogItems';
import { AbstractProvider } from '../../interfaces/providers/abstract';
import { useDebounce } from '../../hooks';
import { Tile } from '../Tile';

interface ProviderInterface {
  provider: AbstractProvider;
  setter: Dispatch<SetStateAction<OptionInterface[]>>;
}

export const Catalog: React.FC = () => {
  const perPage = 20;
  const { dispatch, itemFiltersSelected } = useContext(BaseStoreContext);
  const [isFirstCall, setIsFirstCall] = useState<boolean>(true);
  const [materialFamilyFilterValues, setMaterialFamilyFilterValues] = useState<
    OptionInterface[]
  >([]);
  const [materialSubFamilyFilterValues, setMaterialSubFamilyFilterValues] =
    useState<OptionInterface[]>([]);
  const [searchMaterialFilter, setSearchMaterialFilter] = useState<string>('');
  const [materialFamily, setMaterialFamily] = useState<OptionInterface[]>([]);
  const [materialSubFamily, setMaterialSubFamily] = useState<OptionInterface[]>(
    []
  );
  const debounced = useDebounce(searchMaterialFilter, 500);
  const [providers] = useState<ProviderInterface[]>([
    {
      provider: new MaterialFamily(),
      setter: setMaterialFamily,
    },
  ]);

  useEffect(() => {
    setMaterialSubFamily([]);
    setMaterialSubFamilyFilterValues([]);

    const options: any = {};

    // TODO: this should not be an OptionInterface[] but an OptionInterface
    // there is although lot of code to change to fix this properly
    if (
      materialFamilyFilterValues &&
      !Array.isArray(materialFamilyFilterValues)
    ) {
      options.filters = {
        idMaterialFamily: (materialFamilyFilterValues as OptionInterface).value,
      };
    }

    new MaterialSubFamily(options)
      .getList()
      .then(({ data }: { data: ModelInstance[] }) => {
        setMaterialSubFamily(
          data.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      });
  }, [materialFamilyFilterValues]);

  const resetFilters = (): void => {
    setMaterialFamilyFilterValues([]);
    setMaterialSubFamilyFilterValues([]);
    setSearchMaterialFilter('');
  };
  const callMaterialAPI = useCallback(() => {
    new Material({
      filters: {
        perPage,
        idOrganizations: itemFiltersSelected.organization,
        idCompanies: itemFiltersSelected.plant,
        materialFamilyFilterValues,
        materialSubFamilyFilterValues,
        searchMaterialFilter,
      },
    })
      .getList()
      .then(({ data }: { data: MaterialInstance[] }) => {
        dispatch({
          payload: { data, loading: false },
          type: SET_CATALOG,
        });
      });
  }, [
    dispatch,
    itemFiltersSelected,
    materialFamilyFilterValues,
    materialSubFamilyFilterValues,
    searchMaterialFilter,
  ]);

  useEffect(() => {
    providers.map((provider) =>
      provider.provider.getList().then(({ data }: { data: ModelInstance[] }) =>
        provider.setter(
          data.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        )
      )
    );
  }, [providers]);

  useEffect(() => {
    dispatch({
      payload: { data: [], loading: true },
      type: SET_CATALOG,
    });
    callMaterialAPI();
  }, [
    callMaterialAPI,
    dispatch,
    materialFamilyFilterValues,
    materialSubFamilyFilterValues,
  ]);

  useMemo(() => {
    if (debounced || isFirstCall) {
      setIsFirstCall(false);
      dispatch({
        payload: { data: [], loading: true },
        type: SET_CATALOG,
      });
      callMaterialAPI();
    }
  }, [callMaterialAPI, dispatch, debounced, isFirstCall]);

  return (
    <div className="col-12">
      <Tile className="w-100">
        <div className="row m-0 py-3 w-100">
          <div className="col-sm-6 col-lg-3 order-2 order-md-1 py-1 py-lg-0">
            <MaterialFamilyFilter
              onChange={setMaterialFamilyFilterValues}
              options={materialFamily}
              value={materialFamilyFilterValues}
            />
          </div>
          <div className="col-sm-6 col-lg-3 order-2 order-md-1 py-1 py-lg-0">
            <MaterialSubFamilyFilter
              onChange={setMaterialSubFamilyFilterValues}
              options={materialSubFamily}
              value={materialSubFamilyFilterValues}
            />
          </div>
          <ResetFilter
            resetFilters={resetFilters}
            className="col-12 col-md-6 col-lg-3 order-4 order-md-4 order-lg-3 d-md-block m-auto py-1 py-lg-0"
          />
          <SearchFilter
            className="col-12 col-md-6 col-lg-3 order-1 order-md-3 order-lg-4 py-1 py-lg-0"
            onChange={setSearchMaterialFilter}
            value={searchMaterialFilter}
          />
        </div>
        <CatalogItems
          {...{
            materialFamilyFilterValues,
            materialSubFamilyFilterValues,
            perPage,
            searchMaterialFilter,
          }}
        />
      </Tile>
    </div>
  );
};
