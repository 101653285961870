import { ModelActions, ModelInstance } from './Common';

export interface MaterialFamilyInterface {
  idMaterialFamily: string;
  materialFamilyCode: string;
  materialFamilyName: string;
}

export class MaterialFamilyInstance extends ModelInstance {
  code = '';

  constructor(materialFamily: MaterialFamilyInterface) {
    super(materialFamily);
    this.code = materialFamily.materialFamilyCode;
    this.id = materialFamily.idMaterialFamily;
    this.name = materialFamily.materialFamilyName;
  }
}

export class MaterialFamily extends ModelActions {
  getMany = (
    materialFamilies: MaterialFamilyInterface[]
  ): MaterialFamilyInstance[] =>
    materialFamilies.map(
      (materialFamily) => new MaterialFamilyInstance(materialFamily)
    );
  getOne = (materialFamily: MaterialFamilyInterface): MaterialFamilyInstance =>
    new MaterialFamilyInstance(materialFamily);
}
