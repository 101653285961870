import { AbstractProvider } from './abstract';
import { ContainerDemandStatus as ContainerDemandStatusModel } from '../model';
import { MANAGEMENT, PROVIDER } from '../../actions/BCM_Constant';

export class ContainerDemandStatus extends AbstractProvider {
  endpoint = PROVIDER.CONTAINER_DEMAND_STATUS;
  management = MANAGEMENT.COMPANY;
  model = new ContainerDemandStatusModel();
  name = PROVIDER.CONTAINERS;
}
