import { ModelActions, ModelInstance, ModelInterface } from './Common';

export interface MaterialReferentialInterface extends ModelInterface {
  idMaterialsReferential: string;
  materialsReferentialName: string;
}

export class MaterialReferentialInstance extends ModelInstance {
  constructor(materialReferential: MaterialReferentialInterface) {
    super(materialReferential);
    this.id = materialReferential.idMaterialsReferential;
    this.name = materialReferential.materialsReferentialName;
  }
}

export class MaterialReferential extends ModelActions {
  getMany = (
    materialReferentials: MaterialReferentialInterface[]
  ): MaterialReferentialInstance[] =>
    materialReferentials.map(
      (materialReferential) =>
        new MaterialReferentialInstance(materialReferential)
    );
  getOne = (
    materialReferential: MaterialReferentialInterface
  ): MaterialReferentialInstance =>
    new MaterialReferentialInstance(materialReferential);
}
