import {
  Chart as ChartModel,
  VolumeGraph as VolumeGraphModel,
  MultiGraphInstance,
  Co2Chart as Co2ChartModel,
  Co2ChartInstance,
  Material,
  MixedChartInstance,
  MaterialInstance,
  CircularGraph,
  CircularChartInstance,
} from '../model';
import { AbstractBCMPortal } from './abstractBCMPortal';
import { ExportAllDataInterface } from '../../components/Button/Export/AllSerializer';
import { ExportMovementDataInterface } from '../../components/Button/Export/MovementSerializer';
import { ChartRestInterface } from '../ChartInterface';

const CO2 = '/co2';
const REVENUES = '/revenues';
const VOLUMES = '/volumes';
const CIRCULAR_ECONOMY = '/circular_economy';
const MATERIALS = '/materials';
const INDEXES = '/indexes';


export class Chart extends AbstractBCMPortal {
  endpoint = '/analysis_and_reporting';
  model = new ChartModel();

  getChart = (
    rest?: ChartRestInterface
  ): Promise<
    {
      data: MultiGraphInstance[];
    } & ChartRestInterface
  > => {
    return this.request()
      .get(this.getUrl(REVENUES, true))
      .then(({ data }) => {
        return {data: this.model.getMany(data?.data || data), ...rest}
      });
  };
}
export class FilterChart extends AbstractBCMPortal {
  endpoint = '/analysis_and_reporting';
  model = new ChartModel();

  getMaterials = (
    rest?: ChartRestInterface
  ): Promise<
    {
      materials: MaterialInstance[];
    } & ChartRestInterface
  > => {
    return this.request()
      .get(this.getUrl(MATERIALS, true))
      .then(({ data }) => ({
        materials: new Material().getMany(data),
        ...rest,
      }));
  };
}

export class VolumeChart extends AbstractBCMPortal {
  endpoint = '/analysis_and_reporting';
  model = new VolumeGraphModel();

  getChart = (
    rest?: ChartRestInterface
  ): Promise<
    {
      data: MultiGraphInstance[];
    } & ChartRestInterface
  > => {
    return this.request()
      .get(this.getUrl(VOLUMES, true))
      .then(({ data }) => ({
        data: this.model.getMany(data?.data || data),
        ...rest,
      }));
  };
}
export class CircularEconomyChart extends AbstractBCMPortal {
  endpoint = '/analysis_and_reporting';
  model = new CircularGraph();

  getChart = (
    rest?: ChartRestInterface
  ): Promise<
    {
      data: CircularChartInstance[];
      materials: MaterialInstance[];
    } & ChartRestInterface
  > => {
    return this.request()
      .get(this.getUrl(CIRCULAR_ECONOMY, true))
      .then(({ data }) => ({
        data: this.model.getMany(data?.data || data),
        materials: new Material().getMany(data.materials),
        ...rest,
      }));
  };
}

export class MovementVolumeChart extends AbstractBCMPortal {
  endpoint = '/movement';
  model = new VolumeGraphModel();

  getChart = (
    rest?: ChartRestInterface
  ): Promise<
    {
      data: MultiGraphInstance[];
      materials: MaterialInstance[];
    } & ChartRestInterface
  > => {
    return this.request()
      .get(this.getUrl(VOLUMES, true))
      .then(({ data }) => ({
        data: this.model.getMany(data?.data || data),
        materials: new Material().getMany(data.materials),
        ...rest,
      }));
  };
}

export class Co2Chart extends AbstractBCMPortal {
  endpoint = '/analysis_and_reporting';
  model = new Co2ChartModel();

  getChart = (
    rest?: ChartRestInterface
  ): Promise<
    {
      data: Co2ChartInstance[];
    } & ChartRestInterface
  > => {
    return this.request()
      .get(this.getUrl(CO2, true))
      .then(({ data }) => ({
        data: this.model.getMany(data?.data || data),
        ...rest,
      }));
  };
}

export class MovementCo2Chart extends AbstractBCMPortal {
  endpoint = '/movement';
  model = new Co2ChartModel();

  getChart = (
    rest?: ChartRestInterface
  ): Promise<
    {
      data: Co2ChartInstance[];
      materials: MaterialInstance[];
    } & ChartRestInterface
  > => {
    return this.request()
      .get(this.getUrl(CO2, true))
      .then(({ data }) => ({
        data: this.model.getMany(data?.data || data),
        materials: new Material().getMany(data.materials),
        ...rest,
      }));
  };
}

export class MixedChart extends AbstractBCMPortal {
  endpoint = '/analysis_and_reporting';

  getChart = (): Promise<MixedChartInstance> => {
    return this.request()
      .get(this.getUrl(INDEXES, true))
      .then(({ data }) => new MixedChartInstance(data));
  };
}

export class ExportChart extends AbstractBCMPortal {
  endpoint = '/analysis_and_reporting/export';

  getChart = (): Promise<ExportAllDataInterface> => {
    return this.request()
      .get(this.getUrl('', true))
      .then(({ data }) => data as ExportAllDataInterface);
  };
}
export class MovementExportChart extends AbstractBCMPortal {
  endpoint = '/movement/export';

  getChart = (): Promise<ExportMovementDataInterface> => {
    return this.request()
      .get(this.getUrl('', true))
      .then(({ data }) => data as ExportMovementDataInterface);
  };
}
