import { APIPlatform } from './API';

export class Preference extends APIPlatform {
  public endpoint = '/user_preferences';

  public update({
    data,
    id,
  }: {
    data: Record<string, unknown>;
    id: string;
  }): void {
    this.patchRequest({ data, endpoint: `/${id}` })
      .then(() => {
        return;
      })
      .catch();
  }
}
