import { AbstractProvider } from './abstract';

interface BCMInterface {
  [key: string]: number;
}

export class AbstractBCMPortal extends AbstractProvider {
  withParams = true;

  getUrl = (value = '', withQueryParameters?: boolean): string =>
    `${process.env.REACT_APP_WDH_API_URL}/bcm_customer_portal/v1${
      this.endpoint || this.name
    }${value}${withQueryParameters ? this.formatQueryParameters() : ''}`;

  get = (): Promise<BCMInterface> =>
    this.request()
      .get(this.getUrl('', this.withParams))
      .then(({ data }) => this.model.getOne(data.data ? data.data : data));
}
