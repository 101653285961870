import React, { useContext } from 'react';
import { Layout } from '../components/Layout';
import { Contact as ContactForm } from '../components/Form';
import { SzTypographie } from 'react-theme-components';
import { LanguageContext } from '../contexts';
import { useLogged } from '../hooks';
import { ComponentWithTitle } from '../components/Common';

export const Contact: React.FC = () => {
  useLogged();
  const { translate } = useContext(LanguageContext);
  return (
    <Layout withFooter>
      <ComponentWithTitle title="pages.contact" icon="book">
        <div className="card p-4 d-block col-12">
          <SzTypographie variant="text" className="fs-2 col-12 mb-0">
            {translate('pages.contact.description')}
          </SzTypographie>
          <ContactForm />
        </div>
      </ComponentWithTitle>
    </Layout>
  );
};
